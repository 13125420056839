import { Alert, Button, PaneContent } from '@meterup/atto';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  isDefinedAndNotEmpty,
  ResourceNotFoundError,
} from '@meterup/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { get } from 'lodash-es';
import { useState } from 'react';

import { fetchControllerJSON, fetchControllerState } from '../../../../api/controllers_api';
import { createVPNServer, fetchVPNServer } from '../../../../api/vpn';
import { Nav } from '../../../../components/Nav';
import { Page, PageSection } from '../../../../components/Page';
import VPNClientsWidget from '../../../../components/VPN/VPNClientsWidget';
import { paths } from '../../../../constants';
import { NosFeature, useNosFeatureEnabled } from '../../../../utils/nosfeature';
import { VPNServerCreateDialog } from '../../../drawers/controllers/vpn/VPNServerCreate';

export const Meta = () => ({
  path: '/controllers/:controllerName/vpn',
});

export default function VPNListPage() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerDetails),
  );

  const controller = useQuery(
    ['controllers', controllerName],
    () => fetchControllerJSON(controllerName),
    { suspense: true },
  ).data;

  const vpnServer = useQuery(
    ['controller', controllerName, 'vpn-servers'],
    () => fetchVPNServer(controllerName),
    { suspense: true, enabled: true },
  ).data;

  const stateData = useQuery(
    ['controller', controllerName, 'state'],
    () => fetchControllerState(controllerName),
    { suspense: true },
  ).data;

  expectDefinedOrThrow(controller, new ResourceNotFoundError('Controller response not found'));
  expectDefinedOrThrow(stateData, new ResourceNotFoundError('State response not found'));
  expectDefinedOrThrow(stateData.state, new ResourceNotFoundError('State response not found'));

  const [isOpen, setIsOpen] = useState(false);
  const publicKey = get(stateData?.state, ['meter.v1.wg-vpn-client-manager', 'public_key']);
  const queryClient = useQueryClient();

  const createVPNServerMutation = useMutation(
    async () => {
      if (!isDefinedAndNotEmpty(publicKey)) {
        setIsOpen(true);
      } else {
        await createVPNServer(controllerName, publicKey);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controller', controllerName]);
      },
    },
  );
  const isCOS2Enabled = useNosFeatureEnabled(controllerName, NosFeature.COS2);

  return (
    <PaneContent>
      <Page>
        {controller.company_slug === '' && (
          <Alert
            icon="attention"
            variant="negative"
            heading="No company associated"
            copy="This controller does not yet belong to a company. Please add a company to this controller before creating its VPN."
            relation="stacked"
          />
        )}
        {isCOS2Enabled && (
          <Alert
            icon="attention"
            variant="negative"
            heading="Attention"
            copy="This network uses COS Config 2.0. Use dashboard to configure VPN."
            relation="stacked"
            shoulderButtons={
              <Button
                variant="secondary"
                as="a"
                target="_blank"
                href={`${import.meta.env.DASHBOARD_URL}/org/${
                  controller.company_slug
                }/network/${controller.network_uuid}`}
              >
                View dashboard
              </Button>
            }
          />
        )}
        {controller.company_slug !== '' && vpnServer === null && (
          <PageSection style={{ padding: 16 }}>
            <Alert
              heading="No VPN server "
              copy="This controller does not have a VPN server configured. You can create one below."
              trailingButtons={
                <Button
                  onClick={() => createVPNServerMutation.mutate()}
                  variant="secondary"
                  arrangement="leading-icon"
                >
                  Create server
                </Button>
              }
            />
            <VPNServerCreateDialog
              controllerName={controllerName}
              isOpen={isOpen}
              onOpenChange={setIsOpen}
            />
          </PageSection>
        )}
        {vpnServer != null && <VPNClientsWidget controllerName={controllerName} />}
      </Page>
    </PaneContent>
  );
}
