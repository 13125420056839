import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  styled,
  Tab,
} from '@meterup/atto';
import { isDefined, NeutralBadge } from '@meterup/common';
import React from 'react';

export interface TabFilter<Key extends React.Key = React.Key> {
  key: Key;
  label: React.ReactNode;
  count?: number;
}

export interface TabFiltersProps<Key extends React.Key = React.Key> {
  filters: TabFilter<Key>[];
  activeFilterKey: Key;
  onActivateFilter: (filter: TabFilter<Key>) => void;
}

export const TabFiltersOverflow = styled('div', {
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '$4',
});

export function TabFilters<Key extends React.Key = React.Key>({
  filters,
  activeFilterKey,
  onActivateFilter,
}: TabFiltersProps<Key>) {
  const firstTabs = filters.slice(0, 3);
  const otherTabs = filters.slice(3);

  return (
    <>
      {firstTabs.map((filter) => (
        <Tab
          annotation={filter.count}
          selected={filter.key === activeFilterKey}
          onClick={() => onActivateFilter(filter)}
        >
          {filter.label}
        </Tab>
      ))}
      {otherTabs.length > 0 && (
        <TabFiltersOverflow>
          <DropdownMenu>
            <DropdownMenuButton
              icon="overflow-vertical"
              arrangement="hidden-label"
              variant={
                otherTabs.some((filter) => filter.key === activeFilterKey) ? 'primary' : 'secondary'
              }
            >
              More
            </DropdownMenuButton>
            <DropdownMenuPopover>
              <DropdownMenuGroup>
                {otherTabs.map((filter) => (
                  <DropdownMenuItem
                    key={filter.key}
                    icon={activeFilterKey === filter.key ? 'checkmark' : undefined}
                    onSelect={() => {
                      onActivateFilter(filter);
                    }}
                  >
                    {filter.label}
                    {isDefined(filter.count) && (
                      <NeutralBadge ends="card">{filter.count}</NeutralBadge>
                    )}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuGroup>
            </DropdownMenuPopover>
          </DropdownMenu>
        </TabFiltersOverflow>
      )}
    </>
  );
}
