import type { api } from '@meterup/proto';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Alert,
  Button,
  CheckboxInputWithLabel,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  SecondaryField,
  Section,
  Select,
  SelectItem,
} from '@meterup/atto';
import { checkDefinedOrThrow, getErrorMessage } from '@meterup/common';
import { MaintenanceWindowRecurrence } from '@meterup/proto/esm/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { orderBy } from 'lodash-es';
import React, { useState } from 'react';
import * as z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import {
  fetchControllerJSON,
  fetchMaintenanceWindowForController,
} from '../../../../api/controllers_api';
import { fetchNosVersions, pinNosVersionNetwork } from '../../../../api/nosversions_api';
import { FieldProvider } from '../../../../components/FieldProvider';
import { Nav } from '../../../../components/Nav';
import { NosInformationWidget } from '../../../../components/NosInfomationWidget';
import { WidgetSuspenseAndErrorBoundary } from '../../../../components/WidgetSuspenseAndErrorBoundary';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { styled } from '../../../../stitches';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/nos-upgrade/edit',
});

const validNosFirmwareUpdate = z.object({
  nosId: z.string(),
  forceUpdate: z.boolean().optional(),
  staggerUpgrade: z.boolean().optional(),
});

interface NosFirmwareUpdate extends z.TypeOf<typeof validNosFirmwareUpdate> {}

const StyledForm = styled(Form, {
  display: 'contents',
});

const empty: api.NosVersionResp[] = [];

export default function EditNosUpgrade() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.EditNosUpgrade),
  );

  const nosversionsUnsorted =
    useQuery(['nos-versions-list'], () => fetchNosVersions(), { suspense: true }).data ?? empty;

  // api returns nos versions sorted by updated_at. Sort it by version for this component
  const nosversions = orderBy(nosversionsUnsorted, (e) => e.nos_version?.version, 'desc');

  const windows = useQuery(
    ['controller', controllerName, 'maintenance-windows'],
    () => fetchMaintenanceWindowForController(controllerName),
    {
      suspense: true,
    },
  ).data;
  const maintenanceWindow = windows?.maintenance_windows[0];

  const queryClient = useQueryClient();
  const closeDrawer = useCloseDrawerCallback();
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const timeLabel = (s: number) => {
    let meridiem = 'AM';
    if (s > 12) meridiem = 'PM';
    return `${s % 12 || 12}${meridiem}`;
  };

  const createFirmwareUpdateMutation = useMutation(
    async (values: NosFirmwareUpdate) => {
      await pinNosVersionNetwork(controllerName, values.nosId, {
        force: values.forceUpdate,
        interval_min: values.staggerUpgrade ? 10 : 0,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controller', controllerName]);
        queryClient.invalidateQueries(['controller', controllerName, 'pending-fw-upgrades']);
        queryClient.invalidateQueries(['serial', controllerName, 'pending-fw-upgrades']);
        closeDrawer();
      },
    },
  );
  const controller = useQuery(
    ['controller', controllerName],
    () => fetchControllerJSON(controllerName),
    {
      suspense: true,
    },
  ).data;

  // set initial value based on controller's current running version
  const [nosId, setNosId] = useState(() => {
    const currentNos = nosversions.find(
      (e) => e.nos_version?.version === controller?.firmware_version,
    );
    if (currentNos === undefined) {
      return nosversions[0].id.toString();
    }
    return currentNos.id.toString();
  });

  const getNosInfoForId = (id: string) => {
    const currentNos = nosversions.find((e) => e.id.toString() === id);
    return currentNos?.nos_version;
  };

  const [forceUdate, setForceUpdate] = useState(false);
  const [staggerUpgrade, setStaggerUpgrade] = useState(false);
  const [nosVersionInfo, setNosVersionInfo] = useState(getNosInfoForId(nosId));

  return (
    <Formik<NosFirmwareUpdate>
      initialValues={{
        nosId,
        forceUpdate: forceUdate,
        staggerUpgrade,
      }}
      validationSchema={toFormikValidationSchema(validNosFirmwareUpdate)}
      onSubmit={(values) => createFirmwareUpdateMutation.mutate(values)}
      enableReinitialize
    >
      {(props) => (
        <StyledForm>
          <Drawer>
            <DrawerHeader heading="Edit network firmware" onClose={closeDrawer} />
            <DrawerContent>
              {createFirmwareUpdateMutation.isError && (
                <Alert
                  icon="attention"
                  variant="negative"
                  heading="Error while submitting"
                  copy={getErrorMessage(createFirmwareUpdateMutation.error)}
                />
              )}
              <FieldProvider name="nosId">
                <SecondaryField
                  label="Select NOS Version"
                  element={
                    <Select
                      value={nosId}
                      onValueChange={(value: string) => {
                        setNosId(value);
                        setNosVersionInfo(getNosInfoForId(value));
                      }}
                    >
                      {Object.values(nosversions).map((nos) => (
                        <SelectItem key={nos.id.toString()}>{nos.nos_version?.version}</SelectItem>
                      ))}
                    </Select>
                  }
                />
              </FieldProvider>
              <FieldProvider name="forceUpdate">
                <CheckboxInputWithLabel
                  checked={props.values.forceUpdate || false}
                  onChange={(checked: boolean) => setForceUpdate(checked)}
                  aria-label="Force upgrade now"
                >
                  Force upgrade now
                </CheckboxInputWithLabel>
              </FieldProvider>
              {forceUdate && (
                <Alert
                  variant="negative"
                  copy="All device in this network will be upgraded immediately! Maintenance window will be ignored."
                />
              )}
              <FieldProvider name="staggerUpgrade">
                <CheckboxInputWithLabel
                  checked={props.values.staggerUpgrade || false}
                  onChange={(checked: boolean) => setStaggerUpgrade(checked)}
                  aria-label="Stagger"
                >
                  Slow upgrade this network
                </CheckboxInputWithLabel>
                {staggerUpgrade && (
                  <Alert
                    variant="positive"
                    copy="Upgrades devices one at a time 10min apart. Upgrades begin during maintenance window. COS is upgraded first followed by APs. Total time taken for upgrading all devices can exceed maintenance window."
                  />
                )}
              </FieldProvider>
              <Section relation="stacked">
                <Alert
                  icon="attention"
                  variant="neutral"
                  heading="Maintenance Window"
                  copy={
                    maintenanceWindow &&
                    `${
                      maintenanceWindow.recurrence === MaintenanceWindowRecurrence.RECURRENCE_DAILY
                        ? `Tonight`
                        : `Next ${days[maintenanceWindow.start_day_of_week_local!]}`
                    } at ${timeLabel(maintenanceWindow.start_hour_of_day_local!)}${
                      maintenanceWindow.timezone ? ` (${maintenanceWindow.timezone!})` : ''
                    }`
                  }
                />
              </Section>
              <Section relation="stacked">
                {nosVersionInfo && (
                  <WidgetSuspenseAndErrorBoundary title="Firmware Updates">
                    <NosInformationWidget nos={nosVersionInfo} />
                  </WidgetSuspenseAndErrorBoundary>
                )}
              </Section>
            </DrawerContent>
            <DrawerFooter actions={<Button type="submit">Save</Button>} />
          </Drawer>
        </StyledForm>
      )}
    </Formik>
  );
}
