import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Alert,
  Button,
  ComboBox,
  ComboBoxItem,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  FieldContainer,
  PrimaryField,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  TextInput,
} from '@meterup/atto';
import { checkDefinedOrThrow, notify } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Formik } from 'formik';
import { useState } from 'react';

import type { JobTrackersQuery } from '../../../../gql/graphql';
import type { MeterMailingAddress } from '../../../../utils/googleMaps';
import { createNetworkOnboarding, jobTrackers } from '../../../../api/onboardings_api';
import { FieldProvider } from '../../../../components/FieldProvider';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { graphql } from '../../../../gql';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { styled } from '../../../../stitches';
import { getStructuredAddress } from '../../../../utils/googleMaps';
import { networksForCompany } from '../../../../utils/networks';

export const Meta: PagefileMetaFn = () => ({
  path: '/companies/:companyName/add-networks',
});

const StyledForm = styled('form', {
  display: 'contents',
});

const createNetwork = graphql(`
  mutation CreateNetwork(
    $companySlug: String!
    $mailingAddress: String!
    $label: String!
    $slug: String!
  ) {
    createNetwork(
      input: {
        companySlug: $companySlug
        mailingAddress: $mailingAddress
        label: $label
        slug: $slug
      }
    ) {
      UUID
      slug
    }
  }
`);

// src: https://gist.github.com/codeguy/6684588?permalink_comment_id=4325476#gistcomment-4325476
const slugify = (text: string) =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');

function addressToSlug(address: MeterMailingAddress) {
  let str = address.line1;
  if (address.line2) {
    str += ` ${address.line2}`;
  }
  return slugify(str);
}

type AirtableTrackerRow = JobTrackersQuery['jobTrackers'][0];

type SelectedJobInfo = {
  job: AirtableTrackerRow;
  address: MeterMailingAddress;
};

function JobSelectForm({ onSelect }: { onSelect: (result: SelectedJobInfo) => void }) {
  const closeDrawer = useCloseDrawerCallback();

  const allTrackers = useGraphQL(jobTrackers)?.data;

  const airtableTrackers = allTrackers?.jobTrackers ?? [];

  const [isFetchingAddress, setIsFetchingAddress] = useState(false);

  return (
    <Formik<{ jobTrackerId: string }>
      initialValues={{
        jobTrackerId: '',
      }}
      onSubmit={async (values) => {
        const job = airtableTrackers.find((jt) => jt.id === values.jobTrackerId);
        setIsFetchingAddress(true);
        if (job) {
          try {
            const result = await getStructuredAddress(job.fullAddress?.[0] ?? '');
            onSelect({
              job,
              address: result,
            });
          } catch (err) {
            const error = err instanceof Error ? err : undefined;
            notify(`Could not validate address for network: ${error?.message ?? 'Unknown error'}`, {
              variant: 'negative',
            });
          } finally {
            setIsFetchingAddress(false);
          }
        }
      }}
    >
      {(form) => (
        <StyledForm onSubmit={form.handleSubmit}>
          <Drawer>
            <DrawerHeader heading="Add network" onClose={closeDrawer} />
            <DrawerContent>
              <FieldProvider name="jobTrackerId">
                <PrimaryField
                  label="Select an Airtable job"
                  description="Connect this network to a Job in Airtable."
                  element={
                    <ComboBox
                      placeholder="Select a job"
                      maxWidth="100%"
                      defaultItems={airtableTrackers}
                    >
                      {airtableTrackers.map((jt) => (
                        <ComboBoxItem key={jt.id}>{jt.jobID}</ComboBoxItem>
                      ))}
                    </ComboBox>
                  }
                />
              </FieldProvider>
            </DrawerContent>
            <DrawerFooter
              actions={
                <>
                  <Button variant="secondary" type="button" onClick={closeDrawer}>
                    Cancel
                  </Button>
                  <Button
                    variant={isFetchingAddress ? 'secondary' : 'primary'}
                    loading={isFetchingAddress}
                    type="submit"
                  >
                    Next
                  </Button>
                </>
              }
            />
          </Drawer>
        </StyledForm>
      )}
    </Formik>
  );
}

export default function CreateNetwork() {
  const queryClient = useQueryClient();
  const { companyName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.CompanyNetworks),
  );

  const closeDrawer = useCloseDrawerCallback();

  const [selectedJob, setSelectedJob] = useState<SelectedJobInfo | null>(null);

  const createNetworkMutation = useGraphQLMutation(createNetwork);
  const createOnboardingMutation = useGraphQLMutation(createNetworkOnboarding);

  if (!selectedJob) {
    return <JobSelectForm onSelect={(result) => setSelectedJob(result)} />;
  }

  return (
    <Formik<{ slug: string }>
      initialValues={{
        slug: selectedJob.job.locationCode
          ? slugify(selectedJob.job.locationCode)
          : addressToSlug(selectedJob.address),
      }}
      onSubmit={async (values) => {
        try {
          const addr = selectedJob.address;

          const mailingAddress = `${addr.line1} ${addr.line2}, ${addr.city}, ${addr.subdivision_code} ${addr.postal_code}`;

          const network = await createNetworkMutation.mutateAsync({
            companySlug: companyName,
            mailingAddress,
            label: selectedJob.job.locationCode ?? selectedJob.job.jobID ?? selectedJob.job.id,
            slug: values.slug,
          });

          createOnboardingMutation.mutateAsync({
            jobTrackerID: selectedJob.job.id,
            networkUUID: network.createNetwork.UUID,
          });

          queryClient.invalidateQueries(
            makeQueryKey(networksForCompany, { companySlug: companyName }),
          );
          notify('Network created', { variant: 'positive' });
          closeDrawer();
        } catch (err) {
          const error = err instanceof Error ? err : undefined;
          const graphqlError = error ? getGraphQLError(error) : undefined;

          notify(
            `Error creating network: ${graphqlError?.message ?? error?.message ?? 'Unknown error'}`,
            { variant: 'negative' },
          );
        }
      }}
    >
      {(form) => (
        <StyledForm onSubmit={form.handleSubmit}>
          <Drawer>
            <DrawerHeader heading="Add network" onClose={closeDrawer} />
            <DrawerContent>
              <FieldContainer>
                <FieldProvider name="slug">
                  <PrimaryField
                    label="Slug"
                    description="The slug is used to create the URL for this network."
                    element={<TextInput />}
                  />
                </FieldProvider>
              </FieldContainer>
              <SummaryList>
                <SummaryListRow>
                  <SummaryListKey>Address</SummaryListKey>
                  <SummaryListValue>{selectedJob.job.fullAddress}</SummaryListValue>
                </SummaryListRow>
                <SummaryListRow>
                  <SummaryListKey>Total SqFt</SummaryListKey>
                  <SummaryListValue>{selectedJob.job.totalSqFt}</SummaryListValue>
                </SummaryListRow>
              </SummaryList>
              <Alert
                icon="attention"
                copy="If you proceed, the customer will see an onboarding view in their dashboard with the above information."
              />
            </DrawerContent>
            <DrawerFooter
              actions={
                <>
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => {
                      setSelectedJob(null);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    disabled={!form.isValid}
                    loading={createOnboardingMutation.isLoading}
                  >
                    Add network
                  </Button>
                </>
              }
            />
          </Drawer>
        </StyledForm>
      )}
    </Formik>
  );
}
