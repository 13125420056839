import { Body } from '@meterup/atto';

import { colors, fontWeights, styled } from '../stitches';

export const Page = styled('div', {
  vStack: '$16',
  alignItems: 'stretch',
  paddingBottom: '$40',
});

export const PageHeader = styled('div', {
  hStack: '$8',
  paddingX: '$20',
  height: '$56',
  flexShrink: 0,
  strokeAll: colors.strokeApplicationLight,
});

export const PageTitle = styled('h1', Body, {
  color: colors.gray800,
  fontWeight: fontWeights.bold,
});

export const PageSection = styled('div', {
  paddingX: '$16',
});

export const PageControls = styled('div', {
  hStack: '$8',
  marginLeft: 'auto',
});
