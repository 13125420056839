import type { api } from '@meterup/proto';
import { Badge, Dock, Layout, LayoutPage, Logo, styled } from '@meterup/atto';
import { observer, useCommand, useRegisterCommands } from '@meterup/command';
import { useLocalStorage } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useNavigate } from 'react-router';

import { fetchCompaniesJSON } from '../api/company_api';
import { HeaderDropdownMenu } from '../components/HeaderDropdownMenu';
import { Sidebar } from '../components/Sidebar/Sidebar';
import { paths } from '../constants';
import { makeDrawerLink } from '../utils/makeLink';

const empty: api.CompanyResponse[] = [];

const HeaderContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$6',
  height: '$40',
  maxHeight: '$40',
  minHeight: '$40',

  variants: {
    minimized: {
      true: {
        margin: '0 -2px',
      },
      false: {
        padding: '0 $8',
      },
    },
  },
});

export const AppLayout = observer(({ children }: React.PropsWithChildren<{}>) => {
  const companies =
    useQuery(['companies', 'filter', 'all'], () => fetchCompaniesJSON()).data ?? empty;
  const [minimized, setMinimized] = useLocalStorage('noc.dock.minimized', true);

  const { state } = useCommand();

  const navigate = useNavigate();

  useRegisterCommands(
    companies
      ? [
          state.nodeFactory.directory({
            id: 'global-companies-search',
            display: 'Go to company…',
            label: 'Go to company…',
            shortcut: '$mod+/',
            children: companies.map((company) =>
              state.nodeFactory.action({
                id: company.sid,
                display: `${company.name} (${company.slug})`,
                label: `${company.name} (${company.slug})`,
                synonyms: company.slug,
                icon: 'company',
                onSelect() {
                  navigate(
                    makeDrawerLink(paths.drawers.CompanySummary, { companyName: company.slug }),
                  );
                },
              }),
            ),
          }),
        ]
      : [],
    [companies],
  );

  return (
    <Layout>
      <Dock
        minimized={minimized}
        setMinimized={setMinimized}
        search={
          <HeaderContainer minimized={minimized}>
            {!minimized && <Logo variant="full" height={3} />}
            <Badge variant="brand" size="small">
              NOC
            </Badge>
          </HeaderContainer>
        }
        account={<HeaderDropdownMenu />}
        products={<Sidebar />}
      />
      <LayoutPage>{children}</LayoutPage>
    </Layout>
  );
});
