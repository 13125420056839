import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Alert, Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader } from '@meterup/atto';
import { checkDefinedOrThrow, isDefined } from '@meterup/common';
import { api } from '@meterup/proto';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Formik } from 'formik';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import type { ValidPlanData } from './common/form_data';
import { createInternetServicePlan } from '../../../../api/controllers_api';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { styled } from '../../../../stitches';
import { makeCloseDrawerLink, makeDrawerLink } from '../../../../utils/makeLink';
import { NO_PROVIDER, toISPCreateRequest, validPlanData } from './common/form_data';
import { FormContent } from './common/FormContent';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/plans/new',
});

const StyledForm = styled('form', {
  height: '100%',
});

export default function ISPCreate() {
  const navigate = useNavigate();
  const closeDrawer = useCloseDrawerCallback();

  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.ISPCreate),
  );

  const queryClient = useQueryClient();

  const createDrawerMutation = useMutation(
    async (data: ValidPlanData) => {
      const apiData = toISPCreateRequest(data);
      const plan = await createInternetServicePlan(controllerName, apiData.planData);
      return plan;
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(['controllers', controllerName, 'service-plans']);
        navigate(
          makeDrawerLink(paths.drawers.ISPDetails, {
            controllerName: result.controller,
            planId: result.sid,
          }),
        );
      },
    },
  );

  return (
    <Formik<ValidPlanData>
      initialValues={{
        provider: NO_PROVIDER,
        // @ts-ignore
        product: api.ISPProduct.IR_UNKNOWN,
        // @ts-ignore
        status: api.ISPStatus.IS_UNKNOWN,
        network_interface: 'wan0',
      }}
      validationSchema={toFormikValidationSchema(validPlanData)}
      onSubmit={(values) => createDrawerMutation.mutate(values)}
    >
      {(form) => (
        <StyledForm onSubmit={form.handleSubmit}>
          <Drawer>
            <DrawerHeader heading="Create plan" onClose={closeDrawer} />
            <DrawerContent>
              {isDefined(createDrawerMutation.error) && (
                <Alert
                  heading="Error while submitting"
                  copy={JSON.stringify(createDrawerMutation.error)}
                />
              )}
              <FormContent controllerName={controllerName} />
            </DrawerContent>
            <DrawerFooter
              actions={
                <>
                  <Button
                    type="button"
                    variant="secondary"
                    as={Link}
                    to={makeCloseDrawerLink()}
                    loading={createDrawerMutation.isLoading}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" loading={createDrawerMutation.isLoading}>
                    Save
                  </Button>
                </>
              }
            />
          </Drawer>
        </StyledForm>
      )}
    </Formik>
  );
}
