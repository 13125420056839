export const getGrafanaURL = (
  controllerName?: string,
  apName?: string,
  clientName?: string,
): string => {
  let url =
    import.meta.env.REALM === 'prod'
      ? 'https://grafana.corp.meter.com/d/JiLGOTu7z/client-metrics?orgId=1'
      : // Note: hardcoding IPs here is a temporary workaround due to challenges setting up internal DNS.
        // Some raw context here: https://meterup.quip.com/Nb8zAM4Vfnzh/Metrics-AWS-Setup-H1-22
        'http://grafana.corp.meter.dev/d/JiLGOTu7z/client-metrics?orgId=1';
  if (controllerName) {
    url += `&var-controller_name=${controllerName}`;
  }
  if (apName) {
    url += `&var-ap_name=${apName}`;
  }
  if (clientName) {
    url += `&var-client_name=${clientName}`;
  }
  return url;
};

export const getGrafanaLogsURL = (controllerName: string): string =>
  `https://grafana.corp.meter.${
    import.meta.env.REALM === 'prod' ? 'com' : 'dev'
  }/d/JiLGOTu7z/logs-dashboard-for-controller?orgId=1&var-controller=meter-mc01-${controllerName}`;
