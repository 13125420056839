import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Alert, Button, Pane, PaneContent } from '@meterup/atto';
import { checkDefinedOrThrow } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { fetchControllerJSON } from '../../../api/controllers_api';
import { Nav } from '../../../components/Nav';
import EventLogsNetworkWidget from '../../../components/Network/EventLogsNetwork/EventLogsNetworkWidget';
import { paths } from '../../../constants';
import { NosFeature, useNosFeatureEnabled } from '../../../utils/nosfeature';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/logs',
});

export default function ControllerLogs() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerLogs),
  );
  const networkInfo = useQuery(
    ['controller', controllerName],
    async () => fetchControllerJSON(controllerName),
    { suspense: true },
  ).data;

  const isCOS2Enabled = useNosFeatureEnabled(controllerName, NosFeature.COS2);

  return (
    <PaneContent>
      {isCOS2Enabled && (
        <Alert
          variant="negative"
          copy="This network uses COS Config 2.0. Logs in this page are invalid. Please use Dashboard."
          heading="Attention"
          relation="stacked"
          icon="warning"
          shoulderButtons={
            networkInfo ? (
              <Button
                variant="secondary"
                as="a"
                target="_blank"
                href={`${import.meta.env.DASHBOARD_URL}/org/${
                  networkInfo.company_slug
                }/network/${networkInfo.network_uuid}`}
              >
                View dashboard
              </Button>
            ) : undefined
          }
        />
      )}
      <Pane>
        <EventLogsNetworkWidget controllerName={controllerName} />
      </Pane>
    </PaneContent>
  );
}
