import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Alert, Button, PaneContent } from '@meterup/atto';
import { checkDefinedOrThrow, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import Editor from '@monaco-editor/react';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { fetchControllerJSON, fetchControllerState } from '../../../api/controllers_api';
import { Nav } from '../../../components/Nav';
import { paths } from '../../../constants';
import { styled } from '../../../stitches';
import { NosFeature, useNosFeatureEnabled } from '../../../utils/nosfeature';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/state',
});

const Container = styled('div', { display: 'flex', flexDirection: 'column', height: '100%' });

const EditorContainer = styled('div', {
  height: '100%',
  flex: 1,
  flexShrink: 0,
  overflow: 'hidden',
});

export default function ControllerState() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerState),
  );

  const networkInfo = useQuery(
    ['controller', controllerName],
    async () => fetchControllerJSON(controllerName),
    { suspense: true },
  ).data;

  expectDefinedOrThrow(
    networkInfo,
    new ResourceNotFoundError(`Controller ${controllerName} not found`),
  );

  const stateData = useQuery(
    ['controller', controllerName, 'state'],
    () => fetchControllerState(controllerName),
    { suspense: true },
  ).data;
  const isCOS2Enabled = useNosFeatureEnabled(controllerName, NosFeature.COS2);

  return (
    <PaneContent>
      <Container>
        {isCOS2Enabled && (
          <Alert
            icon="attention"
            variant="negative"
            heading="Attention"
            copy="This network uses COS Config 2.0. It will not report state to this page."
            relation="stacked"
            shoulderButtons={
              networkInfo ? (
                <Button
                  variant="secondary"
                  as="a"
                  target="_blank"
                  href={`${import.meta.env.DASHBOARD_URL}/org/${
                    networkInfo.company_slug
                  }/network/${networkInfo.network_uuid}`}
                >
                  View dashboard
                </Button>
              ) : undefined
            }
          />
        )}
        <EditorContainer>
          <Editor
            language="json"
            value={JSON.stringify(stateData?.state ?? {}, null, 2)}
            options={{ minimap: { enabled: false }, readOnly: true }}
          />
        </EditorContainer>
      </Container>
    </PaneContent>
  );
}
