import type { api } from '@meterup/proto';
import {
  Badge,
  Body,
  CopyBox,
  Section,
  SectionContent,
  SectionHeader,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';
import { isDefined, PendingBadge, UserRoleBadge } from '@meterup/common';
import React from 'react';

import type { HasName } from '../../../../../utils/users';
import { formatFullName } from '../../../../../utils/users';

type HasUserFields = {
  company_role: api.CompanyMembershipRole;
  email: string;
  deletedAt?: string | null;
} & HasName;

export function UserProfileAndPermissionsWidget({ user }: { user: HasUserFields }) {
  const formattedName = formatFullName(user);
  return (
    <Section relation="standalone">
      <SectionHeader heading="Profile & permissions" />
      <SectionContent>
        <SummaryList>
          <SummaryListRow>
            <SummaryListKey>Name</SummaryListKey>
            <SummaryListValue>
              {isDefined(formattedName) ? (
                <CopyBox
                  aria-label="Copy full name"
                  relation="stacked"
                  size="small"
                  value={formattedName}
                >
                  <Body>{formattedName}</Body>
                </CopyBox>
              ) : (
                <PendingBadge />
              )}
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Email</SummaryListKey>
            <SummaryListValue>
              <CopyBox aria-label="Copy email" relation="stacked" size="small" value={user.email}>
                <Body>{user.email}</Body>
              </CopyBox>
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Role</SummaryListKey>
            <SummaryListValue>
              <UserRoleBadge value={user.company_role} />
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Status</SummaryListKey>
            <SummaryListValue>
              {user.deletedAt ? (
                <Badge size="small" variant="neutral">
                  Deleted
                </Badge>
              ) : (
                <Badge size="small" variant="positive">
                  Active
                </Badge>
              )}
            </SummaryListValue>
          </SummaryListRow>
        </SummaryList>
      </SectionContent>
    </Section>
  );
}
