import { createCustomFieldPropsProvider } from '@meterup/atto';
import { useFormikContext } from 'formik';

import { getFieldProps, getInputProps } from '../utils/inputs';

export const FieldProvider = createCustomFieldPropsProvider(({ name }: { name: string }) => {
  const form = useFormikContext<any>();
  return {
    inputProps: getInputProps(form, name),
    fieldProps: getFieldProps(form, name),
  };
});
