/* eslint-disable react/no-unstable-nested-components */
import type { api } from '@meterup/proto';
import type { CellProps, Column } from 'react-table';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Alert, Button, Pane, PaneContent } from '@meterup/atto';
import { checkDefinedOrThrow, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Link } from 'react-router-dom';

import { fetchControllerJSON, fetchIncidents } from '../../../api/controllers_api';
import { AutoTable } from '../../../components/AutoTable/AutoTable';
import { IncidentCategoryBadge } from '../../../components/Incidents/IncidentCategoryBadge';
import { Nav } from '../../../components/Nav';
import {
  CurrentLocalTime,
  StringToDateTime,
  TimestampWithTimezone,
  TimezoneAbbreviation,
} from '../../../components/timestamps';
import { paths } from '../../../constants';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { useCurrentTimezone } from '../../../providers/CurrentTimezoneProvider';
import { makeDrawerLink } from '../../../utils/makeLink';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/incidents',
});

export default function ControllerIncidentsList() {
  const timezone = useCurrentTimezone();

  const tz = TimezoneAbbreviation(timezone);

  const columns: Column<api.ControllerIncident>[] = [
    {
      Header: `Created at (${tz})`,
      accessor: (row) => row.created_at,
      Cell: (row: CellProps<api.ControllerIncident, string>) => (
        <div>
          <TimestampWithTimezone value={row.value} timezone={timezone} />
        </div>
      ),
    },
    {
      Header: `Start time (${tz})`,
      accessor: (row) => row.start_time,
      Cell: (row: CellProps<api.ControllerIncident, string>) => (
        <div>
          <TimestampWithTimezone value={row.value} timezone={timezone} />
        </div>
      ),
    },
    {
      Header: `End time (${tz})`,
      accessor: (row) => row.end_time,
      Cell: (row: CellProps<api.ControllerIncident, string>) => (
        <div>
          <TimestampWithTimezone value={row.value} timezone={timezone} />
        </div>
      ),
    },

    {
      Header: 'Category',
      accessor: (row) => row.category,
      Cell: (row: CellProps<api.ControllerIncident, string>) => (
        <div>
          <IncidentCategoryBadge category={row.value} />
        </div>
      ),
    },
  ];

  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerIncidentsList),
  );

  const network = useQuery(
    ['controller', controllerName, 'json'],
    () => fetchControllerJSON(controllerName),
    {
      suspense: true,
    },
  ).data;

  expectDefinedOrThrow(network, new ResourceNotFoundError('Controller not found'));

  const incidents =
    useQuery(['controller', controllerName, 'incidents'], () => fetchIncidents(controllerName), {
      suspense: true,
    }).data ?? [];

  const now = CurrentLocalTime();
  const currentIncident = incidents.find((i) => {
    const start = StringToDateTime(i.start_time ?? '');
    const end = StringToDateTime(i.end_time ?? '');
    return start <= now && now <= end;
  });

  const currentIncidentAlert = currentIncident ? (
    <Alert
      icon="attention"
      variant="negative"
      heading="Ongoing incident"
      copy="There is an active incident on this controller."
      relation="stacked"
      trailingButtons={
        <Button
          as={Link}
          to={makeDrawerLink(paths.drawers.IncidentDetail, {
            controllerName,
            id: currentIncident.sid,
          })}
        >
          View incident
        </Button>
      }
    />
  ) : null;

  useDocumentTitle('Incidents', controllerName, network);

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.IncidentDetail);

  if (incidents) {
    return (
      <PaneContent>
        <Pane>
          {currentIncidentAlert}
          <AutoTable
            additionalControls={
              <Button
                arrangement="leading-icon"
                icon="plus"
                variant="secondary"
                as={Link}
                to={makeDrawerLink(paths.drawers.IncidentCreate, { controllerName })}
              >
                Create incident
              </Button>
            }
            isRowSelected={(row) => row.sid === drawerParams?.id}
            linkProps={(row) => ({
              to: makeDrawerLink(paths.drawers.IncidentDetail, {
                controllerName,
                id: row.sid,
              }),
            })}
            columns={columns}
            data={incidents}
            csvFileName={`${controllerName}-incidents.csv`}
          />
        </Pane>
      </PaneContent>
    );
  }
}
