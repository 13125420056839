import {
  FieldContainer,
  PrimaryField,
  SecondaryField,
  SectionHeader,
  Select,
  SelectItem,
  Textarea,
  TextInput,
} from '@meterup/atto';
import React from 'react';

import { FieldProvider } from '../../../../../components/FieldProvider';
import { TimezoneAbbreviation } from '../../../../../components/timestamps';
import { useCurrentTimezone } from '../../../../../providers/CurrentTimezoneProvider';
import { categoryOptions } from './form_data';

export function FormContent() {
  const timezone = useCurrentTimezone();
  const tz = TimezoneAbbreviation(timezone);

  return (
    <>
      <FieldContainer>
        <SectionHeader heading="Incident details" />
        <FieldProvider name="start_time">
          <SecondaryField
            label={`Start time (${tz})`}
            element={<TextInput type="datetime-local" />}
          />
        </FieldProvider>
        <FieldProvider name="end_time">
          <SecondaryField
            label={`End time (${tz})`}
            element={<TextInput type="datetime-local" />}
          />
        </FieldProvider>
      </FieldContainer>
      <FieldContainer>
        <FieldProvider name="notes">
          <PrimaryField label="Notes" element={<Textarea />} />
        </FieldProvider>
        <FieldProvider name="category">
          <SecondaryField
            label="Category"
            element={
              <Select placeholder="None" items={categoryOptions}>
                {({ key, label }) => <SelectItem key={key}>{label}</SelectItem>}
              </Select>
            }
          />
        </FieldProvider>
      </FieldContainer>
    </>
  );
}
