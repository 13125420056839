import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Body,
  Button,
  CopyBox,
  Drawer,
  DrawerContent,
  DrawerHeader,
  Heading,
  Section,
  SectionContent,
  SectionHeader,
  SectionTarget,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  SummaryTable,
  SummaryTableArrow,
  SummaryTableCell,
  SummaryTableRow,
  VStack,
} from '@meterup/atto';
import {
  checkDefinedOrThrow,
  CountBadge,
  expectDefinedOrThrow,
  notify,
  ResourceNotFoundError,
} from '@meterup/common';
import { makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { api } from '@meterup/proto';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { fetchCompanyControllersJSON, fetchCompanyJSON } from '../../../api/company_api';
import { fetchCompanyUsersJSON } from '../../../api/users_api';
import GenerateWorkOSPortalLink from '../../../components/GenerateWorkOSPortalLink';
import { Nav } from '../../../components/Nav';
import AddPermittedEmailDomain from '../../../components/PermittedEmailDomains/AddPermittedEmailDomain';
import { paths } from '../../../constants';
import { graphql } from '../../../gql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useFeatureFlag } from '../../../hooks/useFeatureFlags';
import { makeLink } from '../../../utils/makeLink';
import { networksForCompany } from '../../../utils/networks';

export const Meta: PagefileMetaFn = () => ({
  path: '/companies/:companyName',
});

const companiesWithPermittedEmailDomains = graphql(`
  query CompaniesWithPermittedEmailDomains($companyName: String!) {
    getCompany(slug: $companyName) {
      ssoEnabled
      permittedEmailDomains {
        domain
        sid
      }
    }
  }
`);

const removePermittedEmailDomainMutation = graphql(`
  mutation RemovePermittedEmailDomain($companyName: String!, $permittedEmailDomainSID: UUID!) {
    deletePermittedEmailDomain(
      input: { companySlug: $companyName, permittedEmailDomainSID: $permittedEmailDomainSID }
    ) {
      numRowsDeleted
    }
  }
`);

function CompanyNetworksList({ companySlug }: { companySlug: string }) {
  const networks = useGraphQL(networksForCompany, { companySlug });

  if (!networks.data) return null;

  const viewNetworksLink = (
    <SectionTarget
      tabIndex={0}
      as={Link}
      to={makeLink(paths.pages.CompanyNetworks, {
        companyName: companySlug,
      })}
    >
      View networks
    </SectionTarget>
  );

  if (networks.data.networksForCompany.length === 0) {
    return (
      <>
        <SectionContent gutter="all">
          <Body>No networks yet</Body>
        </SectionContent>
        {viewNetworksLink}
      </>
    );
  }

  return (
    <>
      <SummaryTable>
        {networks.data.networksForCompany.map((network) => (
          <SummaryTableRow
            key={network.UUID}
            as="a"
            target="_blank"
            href={`${import.meta.env.DASHBOARD_URL}/org/${companySlug}/network/${network.slug}`}
          >
            <SummaryTableCell>{network.label ? network.label : network.UUID}</SummaryTableCell>
            <SummaryTableArrow />
          </SummaryTableRow>
        ))}
      </SummaryTable>
      {viewNetworksLink}
    </>
  );
}

export default function CompanySummary() {
  const { companyName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.CompanySummary),
  );
  const queryClient = useQueryClient();
  const showSSOForOrgs = useFeatureFlag('sso-for-orgs');

  const [showAddPermittedEmailDomain, setShowAddPermittedEmailDomain] = useState(false);

  const companyData = useQuery(['companies', companyName], () => fetchCompanyJSON(companyName), {
    suspense: true,
  }).data;

  expectDefinedOrThrow(companyData, new ResourceNotFoundError('Company not found'));

  const users =
    useQuery(['companies', companyName, 'users'], () => fetchCompanyUsersJSON(companyName), {
      suspense: true,
    }).data ?? [];

  const controllers =
    useQuery(
      ['companies', companyName, 'controllers'],
      () => fetchCompanyControllersJSON(companyName),
      {
        suspense: true,
      },
    ).data ?? [];

  const removePermittedEmailDomain = useGraphQLMutation(removePermittedEmailDomainMutation);

  const onClickAddPermittedEmailDomains = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setShowAddPermittedEmailDomain(true);
  }, []);

  const invalidateCompanyQuery = useCallback(() => {
    queryClient.invalidateQueries(
      makeQueryKey(companiesWithPermittedEmailDomains, { companyName }),
    );
  }, [companyName, queryClient]);
  const onClickRemovePermittedEmailDomain = useCallback(
    (permittedEmailDomainSID: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      // eslint-disable-next-line no-restricted-globals,no-alert
      const confirmed = confirm('Are you sure you want to remove this permitted email domain?');
      if (confirmed) {
        removePermittedEmailDomain.mutate(
          { companyName, permittedEmailDomainSID },
          {
            onSuccess() {
              notify('Permitted email domain removed', { variant: 'positive' });
              invalidateCompanyQuery();
            },
          },
        );
      }
    },
    [companyName, invalidateCompanyQuery, removePermittedEmailDomain],
  );

  const permittedEmailDomainsQuery = useGraphQL(companiesWithPermittedEmailDomains, {
    companyName,
  });
  const permittedEmailDomains =
    permittedEmailDomainsQuery.data?.getCompany?.permittedEmailDomains ?? [];
  const companyHasSSOEnabled = permittedEmailDomainsQuery.data?.getCompany?.ssoEnabled ?? false;

  const adminUsers = users.filter((u) => u.company_role === api.CompanyMembershipRole.admin);
  const memberUsers = users.filter((u) => u.company_role === api.CompanyMembershipRole.member);
  const guestUsers = users.filter((u) => u.company_role === api.CompanyMembershipRole.guest);

  return (
    <Drawer>
      <DrawerHeader heading="Company" onClose={useCloseDrawerCallback()} />
      <DrawerContent>
        <VStack align="center">
          <CopyBox
            aria-label="Copy company name"
            relation="stacked"
            size="small"
            value={companyData.name}
          >
            <Heading>{companyData.name}</Heading>
          </CopyBox>
          <CopyBox
            aria-label="Copy company slug"
            relation="stacked"
            size="small"
            value={companyData.slug}
          >
            <Body>{companyData.slug}</Body>
          </CopyBox>
        </VStack>
        <Button
          variant="secondary"
          width="100%"
          size="large"
          as="a"
          target="_blank"
          href={`${import.meta.env.DASHBOARD_URL}/org/${companyData.slug}`}
        >
          Open Dashboard
        </Button>

        {/* List of the company's networks */}
        <Section relation="standalone">
          <SectionHeader icon="location" heading="Networks" />
          <CompanyNetworksList companySlug={companyName} />
        </Section>

        {/* List of the company's controllers */}
        <Section relation="standalone">
          <SectionHeader icon="security-appliance" heading="Controllers" />
          <SectionContent gutter={controllers.length > 0 ? undefined : 'all'}>
            {controllers.length > 0 ? (
              <SummaryTable>
                {controllers.map((controller) => (
                  <SummaryTableRow
                    key={controller.name}
                    as={Link}
                    to={makeLink(paths.pages.ControllerDetails, {
                      controllerName: controller.name,
                    })}
                  >
                    <SummaryTableCell>
                      {controller.label ? controller.label : controller.name}
                    </SummaryTableCell>
                    <SummaryTableCell css={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {controller.address}
                    </SummaryTableCell>
                    <SummaryTableArrow />
                  </SummaryTableRow>
                ))}
              </SummaryTable>
            ) : (
              <Body>None</Body>
            )}
          </SectionContent>
        </Section>

        {/* Company user counts */}
        <Section relation="standalone">
          <SectionHeader icon="user" heading="Users" />
          <SectionContent>
            <SummaryList>
              <SummaryListRow>
                <SummaryListKey>All</SummaryListKey>
                <SummaryListValue>
                  <CountBadge value={users.length} />
                </SummaryListValue>
              </SummaryListRow>
              <SummaryListRow>
                <SummaryListKey>Admins</SummaryListKey>
                <SummaryListValue>
                  <CountBadge value={adminUsers.length} />
                </SummaryListValue>
              </SummaryListRow>
              <SummaryListRow>
                <SummaryListKey>Members</SummaryListKey>
                <SummaryListValue>
                  <CountBadge value={memberUsers.length} />
                </SummaryListValue>
              </SummaryListRow>
              <SummaryListRow>
                <SummaryListKey>Guests</SummaryListKey>
                <SummaryListValue>
                  <CountBadge value={guestUsers.length} />
                </SummaryListValue>
              </SummaryListRow>
            </SummaryList>
          </SectionContent>
          <SectionTarget
            tabIndex={0}
            as={Link}
            to={makeLink(paths.pages.CompanyUsers, {
              companyName,
            })}
          >
            View users
          </SectionTarget>
        </Section>

        {/* Company permitted email domains */}
        <Section relation="standalone">
          <SectionHeader icon="email" heading="Permitted email domains" />
          <SectionContent gutter={permittedEmailDomains.length > 0 ? undefined : 'all'}>
            {permittedEmailDomains.length > 0 ? (
              <SummaryTable>
                {permittedEmailDomains.map((permittedEmailDomain) => (
                  <SummaryTableRow key={permittedEmailDomain.sid}>
                    <SummaryTableCell>{permittedEmailDomain.domain}</SummaryTableCell>
                    <SummaryTableCell>
                      <Button
                        variant="destructive"
                        type="button"
                        size="small"
                        onClick={onClickRemovePermittedEmailDomain(permittedEmailDomain.sid || '')}
                      >
                        Remove
                      </Button>
                    </SummaryTableCell>
                  </SummaryTableRow>
                ))}
              </SummaryTable>
            ) : (
              <Body>None</Body>
            )}
          </SectionContent>
          {showAddPermittedEmailDomain ? (
            <SectionContent gutter="all">
              <AddPermittedEmailDomain
                companyName={companyName}
                onCancel={() => setShowAddPermittedEmailDomain(false)}
                onSuccess={() => {
                  setShowAddPermittedEmailDomain(false);
                  invalidateCompanyQuery();
                }}
              />
            </SectionContent>
          ) : (
            <SectionTarget tabIndex={0} as={Link} to="." onClick={onClickAddPermittedEmailDomains}>
              Add permitted email domains
            </SectionTarget>
          )}
        </Section>

        {/* Enabling and configuring SSO for orgs */}
        {showSSOForOrgs ? (
          <GenerateWorkOSPortalLink
            companyHasSSOEnabled={companyHasSSOEnabled}
            companySlug={companyName}
            invalidateCompany={invalidateCompanyQuery}
          />
        ) : null}
      </DrawerContent>
    </Drawer>
  );
}
