import { isEqual as lodashIsEqual } from 'lodash-es';
import { useEffect, useRef } from 'react';

export function useMemoCompare<T>(next: T, isEqual: (a: T, b: T) => boolean = lodashIsEqual): T {
  const previousRef = useRef(next);
  const previous = previousRef.current;

  const hasChanged = !isEqual(previous, next);

  useEffect(() => {
    if (hasChanged) {
      previousRef.current = next;
    }
  });

  return hasChanged ? next : previous;
}
