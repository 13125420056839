import type { HTMLAttributes } from 'react';
import { Icon, styled } from '@meterup/atto';
import React from 'react';

import { TableDataCell, TableHeadCell } from './Table';

const NavigableIcon = styled(Icon, {
  color: '$$rowIconColor',
  width: '$8',
  height: '$8',
});

export function NavigableTablePlaceholderCell() {
  return <TableHeadCell aria-hidden />;
}

export function NavigableTableArrowCell() {
  return (
    <TableDataCell style={{ width: 0 }} aria-hidden>
      <NavigableIcon icon="chevron-right" />
    </TableDataCell>
  );
}

export function NavigableTableDeselectCell(props: HTMLAttributes<HTMLDivElement>) {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    props.onClick?.(e);
  };

  return (
    <TableDataCell {...props} onClick={handleClick} style={{ width: 0 }} aria-hidden>
      <NavigableIcon icon="cross" />
    </TableDataCell>
  );
}
