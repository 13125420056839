import type { Column } from 'react-table';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Button, Link, Pane, VStack } from '@meterup/atto';
import { checkDefinedOrThrow } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import type { NetworksForCompanyQuery } from '../../../gql/graphql';
import { fetchCompanyJSON } from '../../../api/company_api';
import { AutoTable } from '../../../components/AutoTable/AutoTable';
import { Nav } from '../../../components/Nav';
import { PageTitle } from '../../../components/Page';
import { paths } from '../../../constants';
import { makeDrawerLink, makeLink } from '../../../utils/makeLink';
import { networksForCompany } from '../../../utils/networks';

export const Meta: PagefileMetaFn = () => ({
  path: '/companies/:companyName/networks',
});

type Row = NetworksForCompanyQuery['networksForCompany'][number];

export default function CompanyNetworks() {
  const { companyName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.CompanyNetworks),
  );

  const columns = useMemo(() => {
    const c: Column<Row>[] = [
      {
        Header: 'Label',
        accessor: (row) => row.label,
      },
      {
        Header: 'Dashboard',
        // this isn't actually unstable, it's only created when companyName changes
        // eslint-disable-next-line react/no-unstable-nested-components
        accessor: (row) => (
          <Link
            href={`${import.meta.env.DASHBOARD_URL}/org/${companyName}/network/${row.slug}`}
            target="_blank"
          >
            View dashboard
          </Link>
        ),
      },
    ];
    return c;
  }, [companyName]);

  const networksResp = useGraphQL(networksForCompany, { companySlug: companyName });
  const companyData = useQuery(['companies', companyName], () => fetchCompanyJSON(companyName), {
    suspense: true,
  }).data;

  return (
    <Pane>
      <VStack>
        <PageTitle>
          Networks on company{' '}
          <Link
            as={RouterLink}
            to={Nav.makeTo({
              root: makeLink(paths.pages.CompaniesList, {}),
              drawer: makeLink(paths.drawers.CompanySummary, { companyName }),
            })}
          >
            {companyName}
          </Link>
        </PageTitle>

        <AutoTable
          columns={columns}
          data={networksResp?.data?.networksForCompany || []}
          additionalControls={
            !companyData?.external_id && (
              <Button
                as={RouterLink}
                variant="secondary"
                to={makeDrawerLink(paths.drawers.CreateNetwork, { companyName })}
              >
                Add network
              </Button>
            )
          }
        />
      </VStack>
    </Pane>
  );
}
