import type { api } from '@meterup/proto';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Alert,
  CheckboxInputWithLabel,
  Drawer,
  DrawerContent,
  DrawerHeader,
  PrimaryField,
  Section,
  SectionContent,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Textarea,
  TextInput,
} from '@meterup/atto';
import { checkDefinedOrThrow, isDefinedAndNotEmpty } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { fetchNosVersion } from '../../../api/nosversions_api';
import { FieldProvider } from '../../../components/FieldProvider';
import { Nav } from '../../../components/Nav';
import { TimestampWithTimezone, TimezoneAbbreviation } from '../../../components/timestamps';
import { paths } from '../../../constants';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useCurrentTimezone } from '../../../providers/CurrentTimezoneProvider';
import { styled } from '../../../stitches';

export const Meta: PagefileMetaFn = () => ({
  path: '/nos-versions/:nosId',
});

const ValidNosFormData = z.object({
  version: z.string(),
  mc01: z.string().optional(),
  mw03467: z.string().optional(),
  mw08: z.string().optional(),
  mw09: z.string().optional(),
  sos: z.string().optional(),
  release_notes: z.string().optional(),
  is_locked: z.boolean().optional(),
  is_default: z.boolean().optional(),
  major: z.coerce.number().nonnegative().int().optional(),
  minor: z.coerce.number().nonnegative().int().optional(),
  patch: z.coerce.number().nonnegative().int().optional(),
});

type ValidateNosFormData = z.infer<typeof ValidNosFormData>;

const StyledForm = styled('form', {
  display: 'contents',
});

export default function NosVersionSummary() {
  const timezone = useCurrentTimezone();
  const tz = TimezoneAbbreviation(timezone);

  const { nosId } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.NosVersionSummary),
  );

  const nos =
    useQuery(['nosversion', nosId, 'nosversion'], () => fetchNosVersion(nosId), {
      suspense: true,
    }).data ?? ({} as api.NosVersionResp);

  const closeDrawer = useCloseDrawerCallback();

  const [isDefault, setIsDefault] = useState(nos.nos_version?.is_default);
  // No longer allow updates here, use dashboard instead
  const disableEdits = true;
  const disableGaEdit = true;
  const [isLocked, setIsLocked] = useState(disableEdits);

  return (
    <Formik<ValidateNosFormData>
      validationSchema={toFormikValidationSchema(ValidNosFormData)}
      initialValues={{
        version: nos.nos_version?.version || '',
        mc01: nos.nos_version?.mc01_build_name,
        mw03467: nos.nos_version?.mw07_build_name,
        mw08: nos.nos_version?.mw08_build_name,
        mw09: nos.nos_version?.mw09_build_name,
        sos: nos.nos_version?.ms10_build_name,
        release_notes: nos.nos_version?.release_notes,
        is_default: isDefault,
        is_locked: isLocked,
        major: nos.nos_version?.major,
        minor: nos.nos_version?.minor,
        patch: nos.nos_version?.patch,
      }}
      enableReinitialize
      onSubmit={() => {}}
    >
      {(form) => (
        <StyledForm onSubmit={form.handleSubmit}>
          <Drawer>
            <DrawerHeader heading={nos.nos_version?.version} onClose={closeDrawer} />
            <DrawerContent>
              <FieldProvider name="version">
                <PrimaryField label="NOS Version" element={<TextInput disabled={disableEdits} />} />
              </FieldProvider>
              <FieldProvider name="mc01">
                <PrimaryField label="COS" element={<TextInput disabled={disableEdits} />} />
              </FieldProvider>
              <FieldProvider name="mw03467">
                <PrimaryField
                  label="MW03, MW04, MW06, MW07"
                  element={<TextInput disabled={disableEdits} />}
                />
              </FieldProvider>
              <FieldProvider name="mw08">
                <PrimaryField label="MW08" element={<TextInput disabled={disableEdits} />} />
              </FieldProvider>
              <FieldProvider name="mw09">
                <PrimaryField label="MW09" element={<TextInput disabled={disableEdits} />} />
              </FieldProvider>
              <FieldProvider name="sos">
                <PrimaryField label="MS10, MS11" element={<TextInput disabled={disableEdits} />} />
              </FieldProvider>
              <FieldProvider name="major">
                <PrimaryField label="Major" element={<TextInput disabled={disableEdits} />} />
              </FieldProvider>
              <FieldProvider name="minor">
                <PrimaryField label="Minor" element={<TextInput disabled={disableEdits} />} />
              </FieldProvider>
              <FieldProvider name="patch">
                <PrimaryField label="Patch" element={<TextInput disabled={disableEdits} />} />
              </FieldProvider>
              <FieldProvider name="is_default">
                <CheckboxInputWithLabel
                  checked={isDefault}
                  disabled={disableGaEdit}
                  onChange={(checked: boolean) => setIsDefault(checked)}
                  aria-label="GA"
                >
                  Mark as GA
                </CheckboxInputWithLabel>
              </FieldProvider>
              {isDefault && (
                <Section relation="stacked">
                  <Alert
                    variant="negative"
                    copy="This NOS version is the default for all Meter Networks."
                  />
                </Section>
              )}
              <FieldProvider name="is_locked">
                <CheckboxInputWithLabel
                  disabled={disableEdits}
                  checked={isLocked}
                  onChange={(checked: boolean) => setIsLocked(checked)}
                  aria-label="locked"
                >
                  Lock build names in this version. (No more edits to build names)
                </CheckboxInputWithLabel>
              </FieldProvider>
              <FieldProvider name="release_notes">
                <PrimaryField
                  label="release_notes"
                  element={<Textarea disabled={disableEdits} />}
                />
              </FieldProvider>
              {isDefinedAndNotEmpty(nos.updated_at) && (
                <Section relation="standalone">
                  <SectionContent>
                    <SummaryList>
                      <SummaryListRow>
                        <SummaryListKey>Last updated</SummaryListKey>
                        <SummaryListValue>
                          <TimestampWithTimezone value={nos.updated_at} timezone={timezone} /> {tz}
                        </SummaryListValue>
                      </SummaryListRow>
                    </SummaryList>
                  </SectionContent>
                </Section>
              )}
            </DrawerContent>
          </Drawer>
        </StyledForm>
      )}
    </Formik>
  );
}
