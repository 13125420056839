import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Alert, Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader } from '@meterup/atto';
import { checkDefinedOrThrow, expectDefinedOrThrow, isDefined } from '@meterup/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Formik } from 'formik';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import type { ValidPlanData } from './common/form_data';
import { getInternetServicePlan, updateInternetServicePlan } from '../../../../api/controllers_api';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { styled } from '../../../../stitches';
import { makeDrawerLink } from '../../../../utils/makeLink';
import { fromAPIData, toISPUpdateRequest, validPlanData } from './common/form_data';
import { FormContent } from './common/FormContent';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/plans/:planId/edit',
});

const StyledForm = styled('form', {
  height: '100%',
});

export default function ISPEdit() {
  const navigate = useNavigate();
  const closeDrawer = useCloseDrawerCallback();

  const { controllerName, planId } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.ISPDetails),
  );

  const provider = useQuery(
    ['controllers', controllerName, 'service-plans', planId],
    () => getInternetServicePlan(controllerName, planId),
    { suspense: true },
  ).data;

  expectDefinedOrThrow(provider);

  const toProviderDetail = makeDrawerLink(paths.drawers.ISPDetails, { controllerName, planId });

  const queryClient = useQueryClient();
  const editDrawerMutation = useMutation(
    async (data: ValidPlanData) => {
      const apiData = toISPUpdateRequest(data);
      await updateInternetServicePlan(controllerName, planId, apiData.planData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controllers', controllerName, 'service-plans']);
        navigate(toProviderDetail);
      },
    },
  );

  return (
    <Formik<ValidPlanData>
      initialValues={fromAPIData(provider)}
      validationSchema={toFormikValidationSchema(validPlanData)}
      onSubmit={(values) => editDrawerMutation.mutate(values)}
    >
      {(form) => (
        <StyledForm onSubmit={form.handleSubmit}>
          <Drawer>
            <DrawerHeader heading="Edit plan" onClose={closeDrawer} />
            <DrawerContent>
              {isDefined(editDrawerMutation.error) && (
                <Alert
                  heading="Error while submitting"
                  copy={JSON.stringify(editDrawerMutation.error)}
                />
              )}
              <FormContent controllerName={controllerName} />
            </DrawerContent>
            <DrawerFooter
              actions={
                <>
                  <Button
                    type="button"
                    variant="secondary"
                    as={Link}
                    to={toProviderDetail}
                    loading={editDrawerMutation.isLoading}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" loading={editDrawerMutation.isLoading}>
                    Save
                  </Button>
                </>
              }
            />
          </Drawer>
        </StyledForm>
      )}
    </Formik>
  );
}
