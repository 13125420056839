import type { CellProps, Column } from 'react-table';
import { Section, SectionContent, SectionHeader } from '@meterup/atto';
import {
  isDefined,
  isGuest,
  isOnline,
  NeutralBadge,
  PositiveBadge,
  SignalStrengthBadge,
  WiredBadge,
} from '@meterup/common';
import { useQuery } from '@tanstack/react-query';

import { fetchClientsGql } from '../api/clients_api';
import { paths } from '../constants';
import { useCloseDrawerCallback } from '../hooks/useCloseDrawerCallback';
import { type NetworkClient, isWired, isWireless } from '../utils/clients';
import { makeDrawerLink, makeLink } from '../utils/makeLink';
import { NosFeature, useNosFeatureEnabled } from '../utils/nosfeature';
import { AutoTable } from './AutoTable/AutoTable';
import { ListItemTableContainer } from './ListItemTableContainer';
import { ListItemViewAllLink } from './ListItemViewAllLink';
import { Nav } from './Nav';
import { ListItemStatList, StatItem, StatItemLabel } from './StatItemLabel';

const columns: Column<NetworkClient>[] = [
  {
    Header: 'Name',
    accessor: (d) => d.clientName,
  },
  {
    Header: 'Signal',
    accessor: (d) => (isWireless(d) ? d.signal : null),
    Cell: (props: CellProps<NetworkClient, number | null>) =>
      isWired(props.row.original) ? <WiredBadge /> : <SignalStrengthBadge value={props.value} />,
  },
];

export function ClientsWidget({
  controllerName,
  networkUUID,
}: {
  controllerName: string;
  networkUUID: string;
}) {
  const clients =
    useQuery(['controller', controllerName, 'clients'], async () => fetchClientsGql(networkUUID), {
      suspense: true,
    }).data ?? [];

  const limitedClients = clients.slice(0, 5);

  const onlineCount = clients.filter(isOnline).length;
  const guestCount = clients.filter(isGuest).length;
  const wiredCount = clients.filter(isWired).length;

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.ClientDetail);

  const closeDrawer = useCloseDrawerCallback();

  const isWOS2Enabled = useNosFeatureEnabled(controllerName, NosFeature.WOS2);
  if (isWOS2Enabled) {
    return (
      <Section relation="standalone">
        <SectionHeader
          heading="Clients (Use Dashboard)"
          icon="client"
          actions={
            <NeutralBadge ends="card" variant="brand">
              WOS Config 2.0
            </NeutralBadge>
          }
        />
      </Section>
    );
  }

  return (
    <Section relation="standalone">
      <SectionHeader icon="client" heading="Clients" />
      <SectionContent>
        <ListItemStatList>
          <StatItem>
            <PositiveBadge size="large" icon="client" arrangement="leading-icon">
              {onlineCount}
            </PositiveBadge>
            <StatItemLabel>Online</StatItemLabel>
          </StatItem>
          <StatItem>
            <NeutralBadge size="large" icon="client" arrangement="leading-icon">
              {guestCount}
            </NeutralBadge>
            <StatItemLabel>Guest</StatItemLabel>
          </StatItem>
          <StatItem>
            <NeutralBadge size="large" icon="wired" arrangement="leading-icon">
              {wiredCount}
            </NeutralBadge>
            <StatItemLabel>Wired</StatItemLabel>
          </StatItem>
        </ListItemStatList>
        <ListItemTableContainer>
          <AutoTable<NetworkClient>
            columns={columns}
            data={limitedClients}
            shouldShowTopBar={false}
            onRowDeselect={closeDrawer}
            isRowSelected={(row) => isDefined(drawerParams) && row.macAddress === drawerParams.mac}
            linkProps={(row) => ({
              to: makeDrawerLink(paths.drawers.ClientDetail, {
                controllerName,
                // TODO: Determine if lease is ever undefined?
                mac: row.macAddress ?? '',
              }),
            })}
          />
        </ListItemTableContainer>
      </SectionContent>
      <ListItemViewAllLink to={makeLink(paths.pages.ControllerClientsList, { controllerName })}>
        View all{' '}
        {limitedClients.length < clients.length
          ? `(Showing ${limitedClients.length} out of ${clients.length})`
          : ''}
      </ListItemViewAllLink>
    </Section>
  );
}
