import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  FieldContainer,
  SecondaryField,
  SectionHeader,
  TextInput,
  ToggleInput,
} from '@meterup/atto';
import { checkDefinedOrThrow, isDefined, isDefinedAndNotEmpty } from '@meterup/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Formik } from 'formik';
import { get } from 'lodash-es';
import { Link, useNavigate } from 'react-router-dom';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import type { FixMeLater } from '../../../../utils/FixMeLater';
import type { ValidVPNServerEditData } from './form_data';
import {
  fetchControllerConfig,
  updateControllerVPNServer,
  upsertControllerConfigKey,
} from '../../../../api/controllers_api';
import { fetchVPNServer } from '../../../../api/vpn';
import { FieldProvider } from '../../../../components/FieldProvider';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { styled } from '../../../../stitches';
import { makeDrawerLink } from '../../../../utils/makeLink';
import { validVPNServerEditData } from './form_data';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/vpn-server/edit',
});

const StyledForm = styled('form', {
  display: 'contents',
});

export default function VPNServerEdit() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const closeDrawer = useCloseDrawerCallback();

  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.VPNServerEdit),
  );
  const toServerDetail = makeDrawerLink(paths.drawers.VPNServerDetailPage, { controllerName });

  const config = useQuery(
    ['controller', controllerName, 'config'],
    async () => fetchControllerConfig(controllerName),
    {
      suspense: true,
      refetchInterval: 1000,
    },
  ).data?.config as FixMeLater;

  const enabled = !!get(config, ['meter.v1.network.vpn.client', 'enabled']);
  const port = get(config, ['meter.v1.network.vpn.client', 'port']).toString();
  const address = get(config, ['meter.v1.network.vpn.client', 'address']);

  const vpnServerDetails = useQuery(
    ['controller', controllerName, 'vpn-servers'],
    () => fetchVPNServer(controllerName),
    { suspense: true, enabled: true },
  ).data;

  const updateConfig = (data: ValidVPNServerEditData) => {
    config['meter.v1.network.vpn.client'].enabled = !!data.enabled;
    if (isDefinedAndNotEmpty(data.port)) {
      config['meter.v1.network.vpn.client'].port = Number(data.port);
    }
    if (isDefinedAndNotEmpty(data.address)) {
      config['meter.v1.network.vpn.client'].address = data.address;
    }
    upsertControllerConfigKey(
      controllerName,
      'meter.v1.network.vpn.client',
      config['meter.v1.network.vpn.client'],
    );

    if (vpnServerDetails && data.defaultAllowedIPs) {
      // @ts-ignore expects public_key which should be nullable
      updateControllerVPNServer(controllerName, {
        default_client_allowed_ips: data.defaultAllowedIPs.split(','),
      });
    }
  };

  const editDrawerMutation = useMutation(
    async (data: ValidVPNServerEditData) => {
      await updateConfig(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controllers', controllerName, 'vpn-server']);
        navigate(toServerDetail);
      },
    },
  );

  return (
    <Formik<ValidVPNServerEditData>
      initialValues={{
        port: port ?? '',
        address: address ?? '',
        enabled,
        defaultAllowedIPs: vpnServerDetails?.default_client_allowed_ips.join(',') ?? '',
      }}
      validationSchema={toFormikValidationSchema(validVPNServerEditData)}
      onSubmit={(values) => editDrawerMutation.mutate(values)}
    >
      {(form) => (
        <StyledForm onSubmit={form.handleSubmit}>
          <Drawer>
            <DrawerHeader heading="Edit VPN server" onClose={closeDrawer} />
            <DrawerContent>
              <Alert
                icon="attention"
                variant="brand"
                heading="Downtime expected"
                copy="Editing VPN server configuration details results in a network restart. Please expect up to 30 seconds of network downtime."
                relation="standalone"
              />
              {isDefined(editDrawerMutation.error) && (
                <Alert
                  heading="Error while submitting"
                  copy={JSON.stringify(editDrawerMutation.error)}
                />
              )}

              <FieldContainer>
                <SectionHeader heading="VPN server details" />
                <FieldProvider name="port">
                  <SecondaryField label="Port" element={<TextInput />} />
                </FieldProvider>
                <FieldProvider name="address">
                  <SecondaryField label="Address" element={<TextInput />} />
                </FieldProvider>
                <FieldProvider name="defaultAllowedIPs">
                  <SecondaryField
                    label="Default Client Allowed IPs"
                    description="Separate each by a comma"
                    element={<TextInput />}
                  />
                </FieldProvider>
                <FieldProvider name="enabled">
                  <SecondaryField
                    label="Enabled"
                    element={<ToggleInput selected={!!form.values.enabled} />}
                  />
                </FieldProvider>
              </FieldContainer>
            </DrawerContent>
            <DrawerFooter
              actions={
                <>
                  <Button
                    type="button"
                    variant="secondary"
                    as={Link}
                    to={toServerDetail}
                    loading={editDrawerMutation.isLoading}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" loading={editDrawerMutation.isLoading}>
                    Save
                  </Button>
                </>
              }
            />
          </Drawer>
        </StyledForm>
      )}
    </Formik>
  );
}
