import type { UseMutationResult } from '@tanstack/react-query';
import { Alert, Button, CheckboxInputWithLabel, DrawerContent, DrawerFooter } from '@meterup/atto';
import { getErrorMessage } from '@meterup/common';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';

import type { ValidLegacyNetworkInfoFormData } from './ValidLegacyNetworkInfoFormData';
import { LegacyNetworkInfoCards } from './LegacyNetworkInfoCards';
import { formDataToAPIData } from './ValidLegacyNetworkInfoFormData';

export default function ReviewLegacyNetworkInfoForm({
  onClickBack,
  mutationResult,
}: {
  mutationResult: UseMutationResult;
  onClickBack: () => void;
}) {
  const form = useFormikContext<ValidLegacyNetworkInfoFormData>();
  const [hasConfirmed, setHasConfirmed] = useState(false);

  return (
    <>
      <DrawerContent>
        <Alert
          heading="Please confirm"
          copy="Changes to the wireless network settings will render the network unavailable for a period of time."
        />
        {mutationResult.error ? (
          <Alert
            variant="negative"
            heading="Unexpected error"
            copy={getErrorMessage(mutationResult.error)}
          />
        ) : null}
        <LegacyNetworkInfoCards networkPass={formDataToAPIData(form.values)} />
        <div style={{ position: 'relative' }}>
          <CheckboxInputWithLabel checked={hasConfirmed} onChange={(v) => setHasConfirmed(v)}>
            I understand the risks and wish to continue.
          </CheckboxInputWithLabel>
        </div>
      </DrawerContent>
      <DrawerFooter
        actions={
          <>
            <Button variant="secondary" onClick={onClickBack}>
              Back
            </Button>
            <Button type="submit" loading={mutationResult.isLoading} disabled={!hasConfirmed}>
              Save
            </Button>
          </>
        }
      />
    </>
  );
}
