import type { FormEvent } from 'react';
import { Button, HStack, Label, space, TextInput, VStack } from '@meterup/atto';
import { PrimaryFieldDescription } from '@meterup/atto/src/controls/Field/common/FieldLabel';
import { notify } from '@meterup/common';
import { useGraphQLMutation } from '@meterup/graphql';
import { isEmpty } from 'lodash-es';
import React, { useCallback, useState } from 'react';

import { graphql } from '../../gql';

const createPermittedEmailDomainQuery = graphql(`
  mutation CreatePermittedEmailDomain($companyName: String!, $emailDomain: String!) {
    createPermittedEmailDomain(input: { companySlug: $companyName, domain: $emailDomain }) {
      sid
      domain
    }
  }
`);

type AddPermittedEmailDomainProps = {
  companyName: string;
  onCancel: () => void;
  onSuccess: () => void;
};

export default function AddPermittedEmailDomain({
  companyName,
  onCancel,
  onSuccess,
}: AddPermittedEmailDomainProps) {
  const createPermittedEmailDomain = useGraphQLMutation(createPermittedEmailDomainQuery);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [emailDomain, setEmailDomain] = useState('');
  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (isEmpty(emailDomain)) {
        setErrorMessage('Email domain cannot be empty');
      }
      createPermittedEmailDomain.mutate(
        {
          companyName,
          emailDomain,
        },
        {
          onError: (err) => {
            const messages = (err.response.errors || []).map((error) => error.message);
            setErrorMessage(messages.join(', '));
            notify('Error adding permitted email domain', {
              variant: 'negative',
            });
          },
          onSuccess: (result) => {
            notify(`Added ${result.createPermittedEmailDomain.domain}`, {
              variant: 'positive',
            });
            onSuccess();
          },
        },
      );
    },
    [companyName, createPermittedEmailDomain, emailDomain, onSuccess],
  );
  const onChange = useCallback((val: string) => {
    setEmailDomain(val);
    setErrorMessage(null);
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <VStack spacing={space(8)} align="end">
        <VStack spacing={space(4)} align="start" width="full">
          <Label htmlFor="email-domain" invalid={!!errorMessage}>
            Email domain
          </Label>
          {errorMessage && (
            <PrimaryFieldDescription aria-invalid>{errorMessage}</PrimaryFieldDescription>
          )}
        </VStack>
        <TextInput
          id="email-domain"
          label="Email domain"
          width="100%"
          value={emailDomain}
          invalid={!!errorMessage}
          onChange={onChange}
        />
        <HStack spacing={space(6)}>
          <Button type="button" variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">Add</Button>
        </HStack>
      </VStack>
    </form>
  );
}
