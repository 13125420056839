import type { api } from '@meterup/proto';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Drawer, DrawerContent, DrawerHeader } from '@meterup/atto';
import { checkDefinedOrThrow } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';

import { fetchNosVersion } from '../../../api/nosversions_api';
import { Nav } from '../../../components/Nav';
import { NosInformationWidget } from '../../../components/NosInfomationWidget';
import { WidgetSuspenseAndErrorBoundary } from '../../../components/WidgetSuspenseAndErrorBoundary';
import { paths } from '../../../constants';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';

export const Meta: PagefileMetaFn = () => ({
  path: '/nos-versions/view/:nosId',
});

export default function NosVersionQuickView() {
  const { nosId } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.NosVersionQuickView),
  );

  const nos =
    useQuery(['nosversion', nosId, 'nosversion'], () => fetchNosVersion(nosId), {
      suspense: true,
    }).data ?? ({} as api.NosVersionResp);

  const closeDrawer = useCloseDrawerCallback();

  return (
    <Drawer>
      <DrawerHeader heading="NOS version information" onClose={closeDrawer} />
      <DrawerContent>
        <WidgetSuspenseAndErrorBoundary title="NOS version information">
          <NosInformationWidget nos={nos.nos_version || ({} as api.NosVersion)} />
        </WidgetSuspenseAndErrorBoundary>
      </DrawerContent>
    </Drawer>
  );
}
