import type { api } from '@meterup/proto';
import type { CellProps, Column } from 'react-table';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Badge, Pane } from '@meterup/atto';
import { Priority, useCommand, useRegisterCommands } from '@meterup/command';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { fetchCompaniesJSON } from '../../../api/company_api';
import { AutoTable } from '../../../components/AutoTable/AutoTable';
import { Nav } from '../../../components/Nav';
import { paths } from '../../../constants';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { makeDrawerLink, makeLink } from '../../../utils/makeLink';

const columns: Column<api.CompanyResponse>[] = [
  {
    Header: 'Company name',
    accessor: (row) => row.name,
  },
  {
    Header: 'Slug',
    accessor: (row) => row.slug,
    Cell: (props: CellProps<api.CompanyResponse>) => <Badge variant="neutral">{props.value}</Badge>,
  },
  {
    Header: 'Customer or internal?',
    accessor: (row) => (row.external_id ? 'Customer' : 'Internal'),
    Cell: (props: CellProps<api.CompanyResponse>) =>
      props.value === 'Customer' ? (
        <Badge variant="positive">Customer</Badge>
      ) : (
        <Badge variant="alternative">Internal</Badge>
      ),
  },
];

export const Meta: PagefileMetaFn = () => ({
  path: '/companies',
});

const empty: api.CompanyResponse[] = [];

export default function CompaniesList() {
  const closeDrawer = useCloseDrawerCallback();

  const companies = useQuery(['companies'], () => fetchCompaniesJSON()).data ?? empty;

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.CompanySummary);

  const navigate = useNavigate();

  const [hoveredCompany, setHoveredCompany] = useState<api.CompanyResponse | null>(null);

  const inContextCompany = useMemo(
    () =>
      drawerParams?.companyName
        ? companies.find((c) => c.slug === drawerParams.companyName) ?? null
        : hoveredCompany,
    [companies, drawerParams?.companyName, hoveredCompany],
  );

  const { state } = useCommand();

  useRegisterCommands(
    inContextCompany
      ? [
          state.nodeFactory.action({
            id: 'open-dashboard',
            label: 'Open dashboard',
            display: 'Open dashboard',
            group: `${inContextCompany.name} - (${inContextCompany.slug})`,
            onSelect() {
              window.open(
                `${import.meta.env.DASHBOARD_URL}/org/${inContextCompany.slug}`,
                '_blank',
              );
            },
            priority: Priority.High,
            shortcut: '$mod+o',
          }),
          state.nodeFactory.action({
            id: 'view-users',
            label: 'View users',
            display: 'View users',
            group: `${inContextCompany.name} - (${inContextCompany.slug})`,
            priority: Priority.High,
            shortcut: '$mod+Shift+u',
            onSelect() {
              navigate(
                makeLink(paths.pages.CompanyUsers, {
                  companyName: inContextCompany.name,
                }),
              );
            },
          }),
        ]
      : [],
    [inContextCompany],
  );

  return (
    <Pane>
      <AutoTable
        icon="company"
        heading="Companies"
        columns={columns}
        data={companies}
        onRowDeselect={closeDrawer}
        isRowSelected={(row) => row.slug === drawerParams?.companyName}
        linkProps={(row) => ({
          to: makeDrawerLink(paths.drawers.CompanySummary, { companyName: row.slug }),
          onPointerEnter() {
            if (drawerParams) {
              return;
            }

            setHoveredCompany(row);
          },
        })}
      />
    </Pane>
  );
}
