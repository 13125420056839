import type { api } from '@meterup/proto';
import {
  Badge,
  CopyBox,
  Section,
  SectionContent,
  SectionHeader,
  Small,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';
import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash-es';

import type { FixMeLater } from '../../../../utils/FixMeLater';
import { fetchControllerConfig } from '../../../../api/controllers_api';
import { fetchVPNServer } from '../../../../api/vpn';

export default function VPNServerDetailContent({
  controllerName,
  vpnServer,
}: {
  controllerName: string;
  vpnServer: api.VPNServer;
}) {
  const config = useQuery(
    ['controller', controllerName, 'config'],
    async () => fetchControllerConfig(controllerName),
    {
      suspense: true,
      refetchInterval: 1000,
    },
  ).data?.config as FixMeLater;

  const vpnServerDetails = useQuery(
    ['controller', controllerName, 'vpn-servers'],
    () => fetchVPNServer(controllerName),
    { suspense: true, enabled: true },
  ).data;

  const enabled = !!get(config, ['meter.v1.network.vpn.client', 'enabled']);
  const address = get(config, ['meter.v1.network.vpn.client', 'address']);

  return (
    <>
      <SummaryList>
        <SummaryListRow>
          <SummaryListKey>Status</SummaryListKey>
          <SummaryListValue>
            {enabled !== null && enabled !== undefined && enabled && (
              <Badge variant="positive" ends="pill">
                Enabled
              </Badge>
            )}
            {enabled === undefined ||
              enabled === null ||
              (!enabled && (
                <Badge variant="neutral" ends="pill">
                  Disabled
                </Badge>
              ))}
          </SummaryListValue>
        </SummaryListRow>
      </SummaryList>

      <Section relation="standalone">
        <SectionHeader icon="information" heading="Details" />
        <SectionContent>
          <SummaryList>
            <SummaryListRow>
              <SummaryListKey>Endpoint</SummaryListKey>
              <SummaryListValue>
                <CopyBox
                  aria-label="Copy VPN server endpoint"
                  relation="stacked"
                  size="small"
                  value={vpnServer.endpoint}
                >
                  <Small family="monospace">{vpnServer.endpoint}</Small>
                </CopyBox>
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>Public key</SummaryListKey>
              <SummaryListValue>
                <CopyBox
                  aria-label="Copy VPN server public key"
                  relation="stacked"
                  size="small"
                  value={vpnServer.public_key?.toString() ?? ''}
                >
                  <Small family="monospace">{vpnServer.public_key}</Small>
                </CopyBox>
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>Port</SummaryListKey>
              <SummaryListValue>
                <CopyBox
                  aria-label="Copy VPN server port"
                  relation="stacked"
                  size="small"
                  value={vpnServer.port?.toString() ?? ''}
                >
                  <Small family="monospace">{vpnServer.port}</Small>
                </CopyBox>
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>Address</SummaryListKey>
              <SummaryListValue>
                <CopyBox
                  aria-label="Copy VPN server address"
                  relation="stacked"
                  size="small"
                  value={address ?? ''}
                >
                  <Small family="monospace">{address}</Small>
                </CopyBox>
              </SummaryListValue>
            </SummaryListRow>
            {vpnServerDetails && (
              <SummaryListRow>
                <SummaryListKey>Default Client Allowed IPs</SummaryListKey>
                <SummaryListValue>
                  <Small family="monospace">
                    {vpnServerDetails.default_client_allowed_ips.join(',')}
                  </Small>
                </SummaryListValue>
              </SummaryListRow>
            )}
          </SummaryList>
        </SectionContent>
      </Section>
    </>
  );
}
