import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Pane, PaneContent } from '@meterup/atto';
import { checkDefinedOrThrow } from '@meterup/common';
import React from 'react';

import Clients from '../../../components/Client/Clients';
import { Nav } from '../../../components/Nav';
import { paths } from '../../../constants';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/devices/:deviceName/clients',
});

export default function ControllerDeviceClients() {
  const { controllerName, deviceName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerDeviceClients),
  );

  return (
    <Pane>
      <PaneContent>
        <Clients controllerName={controllerName} deviceName={deviceName} />
      </PaneContent>
    </Pane>
  );
}
