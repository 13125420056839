import { isDefined } from '@meterup/common';
import { api } from '@meterup/proto';
import { camelCase } from 'lodash-es';
import * as z from 'zod';

type HasCamelName = {
  firstName?: string | null;
  lastName?: string | null;
};

export type HasName = { first_name?: string; last_name?: string } | HasCamelName;

export const formatFullName = (user: HasName) => {
  const u = Object.keys(user).reduce((acc, key) => {
    acc[camelCase(key) as keyof HasCamelName] = user[key as keyof HasName];
    return acc;
  }, {} as HasCamelName);
  const values = [u.firstName, u.lastName].filter(isDefined);

  if (values.length > 0) {
    return values.join(' ');
  }

  return null;
};

export const validEmail = z.string().email();

export const validUserRole = z.union([
  z.literal(api.CompanyMembershipRole.guest),
  z.literal(api.CompanyMembershipRole.member),
  z.literal(api.CompanyMembershipRole.admin),
]);
