import { FieldContainer, PrimaryField, SectionHeader, Textarea, TextInput } from '@meterup/atto';

import { FieldProvider } from '../../../../components/FieldProvider';

export function VPNClientCreateForm() {
  return (
    <FieldContainer>
      <SectionHeader heading="VPN client details" />
      <FieldProvider name="name">
        <PrimaryField label="Name" element={<TextInput />} />
      </FieldProvider>
      <FieldProvider name="email">
        <PrimaryField label="Email" element={<TextInput />} />
      </FieldProvider>
      <FieldProvider name="public_key">
        <PrimaryField label="Public Key" element={<Textarea />} />
      </FieldProvider>
    </FieldContainer>
  );
}
