import {
  Body,
  Button,
  HStack,
  Icon,
  Label,
  PaneFooter,
  space,
  Textarea,
  VStack,
} from '@meterup/atto';
import { checkDefinedOrThrow } from '@meterup/common';
import React from 'react';
import { Link as ReactRouterLink, Route } from 'react-router-dom';

import { Nav } from '../components/Nav';
import { PageControls } from '../components/Page';
import { paths } from '../constants';
import { useConfigEditor } from '../context/ConfigEditorContext';
import { colors, styled } from '../stitches';
import { makeLink } from '../utils/makeLink';

const UnsavedText = styled(Body, {
  color: colors.gray600,
});

const ErrorText = styled(Body, {
  color: colors.red700,
});

const StyledPaneFooter = styled(PaneFooter, {
  alignItems: 'stretch',
});

function SaveChangesFooter() {
  const config = useConfigEditor();

  return config.isDraftModified ? (
    <StyledPaneFooter
      secondaryActions={
        <VStack spacing={space(4)}>
          <Label htmlFor="Label-changeComment" disabled aria-label="Change comment">
            Change comment (required)
          </Label>
          <Textarea
            name="Label-changeComment"
            size="small"
            value={config.changeComment}
            onChange={(e) => config.handleChangeComment(e.target.value)}
          />
        </VStack>
      }
      actions={
        <HStack>
          <div>
            {config.isDraftTextValidJSON ? (
              <UnsavedText>{`Unsaved changes${
                !config.changeComment ? '. A change comment is required.' : ''
              }`}</UnsavedText>
            ) : (
              <HStack align="center" spacing={space(8)}>
                <Icon icon="warning" color={{ light: 'red600' }} />
                <ErrorText>The editor contains invalid JSON</ErrorText>
                <Button variant="secondary" size="small" onClick={config.resetDraftTextToModel}>
                  Revert editor to last valid state
                </Button>
              </HStack>
            )}
          </div>
          <PageControls>
            <Button key="cancel" variant="secondary" onClick={config.resetDraft}>
              Discard changes
            </Button>
            <Button
              onClick={config.saveDraftModel}
              loading={config.isSaving}
              disabled={!config.isDraftTextValidJSON || !config.changeComment}
            >
              Save changes
            </Button>
          </PageControls>
        </HStack>
      }
    />
  ) : null;
}

function ReviewChangesFooter() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerDetails),
  );

  const config = useConfigEditor();

  return config.isDraftModified ? (
    <PaneFooter>
      <div>
        <UnsavedText>Unsaved changes</UnsavedText>
      </div>
      <PageControls>
        <Button key="cancel" variant="secondary" onClick={config.resetDraft}>
          Discard changes
        </Button>
        <Button
          as={ReactRouterLink}
          to={makeLink(paths.pages.ControllerConfig, { controllerName })}
        >
          Review changes
        </Button>
      </PageControls>
    </PaneFooter>
  ) : null;
}

export function ControllerPageFooter() {
  return (
    <Nav.Routes regionName="root">
      <Route path={paths.pages.ControllerConfig} element={<SaveChangesFooter />} />
      <Route path="*" element={<ReviewChangesFooter />} />
    </Nav.Routes>
  );
}
