import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Badge,
  Body,
  Button,
  CopyBox,
  Drawer,
  DrawerContent,
  DrawerHeader,
  Section,
  SectionContent,
  SectionHeader,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  formatDataRateBits,
  isDefined,
  kilobitsPerSecond,
  lookupMACAddressOUI,
  OnlineOfflineStatusBadge,
  ResourceNotFoundError,
  SignalStrengthBadge,
  UnknownBadge,
  ValueOrUnknownBadge,
} from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import { Link as ReactRouterLink } from 'react-router-dom';

import { fetchClientsGql } from '../../../../api/clients_api';
import { fetchControllerJSON } from '../../../../api/controllers_api';
import { fetchDevices } from '../../../../api/devices_api';
import { Nav } from '../../../../components/Nav';
import { DistanceToNowTimestamp } from '../../../../components/timestamps';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { type NetworkClient, isLastSeenKnown, isWireless } from '../../../../utils/clients';
import { getGrafanaURL } from '../../../../utils/grafana';
import { makeLink } from '../../../../utils/makeLink';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/clients/:mac',
});

export default function ClientDetail() {
  const { controllerName, mac } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.ClientDetail),
  );

  const controller = useQuery(
    ['controllers', controllerName],
    () => fetchControllerJSON(controllerName),
    {
      suspense: true,
    },
  ).data || { network_uuid: '' };

  const clientHistory =
    useQuery(
      ['controller', controllerName, 'clients', mac],
      () => fetchClientsGql(controller.network_uuid, mac),
      { suspense: true },
    ).data ?? [];

  const firstEntry = clientHistory[0];

  expectDefinedOrThrow(firstEntry, new ResourceNotFoundError(`Client ${mac} not found`));

  const devices = useQuery(
    ['controller', controllerName, 'devices', firstEntry.apSerialNumber],
    () => fetchDevices(controllerName),
    {
      suspense: true,
    },
  ).data;

  const accessPoint = devices?.access_points.find((ap) => ap.name === firstEntry.apSerialNumber);
  const switchDevice = devices?.switches.find((s) => firstEntry.macAddress === s.mac);
  const ownAccessPoint = devices?.access_points.find((ap) => ap.name === firstEntry.clientName);

  const ssids = firstEntry.ssid ? [firstEntry.ssid] : [];

  return (
    <Drawer>
      <DrawerHeader heading="Client" onClose={useCloseDrawerCallback()} />
      <DrawerContent>
        {isDefined(firstEntry?.macAddress) && (
          <Section relation="standalone">
            <SectionHeader heading="Hardware" />
            <SectionContent>
              <SummaryList>
                <SummaryListRow>
                  <SummaryListKey>Manufacturer</SummaryListKey>
                  <SummaryListValue>
                    {lookupMACAddressOUI(firstEntry.macAddress) ?? <UnknownBadge />}
                  </SummaryListValue>
                </SummaryListRow>
                {isDefined(ownAccessPoint) && (
                  <SummaryListRow>
                    <SummaryListKey>Access point</SummaryListKey>
                    <SummaryListValue>
                      <ReactRouterLink
                        to={Nav.makeTo({
                          root: makeLink(paths.pages.ControllerDevicesList, {
                            controllerName,
                          }),
                          drawer: makeLink(paths.drawers.DeviceDetail, {
                            controllerName,
                            id: ownAccessPoint.name,
                          }),
                        })}
                      >
                        <OnlineOfflineStatusBadge
                          value={ownAccessPoint.connected_status}
                          icon="access-point"
                          arrangement="leading-label"
                        >
                          {ownAccessPoint.location}
                        </OnlineOfflineStatusBadge>
                      </ReactRouterLink>
                    </SummaryListValue>
                  </SummaryListRow>
                )}
                <SummaryListRow>
                  <SummaryListKey>MAC</SummaryListKey>
                  <SummaryListValue>
                    {isDefined(firstEntry?.macAddress) ? (
                      <CopyBox
                        aria-label="Copy MAC address"
                        relation="stacked"
                        size="small"
                        value={firstEntry.macAddress}
                      >
                        <Body family="monospace">{firstEntry.macAddress}</Body>
                      </CopyBox>
                    ) : (
                      <UnknownBadge />
                    )}
                  </SummaryListValue>
                </SummaryListRow>
                <SummaryListRow>
                  <SummaryListKey>Last seen</SummaryListKey>
                  <SummaryListValue>
                    {isLastSeenKnown(firstEntry) ? (
                      <DistanceToNowTimestamp value={firstEntry.lastSeen} />
                    ) : (
                      <UnknownBadge />
                    )}
                  </SummaryListValue>
                </SummaryListRow>
              </SummaryList>
            </SectionContent>
          </Section>
        )}
        {isDefined(switchDevice) && (
          <Section relation="standalone">
            <SectionHeader heading="Switch inventory info" />
            <SectionContent>
              <SummaryList>
                <SummaryListRow>
                  <SummaryListKey>Location</SummaryListKey>
                  <SummaryListValue>
                    <CopyBox
                      aria-label="Copy location"
                      relation="stacked"
                      size="small"
                      value={switchDevice.location}
                    >
                      <Body family="monospace">{switchDevice.location}</Body>
                    </CopyBox>
                  </SummaryListValue>
                </SummaryListRow>
                <SummaryListRow>
                  <SummaryListKey>Serial number</SummaryListKey>
                  <SummaryListValue>
                    <CopyBox
                      aria-label="Copy serial number"
                      relation="stacked"
                      size="small"
                      value={switchDevice.serial_number}
                    >
                      <Body family="monospace">{switchDevice.serial_number}</Body>
                    </CopyBox>
                  </SummaryListValue>
                </SummaryListRow>
                <SummaryListRow>
                  <SummaryListKey>Model</SummaryListKey>
                  <SummaryListValue>
                    <CopyBox
                      aria-label="Copy model"
                      relation="stacked"
                      size="small"
                      value={switchDevice.model}
                    >
                      <Body family="monospace">{switchDevice.model}</Body>
                    </CopyBox>
                  </SummaryListValue>
                </SummaryListRow>
                <SummaryListRow>
                  <SummaryListKey>Inventory status</SummaryListKey>
                  <SummaryListValue>
                    <ValueOrUnknownBadge value={switchDevice.inventory_status} />
                  </SummaryListValue>
                </SummaryListRow>
              </SummaryList>
            </SectionContent>
          </Section>
        )}
        <Section relation="standalone">
          <SectionHeader heading="Network" />
          <SectionContent>
            <SummaryList>
              <SummaryListRow>
                <SummaryListKey>Hostname</SummaryListKey>
                <SummaryListValue>
                  {isDefined(firstEntry.clientName) ? (
                    <CopyBox
                      aria-label="Copy hostname"
                      relation="stacked"
                      size="small"
                      value={firstEntry.clientName}
                    >
                      <Body family="monospace">{firstEntry.clientName}</Body>
                    </CopyBox>
                  ) : (
                    <UnknownBadge />
                  )}
                </SummaryListValue>
              </SummaryListRow>
              {ssids.length > 0 && (
                <SummaryListRow>
                  <SummaryListKey>SSID</SummaryListKey>
                  <SummaryListValue>
                    <Body family="monospace">{firstEntry.ssid}</Body>
                  </SummaryListValue>
                </SummaryListRow>
              )}
              <SummaryListRow>
                <SummaryListKey>IP</SummaryListKey>
                <SummaryListValue>
                  {isDefined(firstEntry) ? (
                    <CopyBox
                      aria-label="Copy IP address"
                      relation="stacked"
                      size="small"
                      value={firstEntry.ip}
                    >
                      <Body family="monospace">{firstEntry.ip}</Body>
                    </CopyBox>
                  ) : (
                    <UnknownBadge />
                  )}
                </SummaryListValue>
              </SummaryListRow>
              <SummaryListRow>
                <SummaryListKey>VLAN</SummaryListKey>
                <SummaryListValue>
                  <Badge size="small">{firstEntry.vlan}</Badge>
                </SummaryListValue>
              </SummaryListRow>
            </SummaryList>
          </SectionContent>
        </Section>
        {isWireless(firstEntry) && (
          <>
            <Button
              variant="secondary"
              width="100%"
              size="large"
              as="a"
              href={getGrafanaURL(controllerName, '', firstEntry.clientName!)}
              target="_blank"
              icon="reporting"
              arrangement="leading-label"
            >
              Open Grafana
            </Button>
            <Section relation="standalone">
              <SectionHeader heading="Connection" />
              <SectionContent>
                <SummaryList>
                  {isDefined(accessPoint) ? (
                    <SummaryListRow>
                      <SummaryListKey>Access point</SummaryListKey>
                      <SummaryListValue>
                        <ReactRouterLink
                          to={Nav.makeTo({
                            root: makeLink(paths.pages.ControllerDevicesList, {
                              controllerName,
                            }),
                            drawer: makeLink(paths.drawers.DeviceDetail, {
                              controllerName,
                              id: accessPoint.name,
                            }),
                          })}
                        >
                          <OnlineOfflineStatusBadge
                            value={accessPoint.connected_status}
                            icon="access-point"
                            arrangement="leading-label"
                          >
                            {accessPoint.location}
                          </OnlineOfflineStatusBadge>
                        </ReactRouterLink>
                      </SummaryListValue>
                    </SummaryListRow>
                  ) : (
                    <SummaryListRow>
                      <SummaryListKey>Access point</SummaryListKey>
                      <SummaryListValue>
                        <UnknownBadge />
                      </SummaryListValue>
                    </SummaryListRow>
                  )}
                  <SummaryListRow>
                    <SummaryListKey>Channel</SummaryListKey>
                    <SummaryListValue>{firstEntry.channel}</SummaryListValue>
                  </SummaryListRow>
                  <SummaryListRow>
                    <SummaryListKey>Signal</SummaryListKey>
                    <SummaryListValue>
                      <SignalStrengthBadge value={firstEntry.signal} />
                    </SummaryListValue>
                  </SummaryListRow>
                  <SummaryListRow>
                    <SummaryListKey>Noise</SummaryListKey>
                    <SummaryListValue>{firstEntry.noise} dBm</SummaryListValue>
                  </SummaryListRow>
                  <SummaryListRow>
                    <SummaryListKey>Tx rate</SummaryListKey>
                    <SummaryListValue>
                      {formatDataRateBits(firstEntry.txRate!, kilobitsPerSecond)}
                    </SummaryListValue>
                  </SummaryListRow>
                  <SummaryListRow>
                    <SummaryListKey>Rx rate</SummaryListKey>
                    <SummaryListValue>
                      {formatDataRateBits(firstEntry.rxRate!, kilobitsPerSecond)}
                    </SummaryListValue>
                  </SummaryListRow>
                  {ssids.length > 0 && ssids[0] !== '' && (
                    <SummaryListRow>
                      <SummaryListKey>SSID{ssids.length > 1 ? 's' : ''}</SummaryListKey>
                      <SummaryListValue>{ssids.join(', ')}</SummaryListValue>
                    </SummaryListRow>
                  )}
                </SummaryList>
              </SectionContent>
            </Section>
          </>
        )}
        <Section relation="standalone">
          <SectionHeader heading="History" />
          <SectionContent>
            <SummaryList>
              {clientHistory.map((entry: NetworkClient) => (
                <SummaryListRow key={entry.macAddress}>
                  <SummaryListKey>
                    {isWireless(entry) ? entry?.apLocation ?? 'Wireless' : 'Wired'}
                  </SummaryListKey>
                  <SummaryListValue>
                    {isDefined(entry.lastSeen) && <DistanceToNowTimestamp value={entry.lastSeen} />}
                  </SummaryListValue>
                </SummaryListRow>
              ))}
            </SummaryList>
          </SectionContent>
        </Section>
      </DrawerContent>
    </Drawer>
  );
}
