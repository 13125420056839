import { SummaryListKey, SummaryListRow, SummaryListValue } from '@meterup/atto';
import {
  getFirstUsableAddress,
  getLastUsableAddress,
  isDefined,
  safeParseAddress4,
} from '@meterup/common';
import React from 'react';

export function FirstAndLastAddresses({ ipRange }: { ipRange?: string }) {
  const addr = isDefined(ipRange) ? safeParseAddress4(ipRange.trim()) : null;

  return (
    <>
      <SummaryListRow>
        <SummaryListKey>First usable address</SummaryListKey>
        <SummaryListValue>
          {isDefined(addr) ? getFirstUsableAddress(addr).correctForm() : <>...</>}
        </SummaryListValue>
      </SummaryListRow>
      <SummaryListRow>
        <SummaryListKey>Last usable address</SummaryListKey>
        <SummaryListValue>
          {isDefined(addr) ? getLastUsableAddress(addr).correctForm() : <>...</>}
        </SummaryListValue>
      </SummaryListRow>
    </>
  );
}
