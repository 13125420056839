import { Body, CopyBox, Heading } from '@meterup/atto';
import { isDefined } from '@meterup/common';
import React from 'react';

import type { HasName } from '../../../../../utils/users';
import { UserProfilePicture } from '../../../../../components/UserProfilePicture';
import { styled } from '../../../../../stitches';
import { formatFullName } from '../../../../../utils/users';

const UserHeading = styled('div', {
  vStack: '$8',
  textAlign: 'center',
});

const UserNameAndEmail = styled('div', {
  vStack: 0,
});

type HasUserFields = {
  email: string;
} & HasName;

export function UserNameAndEmailWidget({ user }: { user: HasUserFields }) {
  const formattedName = formatFullName(user);
  return (
    <UserHeading>
      <UserProfilePicture email={user.email} size="large" />
      {isDefined(formattedName) ? (
        <UserNameAndEmail>
          <CopyBox
            aria-label="Copy user's name"
            relation="stacked"
            size="small"
            value={formattedName}
          >
            <Heading>{formattedName}</Heading>
          </CopyBox>
          <CopyBox
            aria-label="Copy user's email"
            relation="stacked"
            size="small"
            value={user.email}
          >
            <Body>{user.email}</Body>
          </CopyBox>
        </UserNameAndEmail>
      ) : (
        <CopyBox aria-label="Copy user's email" relation="stacked" size="small" value={user.email}>
          <Heading>{user.email}</Heading>
        </CopyBox>
      )}
    </UserHeading>
  );
}
