import { DockTarget } from '@meterup/atto';
import { observer } from '@meterup/command';
import React from 'react';
import { Link } from 'react-router-dom';

import { paths } from '../../constants';
import { useIsPathActiveFn } from '../../hooks/useIsPathActiveFn';
import { makeLink } from '../../utils/makeLink';
import { Nav } from '../Nav';

const SidebarEntries = observer(({ onClickEntry }: { onClickEntry?: () => void }) => {
  const isPathActive = useIsPathActiveFn();
  const location = Nav.useRegionLocation('root');

  // const { state } = useCommand();
  // const navigate = useNavigate();

  const isControllersLinkActive =
    location.pathname === '/' || isPathActive(paths.pages.ControllerDetails);
  const isCompaniesLinkActive = isPathActive(paths.pages.CompaniesList);
  const isNosversionsLinkActive = isPathActive(paths.pages.NosVersionsList);

  // DASH-1523: Hotfix; commenting these out because the keystrokes were taking effect while on-focus in
  // the config editor, causing confusing and destructive behavior. Can be re-implemented if a fix is added to
  // ignore these keystrokes while in the config editor.
  // useRegisterCommands(
  // [
  //   state.nodeFactory.action({
  //     id: 'controllers',
  //     group: 'Navigation',
  //     label: 'Controllers',
  //     display: 'Controllers',
  //     synonyms: 'go to home',
  //     icon: 'controller',
  //     shortcut: 'g h',
  //     onSelect: () => navigate(makeLink(paths.pages.ControllersList, {})),
  //     priority: Priority.Low,
  //   }),
  //   state.nodeFactory.action({
  //     id: 'companies',
  //     group: 'Navigation',
  //     label: 'Companies',
  //     display: 'Companies',
  //     synonyms: 'go to',
  //     icon: 'company',
  //     shortcut: 'g c',
  //     onSelect: () => navigate(makeLink(paths.pages.CompaniesList, {})),
  //     priority: Priority.Low,
  //   }),
  // ],
  // [location.pathname],
  // );

  return (
    <>
      <DockTarget
        as={Link}
        to={makeLink(paths.pages.ControllersList, {})}
        icon="security-appliance"
        label="Controllers"
        selected={isControllersLinkActive}
        onClick={onClickEntry}
      />
      <DockTarget
        as={Link}
        to={makeLink(paths.pages.CompaniesList, {})}
        icon="company"
        label="Companies"
        selected={isCompaniesLinkActive}
        onClick={onClickEntry}
      />
      <DockTarget
        as={Link}
        to={makeLink(paths.pages.NosVersionsList, {})}
        icon="version"
        label="Firmware versions"
        selected={isNosversionsLinkActive}
        onClick={onClickEntry}
      />
    </>
  );
});

export function Sidebar() {
  return <SidebarEntries />;
}
