import type { MeterControllerConfig, MeterV2WirelessServiceSet } from '@meterup/config';
import type { CellProps, Column } from 'react-table';
import { Section, SectionContent, SectionHeader } from '@meterup/atto';
import { NeutralBadge, PositiveBadge } from '@meterup/common';
import React from 'react';

import { paths } from '../constants';
import { useConfigEditor } from '../context/ConfigEditorContext';
import { styled } from '../stitches';
import { makeDrawerLink } from '../utils/makeLink';
import { NosFeature, useNosFeatureEnabled } from '../utils/nosfeature';
import { AutoTable } from './AutoTable/AutoTable';
import { ListItemTableContainer } from './ListItemTableContainer';
import { Nav } from './Nav';

const BadgeList = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
  flexWrap: 'wrap',
});

function createColumns(config: MeterControllerConfig) {
  const columns: Column<MeterV2WirelessServiceSet>[] = [
    {
      Header: 'SSID',
      accessor: (d) => d.ssid,
    },
    {
      Header: 'Visibility',
      accessor: (d) => (d.hidden ? 'Hidden' : 'Visible'),
      Cell: (props: CellProps<MeterV2WirelessServiceSet>) =>
        props.row.original.hidden ? (
          <NeutralBadge icon="eye-closed" arrangement="hidden-label">
            Hidden
          </NeutralBadge>
        ) : (
          <PositiveBadge icon="eye-open" arrangement="hidden-label">
            Visible
          </PositiveBadge>
        ),
    },
    {
      Header: 'Access points',
      accessor: (d) =>
        `${config.getAPsBroadcastingServiceSet(d).length} / ${config.accessPoints.length}`,
      Cell: (props: CellProps<MeterV2WirelessServiceSet>) => (
        <NeutralBadge icon="access-point" arrangement="leading-icon">
          {props.value}
        </NeutralBadge>
      ),
    },
    {
      Header: 'Bands',
      accessor: (d) => d.getSortedBands().join(', '),
      Cell: (props: CellProps<MeterV2WirelessServiceSet>) => (
        <BadgeList>
          {props.row.original.getSortedBands().map((b) => (
            <NeutralBadge key={b} ends="card">
              {b}
            </NeutralBadge>
          ))}
        </BadgeList>
      ),
    },
  ];
  return columns;
}

export function ServiceSetsWidgetCOS({ controllerName }: { controllerName: string }) {
  const config = useConfigEditor().draftModel;

  const columns = createColumns(config);

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.SSIDEdit);

  const isWOS2Enabled = useNosFeatureEnabled(controllerName, NosFeature.WOS2);
  if (isWOS2Enabled) {
    return (
      <Section relation="standalone">
        <SectionHeader
          heading="Service sets (Use Dashboard)"
          actions={
            <NeutralBadge ends="card" variant="brand">
              WOS Config 2.0
            </NeutralBadge>
          }
        />
      </Section>
    );
  }

  return (
    <Section relation="standalone">
      <SectionContent>
        <ListItemTableContainer>
          <AutoTable
            columns={columns}
            data={config.serviceSets}
            shouldShowTopBar={false}
            isRowSelected={(row) => drawerParams?.eid === row.stableId}
            linkProps={(row) => ({
              to: makeDrawerLink(paths.drawers.SSIDEdit, {
                controllerName,
                eid: row.stableId,
              }),
            })}
          />
        </ListItemTableContainer>
      </SectionContent>
    </Section>
  );
}
