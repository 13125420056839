import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Drawer, DrawerContent, DrawerHeader } from '@meterup/atto';
import { checkDefinedOrThrow, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import React, { Suspense } from 'react';

import { LoadingDrawer } from '../../../../components/Drawers/LoadingDrawer';
import IncidentDetailContent from '../../../../components/Incidents/IncidentDetailContent';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { useControllerIncident } from './common/useControllerIncident';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/incidents/:id',
});

function IncidentDetailDrawerContent() {
  const { controllerName, id } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.IncidentDetail),
  );

  const incident = useControllerIncident(controllerName, id);

  expectDefinedOrThrow(incident, new ResourceNotFoundError('Incident not found'));

  return (
    <Drawer>
      <DrawerHeader heading="Incident details" onClose={useCloseDrawerCallback()} />
      <DrawerContent>
        <IncidentDetailContent controllerName={controllerName} incident={incident} />
      </DrawerContent>
    </Drawer>
  );
}

export default function IncidentDetail() {
  return (
    <Suspense fallback={<LoadingDrawer title="Incident details" />}>
      <IncidentDetailDrawerContent />
    </Suspense>
  );
}
