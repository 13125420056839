import type { api } from '@meterup/proto';
import type { Column } from 'react-table';
import { Section, SectionContent, SectionHeader } from '@meterup/atto';
import { NeutralBadge, PositiveBadge } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import { orderBy } from 'lodash-es';

import { fetchAccessPointsJSON } from '../api/devices_api';
import { paths } from '../constants';
import { makeLink } from '../utils/makeLink';
import { NosFeature, useNosFeatureEnabled } from '../utils/nosfeature';
import { AutoTable } from './AutoTable/AutoTable';
import { ListItemTableContainer } from './ListItemTableContainer';
import { ListItemViewAllLink } from './ListItemViewAllLink';
import { ListItemStatList, StatItem, StatItemLabel } from './StatItemLabel';

const columns: Column<api.AccessPointDeviceResponse>[] = [
  {
    Header: 'Name',
    accessor: (d) => d.name,
  },
  {
    Header: 'Clients',
    accessor: (d) => d.connected_clients,
  },
];

export function AccessPointsWidget({ controllerName }: { controllerName: string }) {
  const devices =
    useQuery(
      ['controller', controllerName, 'access_points'],
      async () => fetchAccessPointsJSON(controllerName),
      { suspense: true },
    ).data ?? [];

  const orderedAndLimitedDevices = orderBy(devices, (d) => d.connected_clients).slice(0, 5);

  const onlineCount = devices.filter((d) => d.connected_status === 'online').length;
  const offlineCount = devices.filter((d) => d.connected_status !== 'online').length;

  const isWOS2Enabled = useNosFeatureEnabled(controllerName, NosFeature.WOS2);
  if (isWOS2Enabled) {
    return (
      <Section relation="standalone">
        <SectionHeader
          icon="access-point"
          heading="Access points (Use Dashboard)"
          actions={
            <NeutralBadge ends="card" variant="brand">
              WOS Config 2.0
            </NeutralBadge>
          }
        />
      </Section>
    );
  }

  return (
    <Section relation="standalone">
      <SectionHeader icon="access-point" heading="Access points" />
      <SectionContent>
        <ListItemStatList>
          <StatItem>
            <PositiveBadge size="large" icon="access-point" arrangement="leading-icon">
              {onlineCount}
            </PositiveBadge>
            <StatItemLabel>Online</StatItemLabel>
          </StatItem>
          <StatItem>
            <NeutralBadge size="large" icon="access-point" arrangement="leading-icon">
              {offlineCount}
            </NeutralBadge>
            <StatItemLabel>Offline</StatItemLabel>
          </StatItem>
        </ListItemStatList>
        <ListItemTableContainer>
          <AutoTable columns={columns} data={orderedAndLimitedDevices} shouldShowTopBar={false} />
        </ListItemTableContainer>
      </SectionContent>
      <ListItemViewAllLink to={makeLink(paths.pages.ControllerDevicesList, { controllerName })}>
        View all{' '}
        {orderedAndLimitedDevices.length < devices.length
          ? `(Showing ${orderedAndLimitedDevices.length} out of ${devices.length})`
          : ''}
      </ListItemViewAllLink>
    </Section>
  );
}
