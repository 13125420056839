import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Alert,
  Button,
  ComboBox,
  ComboBoxItem,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  PrimaryField,
  SecondaryField,
  Select,
  SelectItem,
  Textarea,
  TextInput,
} from '@meterup/atto';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  getErrorMessage,
  isDefined,
  isDefinedAndNotEmpty,
  ResourceNotFoundError,
} from '@meterup/common';
import {
  formatLifecycleStatus,
  validLifecycleStatus,
} from '@meterup/common/src/helpers/lifecycleStatus';
import { useGraphQL } from '@meterup/graphql';
import { api } from '@meterup/proto';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import React from 'react';
import * as z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import {
  fetchCompanies,
  fetchControllerJSON,
  updateControllerMetadataJSON,
} from '../../../../api/controllers_api';
import { FieldProvider } from '../../../../components/FieldProvider';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { graphql } from '../../../../gql';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { styled } from '../../../../stitches';

const StyledForm = styled(Form, {
  display: 'contents',
});

const validNetworkMetadata = z.object({
  companySlug: z.string().optional().nullable(),
  // `.uuid()` validation doesn't work: https://github.com/colinhacks/zod/issues/2122
  networkUUID: z.string().optional().nullable(),
  label: z.string().max(25).optional().nullable(),
  lifecycleStatus: validLifecycleStatus.optional(),
  notes: z.string().optional(),
  patchPanelDiagramUrl: z.string().url().optional(),
});

interface NetworkMetadata extends z.TypeOf<typeof validNetworkMetadata> {}

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/metadata/edit',
});

const networksAndControllersForCompany = graphql(`
  query NetworksAndControllersForCompany($companySlug: String!) {
    networksForCompany(companySlug: $companySlug) {
      label
      UUID
      slug

      virtualDevices(filter: { deviceType: CONTROLLER }) {
        UUID
        hardwareDevice {
          serialNumber
        }
      }
    }
  }
`);

function NetworkField({ isActiveAndHasCompany }: { isActiveAndHasCompany: boolean }) {
  const { values, setFieldValue } = useFormikContext<NetworkMetadata>();
  const networks = useGraphQL(
    networksAndControllersForCompany,
    { companySlug: values.companySlug as string },
    { enabled: !!values.companySlug, suspense: true },
  ).data?.networksForCompany;

  return (
    <PrimaryField
      label="Network"
      element={
        <ComboBox
          placeholder="Select a network"
          disabled={!values.companySlug || isActiveAndHasCompany}
          value={values.networkUUID}
          onValueChange={(value) => {
            setFieldValue('networkUUID', value);
          }}
          defaultItems={networks ?? []}
          icon="vlan"
          maxWidth="100%"
          canClearValue
        >
          {(network) => (
            <ComboBoxItem key={network.UUID} textValue={`${network.label} (${network.slug})`}>
              {network.label} ({network.slug})
            </ComboBoxItem>
          )}
        </ComboBox>
      }
    />
  );
}

export default function EditControllerMetadata() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.EditControllerMetadata),
  );

  const { data: networkInfo } = useQuery(
    ['controller', controllerName, 'network-info'],
    () => fetchControllerJSON(controllerName),
    { suspense: true },
  );

  expectDefinedOrThrow(
    networkInfo,
    new ResourceNotFoundError(`Controller ${controllerName} not found`),
  );

  const result = useQuery(['companies'], fetchCompanies, { suspense: true });
  const companies = (result.data?.filter((c) => isDefinedAndNotEmpty(c.slug)) ??
    []) as api.CompanyResponse[];

  const closeDrawer = useCloseDrawerCallback();

  const queryClient = useQueryClient();

  const updateNetworkMetadataMutation = useMutation(
    ['update_network_metadata', networkInfo.name],
    async (values: NetworkMetadata) => {
      // TRICKY: The form deals with a company slug because that's what's
      // returned from the controller info endpoint, but the API call expects a
      // company sid.
      const companySid = companies.find((c) => c.slug === values.companySlug)?.sid;
      await updateControllerMetadataJSON(networkInfo.name, {
        company_sid: companySid ?? '',
        network_uuid: values.networkUUID ?? '',
        label: values.label?.trim() ?? '',
        lifecycle_status: isDefined(values.lifecycleStatus) ? values.lifecycleStatus : undefined,
        noc_metadata: {
          patch_panel_diagram_url: values.patchPanelDiagramUrl?.trim(),
          notes: values.notes?.trim(),
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controller', controllerName]);
        closeDrawer();
      },
    },
  );

  const hasCompany = isDefinedAndNotEmpty(networkInfo.company_slug);

  const isActiveAndHasCompany =
    hasCompany &&
    (networkInfo.lifecycle_status === api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_PRIMARY ||
      networkInfo.lifecycle_status === api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_BACKUP);

  return (
    <Formik<NetworkMetadata>
      initialValues={{
        companySlug: networkInfo.company_slug,
        networkUUID: networkInfo.network_uuid,
        label: networkInfo.label,
        lifecycleStatus: networkInfo.lifecycle_status,
        notes: networkInfo.noc_metadata?.notes ?? '',
        patchPanelDiagramUrl: networkInfo.noc_metadata?.patch_panel_diagram_url ?? '',
      }}
      validationSchema={toFormikValidationSchema(validNetworkMetadata)}
      onSubmit={(values) => updateNetworkMetadataMutation.mutate(values)}
    >
      <StyledForm>
        <Drawer>
          <DrawerHeader heading="Edit controller metadata" onClose={useCloseDrawerCallback()} />
          <DrawerContent>
            {isDefined(updateNetworkMetadataMutation.error) && (
              <Alert
                heading="Error while submitting"
                copy={getErrorMessage(updateNetworkMetadataMutation.error)}
              />
            )}
            <FieldProvider name="companySlug">
              <PrimaryField
                label="Company"
                description={(() => {
                  if (isActiveAndHasCompany) {
                    return 'Reassigning primary/backup controllers is not supported.';
                  }
                  if (hasCompany) {
                    return 'Reassigning a controller can have widespread effects! Please be careful.';
                  }
                  return null;
                })()}
                element={
                  <ComboBox
                    placeholder="Select a company"
                    disabled={isActiveAndHasCompany}
                    defaultItems={companies}
                    icon="company"
                    maxWidth="100%"
                  >
                    {(company) => (
                      <ComboBoxItem
                        key={company.slug}
                        textValue={`${company.name} (${company.slug})`}
                      >
                        {company.name} ({company.slug})
                      </ComboBoxItem>
                    )}
                  </ComboBox>
                }
              />
            </FieldProvider>
            <NetworkField isActiveAndHasCompany={isActiveAndHasCompany} />
            <FieldProvider name="lifecycleStatus">
              <SecondaryField
                label="Lifecycle status"
                element={
                  <Select>
                    {Object.values(api.LifecycleStatus).map((lifecycleStatus) => (
                      <SelectItem key={lifecycleStatus}>
                        {formatLifecycleStatus(lifecycleStatus)}
                      </SelectItem>
                    ))}
                  </Select>
                }
              />
            </FieldProvider>
            <FieldProvider name="label">
              <PrimaryField
                label="Label"
                description="An optional short code to identify this network."
                element={<TextInput />}
              />
            </FieldProvider>
            <FieldProvider name="patchPanelDiagramUrl">
              <PrimaryField label="Link to patch panel diagram" element={<TextInput />} />
            </FieldProvider>
            <FieldProvider name="notes">
              <PrimaryField label="Notes" element={<Textarea />} />
            </FieldProvider>
          </DrawerContent>
          <DrawerFooter actions={<Button type="submit">Save</Button>} />
        </Drawer>
      </StyledForm>
    </Formik>
  );
}
