// eslint-disable-next-line no-restricted-imports
import type { ZodError } from 'zod';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';

import { KNOWN_FEATURE_FLAGS } from '../feature_flags';
import { logError } from '../utils/logError';

function getTabularErrorData(error: ZodError) {
  return Object.fromEntries(error.issues.map((issue) => [issue.path, { message: issue.message }]));
}

/**
 * Validates feature flags from LaunchDarkly against the expected schema and
 * logs any errors to console or to Sentry depending on the realm.
 */
export const useValidateFeatureFlags = () => {
  const flags = useFlags();

  useEffect(() => {
    // NOTE: Skip check when isEmpty because the object will be empty when
    // loading. This could backfire in the case that we delete all flags from
    // LaunchDarkly.
    if (!isEmpty(flags)) {
      const result = KNOWN_FEATURE_FLAGS.safeParse(flags);

      if (!result.success) {
        if (import.meta.env.REALM === 'local') {
          /* eslint-disable no-console */
          console.warn('Feature flags from LaunchDarkly do not match the expected schema. ');
          console.table(getTabularErrorData(result.error));
          /* eslint-enable no-console */
        } else {
          logError(result.error);
        }
      }
    }
  }, [flags]);
};
