import type { api } from '@meterup/proto';
import type { CellProps, Column } from 'react-table';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Pane, Small, VStack } from '@meterup/atto';
import { useQuery } from '@tanstack/react-query';

import { fetchNosVersions } from '../../../api/nosversions_api';
import { AutoTable } from '../../../components/AutoTable/AutoTable';
import { Nav } from '../../../components/Nav';
import { paths } from '../../../constants';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { makeDrawerLink } from '../../../utils/makeLink';

const columns: Column<api.NosVersionResp>[] = [
  {
    Header: 'Version',
    accessor: (row) => row.nos_version?.version,
  },
  {
    Header: 'GA',
    accessor: (row) => (row.nos_version?.is_default ? 'GA' : ''),
  },
  {
    Header: 'COS',
    accessor: (row) => row.nos_version?.mc01_build_name,
  },
  {
    Header: 'WOS',
    accessor: (row) => row.nos_version,
    Cell: (props: CellProps<api.NosVersionResp>) => (
      <VStack>
        <Small>mw07: {props.value?.mw07_build_name} </Small>
        <Small>mw08: {props.value?.mw08_build_name} </Small>
        <Small>mw09: {props.value?.mw09_build_name} </Small>
      </VStack>
    ),
  },
  {
    Header: 'SOS',
    accessor: (row) => row.nos_version?.ms10_build_name,
  },
];

export const Meta: PagefileMetaFn = () => ({
  path: '/nos-versions',
});

export default function NosVersionsList() {
  const closeDrawer = useCloseDrawerCallback();

  const nosversions = useQuery(['nos-versions-list'], () => fetchNosVersions()).data ?? [];

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.NosVersionSummary);

  return (
    <Pane>
      <AutoTable
        icon="version"
        heading="NOS versions"
        columns={columns}
        data={nosversions}
        onRowDeselect={closeDrawer}
        isRowSelected={(row) => row.id.toString() === drawerParams?.nosId}
        linkProps={(row) => ({
          to: makeDrawerLink(paths.drawers.NosVersionSummary, { nosId: row.id.toString() }),
        })}
      />
    </Pane>
  );
}
