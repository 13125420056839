import { graphqlClient } from '@meterup/graphql';
import { orderBy } from 'lodash-es';

import { graphql } from '../gql';

const getNetworkClients = graphql(`
  query networkClients($networkUUID: UUID!, $filter: NetworkClientsFilter) {
    networkClients(networkUUID: $networkUUID, filter: $filter) {
      alias
      apLocation
      apSerialNumber
      channel
      clientName
      ip
      lastSeen
      macAddress
      noise
      rxRate
      signal
      ssid
      txRate
      vlan
    }
  }
`);

export const fetchClientsGql = async (
  networkUUID: string,
  macAddress?: string,
  apSerialNumber?: string,
) => {
  try {
    const res = await graphqlClient.request(getNetworkClients, {
      networkUUID,
      filter: {
        macAddress,
        apSerialNumber,
      },
    });
    return orderBy(res.networkClients, (d) => d.lastSeen, 'desc');
  } catch (err) {
    window.console.error(err);
    return [];
  }
};
