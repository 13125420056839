import type { ReactNode } from 'react';
import { Alert, Body, Button } from '@meterup/atto';
import { getErrorMessage, isDefined } from '@meterup/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';

import { uploadAccessPointsCSV } from '../../api/devices_api';
import { styled } from '../../stitches';
import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogCloseButton,
  DialogContent,
  DialogControls,
  DialogFooter,
  DialogHeader,
  DialogSection,
  DialogTitle,
  DialogTrigger,
} from '../Dialog/Dialog';
import { FilePickerInput } from './FilePickerInput';

const CSVPreviewText = styled('pre', Body, {
  maxHeight: 200,
  overflow: 'auto',
});

export function APCSVUploadDialog({
  trigger,
  controllerName,
}: {
  controllerName: string;
  trigger: ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [fileForUpload, setFileForUpload] = useState<File | null>(null);

  const queryClient = useQueryClient();

  const uploadFileMutation = useMutation(
    async (file: File) => {
      await uploadAccessPointsCSV(controllerName, file);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controller', controllerName, 'accessPoints']);
        setIsOpen(false);
        setFileForUpload(null);
      },
    },
  );

  const fileText = useQuery(
    ['apcsv', fileForUpload?.name, fileForUpload?.lastModified, fileForUpload?.size],
    () => fileForUpload?.text() ?? null,
  );

  const uploadFile = async (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    if (isDefined(fileForUpload)) {
      uploadFileMutation.mutate(fileForUpload);
    }
  };

  const errorMessage = isDefined(uploadFileMutation.error)
    ? getErrorMessage(uploadFileMutation.error)
    : null;

  const handleOpenChange = (value: boolean) => {
    if (!value) {
      setFileForUpload(null);
      uploadFileMutation.reset();
    }
    setIsOpen(value);
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent>
        <form onSubmit={uploadFile} style={{ display: 'contents' }}>
          <DialogHeader>
            <DialogTitle>Import access points</DialogTitle>
            <DialogControls>
              <DialogCloseButton />
            </DialogControls>
          </DialogHeader>
          <DialogBody>
            {isDefined(errorMessage) && (
              <Alert
                icon="warning"
                variant="negative"
                heading="Upload failed"
                copy={errorMessage}
                relation="stacked"
              />
            )}
            <DialogSection>
              <FilePickerInput accept=".csv" value={fileForUpload} onChange={setFileForUpload} />
            </DialogSection>
            {isDefined(fileText.data) && (
              <CSVPreviewText family="monospace">{fileText.data}</CSVPreviewText>
            )}
          </DialogBody>
          <DialogFooter>
            <DialogControls>
              <DialogClose asChild>
                <Button variant="secondary">Cancel</Button>
              </DialogClose>
              <Button
                type="submit"
                loading={uploadFileMutation.isLoading}
                disabled={!isDefined(fileForUpload)}
              >
                Import
              </Button>
            </DialogControls>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
