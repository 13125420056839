import type { api } from '@meterup/proto';
import { getMany, getOne, mutateVoid } from '@meterup/common';
import { orderBy } from 'lodash-es';

import { axiosInstanceJSON } from './api_clients';

export const fetchNosVersions = async () =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<api.NosVersionList>(`/v1/firmware/nos-versions`);
    return orderBy(result.data.nos_versions, (e) => e.updated_at, 'desc');
  });

export const fetchNosVersion = async (id: string) =>
  getOne(async () => {
    if (id === 'create') {
      return <api.NosVersionResp>{};
    }
    const result = await axiosInstanceJSON.get<api.NosVersionResp>(
      `/v1/firmware/nos-versions/${id}`,
    );
    return result.data;
  });

export const updateNosVersion = async (id: string, data: Partial<api.NosVersion>) =>
  mutateVoid(async () => {
    if (id === 'create') {
      await axiosInstanceJSON.post(`/v1/firmware/nos-versions`, data);
    } else {
      await axiosInstanceJSON.put(`/v1/firmware/nos-versions/${id}`, data);
    }
  });

export const pinNosVersionNetwork = async (
  serial: string,
  id: string,
  data: Partial<api.NosVersionReq>,
) =>
  mutateVoid(async () => {
    await axiosInstanceJSON.post(`/v1/controllers/${serial}/nos-versions/${id}`, data);
  });
