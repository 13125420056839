import type { DisplayableError } from '@meterup/common';
import type { ReactNode } from 'react';
import {
  Alert,
  Badge,
  Body,
  Button,
  Drawer,
  DrawerContent,
  DrawerHeader,
  Heading,
  Link,
  Section,
  SectionContent,
  SectionHeader,
  Small,
  Subheading,
  Tab,
  Tabs,
} from '@meterup/atto';
import { getErrorMessage, getErrorTitle, isDefined, isDisplayableError } from '@meterup/common';
import React, { useState } from 'react';

import { useCloseDrawerCallback } from '../hooks/useCloseDrawerCallback';
import { colors, styled } from '../stitches';
import { ListItemTableContainer } from './ListItemTableContainer';

const ErrorFallbackContainer = styled('div', {
  vStack: '$16',
  alignItems: 'stretch',
  width: '100%',
  height: '100%',
  padding: '$20',
});

const DevelopmentErrorFallbackHeading = styled(Heading, {
  color: colors.red800,
});

const CodeBlock = styled('pre', Body, {
  padding: '$8',
  backgroundColor: colors.gray50,
  overflow: 'auto',
  borderRadius: '$4',
});

/* eslint-disable react/no-unused-prop-types */
export interface ErrorFallbackProps {
  errorId?: string;
  error: Error;
  componentStack?: string | null;
  resetError?: () => void;
}
/* eslint-enable react/no-unused-prop-types */

function DevelopmentErrorFallback({
  error,
  componentStack,
}: {
  error: Error;
  componentStack?: string | null;
}) {
  const [currentStack, setCurrentStack] = useState<'error' | 'component'>('error');
  return (
    <>
      <div>
        <DevelopmentErrorFallbackHeading>
          Uncaught error: {error.name}
        </DevelopmentErrorFallbackHeading>

        <Subheading>{getErrorTitle(error)}</Subheading>
        <Body>{getErrorMessage(error)}</Body>
      </div>
      <Tabs>
        <Tab selected={currentStack === 'error'} onClick={() => setCurrentStack('error')}>
          JS Stack
        </Tab>
        <Tab selected={currentStack === 'component'} onClick={() => setCurrentStack('component')}>
          Component Stack
        </Tab>
      </Tabs>
      {currentStack === 'error' ? (
        <CodeBlock family="monospace">{error.stack}</CodeBlock>
      ) : (
        <CodeBlock family="monospace">{componentStack}</CodeBlock>
      )}
      <Small>This error would be logged to Sentry in staging or production.</Small>
    </>
  );
}

export function UnexpectedErrorBoilerplate({ errorId }: { errorId?: string }) {
  return (
    <>
      <p>
        An unexpected error has occurred. Please try refreshing the page. If the issue persists,
        please ping the <Link href="https://meterup.slack.com/archives/C01EW6GLQ21">#noc</Link>{' '}
        channel in Slack.
      </p>
      {isDefined(errorId) ? (
        <>
          <br />
          <p>
            Reference{' '}
            <Badge size="small" variant="brand">
              {errorId}
            </Badge>
          </p>
        </>
      ) : null}
    </>
  );
}

export function GenericErrorFallback({
  error,
  errorId,
  componentStack,
  resetError,
}: ErrorFallbackProps) {
  return import.meta.env.NODE_ENV === 'pizza' ? (
    <ErrorFallbackContainer>
      <DevelopmentErrorFallback error={error} componentStack={componentStack} />
    </ErrorFallbackContainer>
  ) : (
    <ErrorFallbackContainer>
      {isDisplayableError(error) ? (
        <Alert
          icon="warning"
          variant="negative"
          heading={getErrorTitle(error)}
          copy={getErrorMessage(error)}
          trailingButtons={
            <Button variant="secondary" icon="arrows-rotate" onClick={resetError}>
              Try again
            </Button>
          }
        />
      ) : (
        <Alert
          icon="warning"
          variant="negative"
          heading="Unexpected error"
          copy={<UnexpectedErrorBoilerplate errorId={errorId} />}
          trailingButtons={
            <Button variant="secondary" icon="arrows-rotate" onClick={resetError}>
              Try again
            </Button>
          }
        />
      )}
    </ErrorFallbackContainer>
  );
}

function UnexpectedErrorFallbackDrawer({ componentStack, errorId, error }: ErrorFallbackProps) {
  return (
    <Drawer>
      <DrawerHeader heading="Unexpected error" onClose={useCloseDrawerCallback()} />
      <DrawerContent>
        {import.meta.env.NODE_ENV === 'development' ? (
          <DevelopmentErrorFallback error={error} componentStack={componentStack} />
        ) : (
          <Alert
            variant="negative"
            heading="Unexpected error"
            copy={<UnexpectedErrorBoilerplate errorId={errorId} />}
          />
        )}
      </DrawerContent>
    </Drawer>
  );
}

const getErrorDisplayInfo = (
  error: Error | (Error & DisplayableError),
  errorId: string | undefined,
) => {
  const isExpected = isDisplayableError(error);
  const title = isDisplayableError(error) ? error.displayTitle : 'Something went wrong';
  const message = isDisplayableError(error) ? (
    error.displayMessage
  ) : (
    <UnexpectedErrorBoilerplate errorId={errorId} />
  );

  return { title, message, isExpected };
};

function DisplayableErrorFallbackDrawer({
  error,
}: ErrorFallbackProps & { error: DisplayableError }) {
  return (
    <Drawer>
      <DrawerHeader heading={error.displayTitle} onClose={useCloseDrawerCallback()} />
      <DrawerContent>
        <Alert copy={error.displayMessage} />
      </DrawerContent>
    </Drawer>
  );
}
export function ErrorFallbackDrawer({ error, ...rest }: ErrorFallbackProps) {
  return isDisplayableError(error) ? (
    <DisplayableErrorFallbackDrawer error={error} {...rest} />
  ) : (
    <UnexpectedErrorFallbackDrawer error={error} {...rest} />
  );
}

const Box = styled('div');

export function FatalErrorFallback({ error, errorId, componentStack }: ErrorFallbackProps) {
  const display = getErrorDisplayInfo(error, errorId);

  return import.meta.env.NODE_ENV === 'development' ? (
    <ErrorFallbackContainer>
      <DevelopmentErrorFallback error={error} componentStack={componentStack} />
    </ErrorFallbackContainer>
  ) : (
    <Box
      css={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box css={{ vStack: '$32', maxWidth: 400, alignItems: 'stretch' }}>
        <Box css={{ vStack: '$20', alignItems: 'flex-start' }}>
          <Box css={{ vStack: '$4', alignItems: 'flex-start' }}>
            <Heading>{display.title}</Heading>
          </Box>
          <Body>{display.message}</Body>
        </Box>
      </Box>
    </Box>
  );
}

export function ErrorFallbackWidget({
  title,
  errorId,
  error,
  resetError,
}: ErrorFallbackProps & { title: ReactNode }) {
  const display = getErrorDisplayInfo(error, errorId);

  return (
    <Section relation="standalone">
      <SectionHeader heading={title} />
      <SectionContent>
        <ListItemTableContainer>
          <Alert
            icon="warning"
            variant="negative"
            relation="stacked"
            heading={display.title}
            copy={display.message}
            trailingButtons={
              <Button variant="secondary" icon="arrows-rotate" onClick={resetError}>
                Try again
              </Button>
            }
          />
        </ListItemTableContainer>
      </SectionContent>
    </Section>
  );
}
