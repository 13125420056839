import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Body, space, VStack } from '@meterup/atto';
import React, { useEffect, useState } from 'react';

import { createCLISession } from '../../api/api';
import { Page, PageHeader, PageTitle } from '../../components/Page';
import { styled } from '../../stitches';

export const Meta: PagefileMetaFn = () => ({
  path: '/cli',
});

const Container = styled('div', {
  alignItems: 'center',
  height: 'calc(100vh - 200px)',
  display: 'flex',
  justifyContent: 'center',
});

export default function CLI() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading) {
      return;
    }
    const cliSession = async () => {
      await createCLISession();
    };
    cliSession();
    setLoading(false);
  }, [loading]);
  return (
    <Page>
      <PageHeader>
        <PageTitle>CLI Login</PageTitle>
      </PageHeader>
      <Container>
        <VStack align="center" justify="center" spacing={space(16)}>
          {loading ? (
            <Body>Loading...</Body>
          ) : (
            <Body>CLI session created, you can now close this tab.</Body>
          )}
        </VStack>
      </Container>
    </Page>
  );
}
