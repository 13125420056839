import { useEffect, useState } from 'react';

// single global promise that resolves when the global Google Maps object is ready
let googleMapsPromise: Promise<typeof google.maps> | null = null;

function setupGoogleMaps() {
  googleMapsPromise = new Promise<typeof google.maps>((resolve) => {
    // @ts-expect-error we are adding this fn to window, so we don't expect it to exist
    window.loadMapsAPICallback = () => {
      resolve(window.google.maps);
    };

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDQ34LlABV3gXpbIwa78ael-vnfaGZd3s8&region=US&libraries=places&callback=loadMapsAPICallback`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  });
  return googleMapsPromise;
}

export async function getGoogleMapsClient() {
  if (googleMapsPromise) {
    return googleMapsPromise;
  }
  return setupGoogleMaps();
}

export type AddressComponents = google.maps.GeocoderAddressComponent[];

export type MeterMailingAddress = {
  line1: string;
  line2: string;
  city: string;
  subdivision_code: string | null;
  postal_code: string | null;
  country_iso2: string;
  // timezone: string;
};

function toMeterDatabaseAddress(components: AddressComponents): MeterMailingAddress {
  const streetNumber = components.find((c) => c.types.includes('street_number'))?.long_name;
  const street = components.find((c) => c.types.includes('route'))?.short_name;
  if (!streetNumber || !street) throw new Error('No street/number found for address');

  const line2 = components.find((c) => c.types.includes('subpremise'))?.long_name || '';

  let city = components.find((c) => c.types.includes('locality'))?.long_name;

  if (!city) {
    // sublocality_level_1 is a neighborhood or district within a city that takes the place of the "real" city in the address. For example, "Brooklyn" is technically in New York City, but "Brooklyn" is the city slot of the address address. We added this specifically for this address in NYC: 63 Flushing Ave Building 303 Suite 702 Brooklyn, NY
    city = components.find((c) => c.types.includes('sublocality_level_1'))?.long_name;
  }
  if (!city) throw new Error('No city found for address');

  const state = components.find((c) => c.types.includes('administrative_area_level_1'))?.short_name;
  if (!state) throw new Error('No state found for address');

  const postalCode = components.find((c) => c.types.includes('postal_code'))?.long_name || null;

  const countryIso2 = components.find((c) => c.types.includes('country'))?.short_name;
  if (!countryIso2) throw new Error('No country found for address');

  return {
    line1: `${streetNumber} ${street}`,
    line2,
    city,
    subdivision_code: state,
    postal_code: postalCode,
    country_iso2: countryIso2,
  };
}

export async function getStructuredAddress(address: string) {
  const mapsClient = await getGoogleMapsClient();

  return new Promise<MeterMailingAddress>((resolve, reject) => {
    new mapsClient.Geocoder().geocode({ address }, (results, status) => {
      if (!results || results.length === 0) {
        reject(new Error('No results found'));
        return;
      }
      if (status !== 'OK') {
        reject(new Error('Google getDetails failed'));
        return;
      }
      try {
        resolve(toMeterDatabaseAddress(results[0].address_components));
      } catch (err) {
        reject(err);
      }
    });
  });
}

export function useGooglePlacesAPI() {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    getGoogleMapsClient().then(() => {
      setIsReady(true);
    });
  });

  return isReady ? window.google.maps : null;
}
