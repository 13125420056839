import { graphql } from '../gql';

export const jobTrackers = graphql(`
  query JobTrackers {
    jobTrackers {
      id
      jobID
      totalSqFt
      fullAddress
      locationCode
    }
  }
`);

export const createNetworkOnboarding = graphql(`
  mutation CreateNetworkOnboarding($networkUUID: UUID!, $jobTrackerID: String!) {
    createNetworkOnboarding(networkUUID: $networkUUID, jobTrackerID: $jobTrackerID) {
      UUID
    }
  }
`);
