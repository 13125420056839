import type { HasChildren } from '@meterup/atto';
import { isDefined, useLocalStorage } from '@meterup/common';
import React, { createContext, useContext, useMemo } from 'react';

import { PACIFIC_TIMEZONE } from '../components/timestamps';
import { logError } from '../utils/logError';

interface CurrentTimezoneData {
  currentTimezone: string;
  changeCurrentTimezone: (zone: string) => void;
}

export const CurrentTimezoneContext = createContext<CurrentTimezoneData>({} as any);

export function CurrentTimezoneProvider({ children }: HasChildren) {
  const [currentTimezone, setCurrentTimezone] = useLocalStorage<string>(
    'currentTimezone',
    PACIFIC_TIMEZONE,
    {
      logError,
    },
  );

  const value = useMemo(
    () =>
      isDefined(currentTimezone)
        ? { currentTimezone, changeCurrentTimezone: setCurrentTimezone }
        : null,
    [currentTimezone, setCurrentTimezone],
  );

  return value ? (
    <CurrentTimezoneContext.Provider value={value}>{children}</CurrentTimezoneContext.Provider>
  ) : null;
}

export function useCurrentTimezone() {
  return useContext(CurrentTimezoneContext).currentTimezone;
}

export function useChangeCurrentTimezoneCallback() {
  return useContext(CurrentTimezoneContext).changeCurrentTimezone;
}
