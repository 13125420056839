import {
  Button,
  Link,
  Section,
  SectionContent,
  SectionHeader,
  Small,
  SummaryList,
} from '@meterup/atto';
import { api } from '@meterup/proto';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { fetchPendingFirmwareUpdate } from '../api/devices_api';
import { fetchNosVersion } from '../api/nosversions_api';
import { paths } from '../constants';
import { useCurrentTimezone } from '../providers/CurrentTimezoneProvider';
import { makeDrawerLink } from '../utils/makeLink';

export function NosUpgradeWidget({ serial, editable }: { serial: string; editable: boolean }) {
  const editNosUpgradeLink = makeDrawerLink(paths.drawers.EditNosUpgrade, {
    controllerName: serial,
  });

  const fwList =
    useQuery(['serial', serial, 'pending-fw-upgrades'], () => fetchPendingFirmwareUpdate(serial), {
      suspense: true,
    }).data ?? ({} as api.FirmwareUpdates);

  const pendingFw =
    fwList.firmware_updates.length > 0 &&
    fwList.firmware_updates[0].status === api.FirmwareUpdateStatus.FIRMWARE_UPDATE_STATUS_PENDING
      ? fwList.firmware_updates[0]
      : ({} as api.FirmwareUpdate);

  const pendingNosVersion = useQuery(
    ['nosversion', pendingFw.nos_version_id, 'pending-nos-version'],
    () => fetchNosVersion(pendingFw.nos_version_id?.toString()),
    {
      suspense: true,
      enabled: !!pendingFw.nos_version_id,
    },
  ).data;

  const timezone = useCurrentTimezone();

  const nosVersionViewLink = makeDrawerLink(paths.drawers.NosVersionQuickView, {
    nosId: pendingNosVersion?.id.toString() || '0',
  });

  const buildStringForDevice = () => {
    if (pendingNosVersion?.nos_version === undefined) {
      return '';
    }
    const nos = pendingNosVersion?.nos_version;
    if (serial.substring(0, 2).toLowerCase() === 'mc') {
      return nos.mc01_build_name;
    }
    switch (serial.substring(0, 4).toLowerCase()) {
      case 'mw03':
        return nos.mw03_build_name;
      case 'mw04':
        return nos.mw04_build_name;
      case 'mw06':
        return nos.mw06_build_name;
      case 'mw07':
        return nos.mw07_build_name;
      case 'mw08':
        return nos.mw08_build_name;
      case 'mw09':
        return nos.mw09_build_name;
    }
    return '';
  };

  return (
    <Section relation="standalone">
      <SectionHeader
        icon="upgrading"
        heading="NOS firmware upgrade"
        actions={
          editable && (
            <Button as={RouterLink} to={editNosUpgradeLink} variant="secondary" size="small">
              Edit
            </Button>
          )
        }
      />
      <SectionContent>
        <SummaryList>
          {pendingNosVersion ? (
            <Small weight="bold">
              Currently scheduled to upgrade to{' '}
              <Link as={RouterLink} to={nosVersionViewLink}>
                {pendingNosVersion.nos_version?.version}{' '}
              </Link>
              ({buildStringForDevice()}) on{' '}
              {DateTime.fromISO(pendingFw.scheduled_at!)
                .setZone(timezone)
                .toFormat('MMMM d, h:mm a')}{' '}
            </Small>
          ) : (
            <Small weight="bold">No upgrades scheduled</Small>
          )}
        </SummaryList>
      </SectionContent>
    </Section>
  );
}
