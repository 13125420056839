import type { api } from '@meterup/proto';
import type { AxiosResponse } from 'axios';
import { getMany, getOne, makeAPICall, mutateVoid } from '@meterup/common';
import { sortBy } from 'lodash-es';

import type { FixMeLater } from '../utils/FixMeLater';
import { axiosInstanceJSON } from './api_clients';

export const fetchVPNServer = async (controllerName: string) =>
  getOne(async () => {
    const results = await axiosInstanceJSON.get<api.VPNServer>(
      `/v1/controllers/${controllerName}/vpn-servers`,
    );
    return results.data;
  });

export const createVPNServer = async (controllerName: string, public_key?: string) =>
  mutateVoid(async () => {
    const data: Partial<api.VPNServerUpsertRequest> = {
      public_key: (public_key as FixMeLater) ?? '',
    };
    await axiosInstanceJSON.put(`/v1/controllers/${controllerName}/vpn-servers`, data);
  });

export const createVPNClient = async (controllerName: string, data: api.VPNClientCreateRequest) =>
  makeAPICall(async () => {
    const result = await axiosInstanceJSON.post<
      api.VPNClientCreateRequest,
      AxiosResponse<api.VPNClientResponse>
    >(`/v1/controllers/${controllerName}/vpn-clients`, data);
    return result.data;
  });

export const fetchVPNClient = async (controllerName: string, clientSid: string) =>
  getOne(async () => {
    const results = await axiosInstanceJSON.get<api.VPNClientResponse>(
      `/v1/controllers/${controllerName}/vpn-clients/${clientSid}`,
    );
    return results.data;
  });

export const fetchVPNClients = async (controllerName: string) =>
  getMany(async () => {
    const results = await axiosInstanceJSON.get<api.VPNClientsListResponse>(
      `/v1/controllers/${controllerName}/vpn-clients`,
    );

    return sortBy(Object.values(results.data.clients), (client) => client.name);
  });

export const deleteVPNClient = async (controllerName: string, vpnClientSid: string) =>
  mutateVoid(async () => {
    await axiosInstanceJSON.delete(`/v1/controllers/${controllerName}/vpn-clients/${vpnClientSid}`);
  });
