import { Drawer, DrawerContent, DrawerHeader, Skeleton } from '@meterup/atto';
import React from 'react';

import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { styled } from '../../stitches';

const Container = styled('div', { vStack: '$16', alignItems: 'stretch', height: '100%' });

export interface LoadingDrawerProps {
  title?: React.ReactNode;
}

export function LoadingDrawer({
  title = <Skeleton height="28px" width="200px" radius={4} />,
}: LoadingDrawerProps) {
  return (
    <Drawer>
      <DrawerHeader heading={title} onClose={useCloseDrawerCallback()} />
      <DrawerContent>
        <Container>
          <Skeleton width="100%" height="100%" radius={4} />
        </Container>
      </DrawerContent>
    </Drawer>
  );
}
