import type { api } from '@meterup/proto';
import { getMany, getOne, makeAPICall, mutateVoid } from '@meterup/common';
import { sortBy } from 'lodash-es';

import { axiosInstanceJSON } from './api_clients';

export const updateAP = async (
  controllerName: string,
  apName: string,
  location: string,
  status: api.AccessPointStatus,
) =>
  mutateVoid(async () => {
    const data: Partial<api.AccessPointPutRequest> = {
      skip_greengrass_registration: true,
      status,
      location,
    };
    await axiosInstanceJSON.put(`/v1/controllers/${controllerName}/access-points/${apName}`, data);
  });

export const postFloorPlan = async (controllerName: string, file: File) =>
  mutateVoid(async () => {
    const data = new FormData();
    data.append('file', file);

    await axiosInstanceJSON.post(
      `/v1/controllers/${controllerName}/floor-plan`,

      data,
    );
  });

export const fetchFloorPlan = async (controllerName: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get(`/v1/controllers/${controllerName}/floor-plan`, {
      responseType: 'blob',
    });

    return result.data ? URL.createObjectURL(result.data) : null;
  });

export const fetchDevices = async (controllerName: string) =>
  getOne(async () => {
    const results = await axiosInstanceJSON.get<api.ControllerDeviceResponse>(
      `/v1/controllers/${controllerName}/devices`,
    );
    return results.data;
  });

export const fetchAccessPointsJSON = async (controllerName: string) =>
  getMany(async () => {
    const results = await axiosInstanceJSON.get<api.ControllerDeviceResponse>(
      `/v1/controllers/${controllerName}/devices`,
    );

    return sortBy(Object.values(results.data.access_points), (ap) => [
      ap.connected_status,
      ap.location,
    ]);
  });

export const fetchAccessPointJSON = async (
  controllerName: string,
  deviceName: string,
): Promise<api.AccessPointDeviceResponse | null> =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<api.ControllerDeviceResponse>(
      `/v1/controllers/${controllerName}/devices`,
    );
    return result.data.access_points.find((ap) => ap.name === deviceName) ?? null;
  });

export const deleteAccessPoint = async (controllerName: string, apName: string) =>
  mutateVoid(async () => {
    await axiosInstanceJSON.delete(`/v1/controllers/${controllerName}/access-points/${apName}`);
  });

export const uploadAccessPointsCSV = async (controllerName: any, file: File) =>
  mutateVoid(async () => {
    const data = await file.text();
    await axiosInstanceJSON.post(`/v1/controllers/${controllerName}/access-points`, data, {
      headers: { 'Content-Type': 'text/csv' },
    });
  });

export const getDeviceImages = async (board: string): Promise<string[]> =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<api.DeviceImageBuilds>(`/v1/device-images/${board}`);
    return sortBy(result.data.builds);
  });

export const createFirmwareUpdate = async (
  deviceSerialNumber: string,
  data: api.UpsertFirmwareUpdateRequest,
): Promise<api.FirmwareUpdate> =>
  makeAPICall(async () => {
    const result = await axiosInstanceJSON.post<api.FirmwareUpdate>(
      `/v1/devices/${deviceSerialNumber}/firmware-updates`,
      data,
    );
    return result.data;
  });

export const fetchBootinfoForDevice = async (serial: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<api.DeviceBootinfoList>(
      `/v1/devices/${serial}/bootinfo`,
    );
    return result.data;
  });

export const fetchPendingFirmwareUpdate = async (serial: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<api.FirmwareUpdates>(
      `/v1/devices/${serial}/firmware-updates`,
    );
    return result.data;
  });
