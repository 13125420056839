import {
  Button,
  CompositeField,
  DrawerContent,
  DrawerFooter,
  FieldContainer,
  HStack,
  SecondaryField,
  SecondaryFieldComposite,
  SectionHeader,
  Select,
  SelectItem,
  space,
  TextInput,
} from '@meterup/atto';
import { mboot } from '@meterup/proto';
import { useFormikContext } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';

import type { ValidLegacyNetworkInfoFormData } from './ValidLegacyNetworkInfoFormData';
import { FieldProvider } from '../../../../components/FieldProvider';
import { paths } from '../../../../constants';
import { LegacyNetworkPSKSchemeType } from '../../../../utils/legacy_network_info';
import { makeDrawerLink } from '../../../../utils/makeLink';

export default function LegacyNetworkInfoForm({
  controllerName,
  onClickReview,
}: {
  controllerName: string;
  onClickReview: () => void;
}) {
  const form = useFormikContext<ValidLegacyNetworkInfoFormData>();

  const isGuestPasswordDisabled =
    form.values.guest_strategy !== mboot.GuestPasswordStrategy.UNKNOWN;

  return (
    <>
      <DrawerContent>
        <FieldContainer>
          <SectionHeader heading="Private network" />
          <FieldProvider name="private_ssid">
            <SecondaryField label="SSID 5G" element={<TextInput />} />
          </FieldProvider>
          <FieldProvider name="private_2g_ssid">
            <SecondaryField label="SSID 2G" element={<TextInput />} />
          </FieldProvider>
          <FieldProvider name="private_password">
            <SecondaryField label="Password" element={<TextInput />} />
          </FieldProvider>
        </FieldContainer>
        <FieldContainer>
          <SectionHeader heading="Guest network" />
          <FieldProvider name="guest_ssid">
            <SecondaryField label="SSID" element={<TextInput />} />
          </FieldProvider>
          <SecondaryFieldComposite
            label="Password"
            fields={
              <HStack spacing={space(8)}>
                <FieldProvider name="guest_psk_scheme">
                  <CompositeField
                    label="Type"
                    element={
                      <Select
                        disabledKeys={
                          isGuestPasswordDisabled
                            ? [
                                LegacyNetworkPSKSchemeType.Static,
                                LegacyNetworkPSKSchemeType.Rotating,
                              ]
                            : [LegacyNetworkPSKSchemeType.Rotating]
                        }
                      >
                        <SelectItem key={LegacyNetworkPSKSchemeType.Static}>Static</SelectItem>
                        <SelectItem key={LegacyNetworkPSKSchemeType.Rotating}>Rotating</SelectItem>
                      </Select>
                    }
                  />
                </FieldProvider>
                {form.values.guest_psk_scheme === LegacyNetworkPSKSchemeType.Static && (
                  <FieldProvider name="guest_password">
                    <CompositeField label="Password" element={<TextInput />} />
                  </FieldProvider>
                )}
                {form.values.guest_psk_scheme === LegacyNetworkPSKSchemeType.Rotating && (
                  <FieldProvider name="guest_strategy">
                    <CompositeField
                      label="Rotation frequency"
                      element={
                        <Select disabled>
                          <SelectItem key={mboot.GuestPasswordStrategy.NEVER}>Never</SelectItem>
                          <SelectItem key={mboot.GuestPasswordStrategy.DAILY}>Daily</SelectItem>
                          <SelectItem key={mboot.GuestPasswordStrategy.MONTHLY}>Monthly</SelectItem>
                        </Select>
                      }
                    />
                  </FieldProvider>
                )}
              </HStack>
            }
          />
        </FieldContainer>
      </DrawerContent>
      <DrawerFooter
        actions={
          <>
            <Button
              variant="secondary"
              as={Link}
              to={makeDrawerLink(paths.drawers.LegacyNetworkInfo, { controllerName })}
            >
              Cancel
            </Button>
            <Button onClick={onClickReview} disabled={!form.isValid}>
              Review
            </Button>
          </>
        }
      />
    </>
  );
}
