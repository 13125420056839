import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Button, Drawer, DrawerContent, DrawerHeader } from '@meterup/atto';
import { colors, darkThemeSelector } from '@meterup/atto/src/stitches.config';
import { checkDefinedOrThrow, isDefined, PanAndZoomRegion, preloadImage } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';

import { fetchFloorPlan } from '../../../../api/devices_api';
import { UploadFloorPlanDialog } from '../../../../components/Device/UploadFloorPlanDialog';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { styled } from '../../../../stitches';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/floorplans',
});

const FloorPlanDrawer = styled(Drawer, {
  variants: {
    isFullScreen: {
      true: {
        zIndex: 10,
        gridArea: 'pane / pane / drawer / drawer',
        width: '100%',
        maxWidth: '100%',
        minWidth: '100%',
        background: colors.bgApplicationLight,
        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
        },
      },
      false: {
        width: '500px',
        maxWidth: '500px',
        minWidth: '500px',
      },
    },
  },
});

const PanningContainer = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  strokeAll: colors.strokeApplicationLight,
  padding: 0.5,
});

export default function FloorPlanDetail() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.FloorPlanDetail),
  );

  const [isFullScreen, setIsFullScreen] = useState(false);

  const floorPlanURL = useQuery(
    ['controller', controllerName, 'floor-plan'],
    async () => {
      const url = await fetchFloorPlan(controllerName);
      return url ? preloadImage(url) : null;
    },
    { suspense: true },
  ).data;

  const downloadFloorPlan = (): void => {
    const filename = `${controllerName}-floor-plan-${new Date()
      .toLocaleDateString()
      .replace(/\//g, '-')}`;
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(floorPlanURL, filename);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = floorPlanURL || '';
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  };

  return (
    <FloorPlanDrawer isFullScreen={isFullScreen}>
      <DrawerHeader
        heading="Floor plan"
        actions={
          <>
            <Button
              condense
              arrangement="hidden-label"
              icon={isFullScreen ? 'pane-right' : 'pane-left'}
              variant="secondary"
              onClick={() => setIsFullScreen((prevIsFullScreen) => !prevIsFullScreen)}
            >
              View full screen
            </Button>
            <UploadFloorPlanDialog
              controllerName={controllerName}
              trigger={
                <Button condense arrangement="leading-icon" icon="floorplan" variant="secondary">
                  Replace floor plan
                </Button>
              }
            />
            <Button
              condense
              arrangement="hidden-label"
              icon="download"
              variant="secondary"
              onClick={() => downloadFloorPlan()}
            >
              Download
            </Button>
          </>
        }
        onClose={useCloseDrawerCallback()}
      />
      <DrawerContent>
        <PanningContainer>
          <PanAndZoomRegion>
            {isDefined(floorPlanURL) && (
              <img
                src={floorPlanURL}
                crossOrigin="anonymous"
                alt="Floor plan"
                style={{ maxHeight: '50vh', userSelect: 'none' }}
              />
            )}
          </PanAndZoomRegion>
        </PanningContainer>
      </DrawerContent>
    </FloorPlanDrawer>
  );
}
