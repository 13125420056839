import type { api } from '@meterup/proto';
import type { CellProps, Column } from 'react-table';
import { Section, SectionContent, SectionHeader, Small, SummaryList, VStack } from '@meterup/atto';
import { NeutralBadge } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';

import { fetchBootinfoForDevice } from '../api/devices_api';
import { NosFeature, useNosFeatureEnabled } from '../utils/nosfeature';
import { AutoTable } from './AutoTable/AutoTable';
import { TimestampWithTimezoneShort } from './timestamps';

const columns: Column<api.DeviceBootinfoList_DeviceBootinfoResp>[] = [
  {
    Header: 'Build',
    accessor: (row) => row.build,
  },
  {
    Header: 'Boot count',
    accessor: (row) => row.boot_count,
  },
  {
    Header: 'Reported At',
    accessor: (row) => row.updated_at,
    Cell: (row: CellProps<api.DeviceBootinfoList_DeviceBootinfoResp, string>) => (
      <TimestampWithTimezoneShort value={row.value} timezone="UTC" />
    ),
  },
];

export function BootinfoWidget({ serial, single }: { serial: string; single: boolean }) {
  const bootinfo =
    useQuery(['bootinfo', serial], () => fetchBootinfoForDevice(serial), {
      suspense: true,
    }).data ?? ({} as api.DeviceBootinfoList);

  const isCOS2Enabled = useNosFeatureEnabled(serial, NosFeature.COS2);
  if (isCOS2Enabled) {
    return (
      <Section relation="standalone">
        <SectionHeader
          heading="Boot history (Use Dashboard)"
          icon="upgrading"
          actions={
            <NeutralBadge ends="card" variant="brand">
              COS Config 2.0
            </NeutralBadge>
          }
        />
      </Section>
    );
  }
  return (
    <Section relation="standalone">
      <SectionHeader icon="upgrading" heading="Boot history" />
      {single ? (
        <SectionContent>
          <SummaryList>
            {Object.values(bootinfo.bootinfo.slice(-5).reverse()).map((b) => (
              <VStack key={b.updated_at}>
                <Small>
                  build: <Small family="monospace">{b.build}</Small>{' '}
                </Small>
                <Small>
                  boot_count: <Small family="monospace">{b.boot_count}</Small>{' '}
                </Small>
                <Small>
                  reason: <Small family="monospace">{b.reboot_reason}</Small>{' '}
                </Small>
                <Small>
                  reported at:{' '}
                  <Small family="monospace">
                    <TimestampWithTimezoneShort value={b.updated_at || ''} timezone="UTC" />
                  </Small>
                </Small>
                <hr />
              </VStack>
            ))}
          </SummaryList>
        </SectionContent>
      ) : (
        <AutoTable
          columns={columns}
          data={bootinfo.bootinfo.slice(-5).reverse()} // api sends back asc order. So latest is last elements in array
          shouldShowTopBar={false}
        />
      )}
    </Section>
  );
}
