import { LEGACY_kilobitsPerSecond, LEGACY_megabitsPerSecond } from '@meterup/common';
import { api } from '@meterup/proto';
import * as z from 'zod';

import { convertQuantity } from '../../../../../utils/convertQuantity';
import { usdCents, usdDollars } from '../../../../../utils/currencies';
import { validISPProduct } from '../../../../../utils/isp_product';
import { validISPStatus } from '../../../../../utils/isp_status';

export const NO_INTERFACE = 'none';
export const NO_PROVIDER = 'none';

export const validPlanData = z.object({
  provider: z.string().refine((provider) => provider !== NO_PROVIDER, 'Required'),
  product: validISPProduct.refine((product) => product !== api.ISPProduct.IR_UNKNOWN, 'Required'),
  status: validISPStatus.refine((status) => status !== api.ISPStatus.IS_UNKNOWN, 'Required'),
  network_interface: z.string().optional(),
  account_number: z.string().optional(),
  notes: z.string().optional(),
  download_mbps: z.string().optional(),
  upload_mbps: z.string().optional(),
  monthly_cost_dollars: z
    .string()
    .regex(/^\d+(\.\d{2})?$/, 'Must be a number, optionally with 2 decimal places')
    .optional(),
});

export type ValidPlanData = z.infer<typeof validPlanData>;

export const fromAPIData = (data: api.InternetServicePlan): ValidPlanData => ({
  provider: data.provider,
  // @ts-ignore
  product: data.product,
  // @ts-ignore
  status: data.status,
  account_number: data.account_number,
  notes: data.notes,
  download_mbps: convertQuantity(
    data.download_kbps,
    LEGACY_kilobitsPerSecond,
    LEGACY_megabitsPerSecond,
  ).toString(),
  upload_mbps: convertQuantity(
    data.upload_kbps,
    LEGACY_kilobitsPerSecond,
    LEGACY_megabitsPerSecond,
  ).toString(),
  monthly_cost_dollars: convertQuantity(data.monthly_cost_cents, usdCents, usdDollars).toString(),
  network_interface: data.network_interface!,
});

export const toISPUpdateRequest = (
  data: ValidPlanData,
): {
  planData: api.ISPUpdateRequest;
} => ({
  planData: {
    provider: data.provider,
    product: data.product,
    status: data.status,
    account_number: data.account_number ?? '',
    notes: data.notes ?? '',
    download_kbps: convertQuantity(
      Number.parseFloat(data.download_mbps ?? '0'),
      LEGACY_megabitsPerSecond,
      LEGACY_kilobitsPerSecond,
    ),
    upload_kbps: convertQuantity(
      Number.parseFloat(data.upload_mbps ?? '0'),
      LEGACY_megabitsPerSecond,
      LEGACY_kilobitsPerSecond,
    ),
    monthly_cost_cents: convertQuantity(
      Number.parseFloat(data.monthly_cost_dollars ?? '0'),
      usdDollars,
      usdCents,
    ),
    // This is a hack. We will no longer set the IP on the ISP at all, but before we do the work to
    // strip it out of protobuf and the API, we'll make sure we don't need it here.
    //
    // TODO (apt, 2023-06-07): Remove ip info from ISP table, proto, and API.
    set_dynamic_ip: true,
    static_ip_range: '',
    gateway_addr: '',
    controller_ip: '',
    first_usable_ip: '',
    last_usable_ip: '',
    network_interface: data.network_interface,
  },
});

export const toISPCreateRequest = (
  data: ValidPlanData,
): {
  planData: api.ISPCreateRequest;
} => {
  const apiData = toISPUpdateRequest(data);
  // The only difference between ISPCreateRequest and ISPUpdateRequest is that
  // ISPUpdateRequest has a set_dynamic_ip field. So we remove it. The API won't
  // accept a payload with the field.
  delete (apiData.planData as any).set_dynamic_ip;
  return apiData;
};
