import {
  DockTarget,
  DropdownMenu,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  DropdownMenuTrigger,
  Select,
  SelectItem,
} from '@meterup/atto';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { match } from 'ts-pattern';

import { getIdentity } from '../api/api';
import { useLogoutHandler } from '../hooks/useLogoutHandler';
import {
  useChangeCurrentTimezoneCallback,
  useCurrentTimezone,
} from '../providers/CurrentTimezoneProvider';
import {
  CENTRAL_TIMEZONE,
  EASTERN_TIMEZONE,
  MOUNTAIN_TIMEZONE,
  PACIFIC_TIMEZONE,
  timezones,
} from './timestamps';
import { UserProfilePicture } from './UserProfilePicture';

export const TimezoneDropdownString = ({ tz }: { tz: string }) =>
  match(tz)
    .with(PACIFIC_TIMEZONE, () => 'Los Angeles (Pacific Standard Time)')
    .with(MOUNTAIN_TIMEZONE, () => 'Denver (Mountain Standard Time)')
    .with(CENTRAL_TIMEZONE, () => 'Chicago (Central Standard Time)')
    .with(EASTERN_TIMEZONE, () => 'New York (Eastern Standard Time)')
    .otherwise(() => tz);

export function HeaderDropdownMenu() {
  const timezone = useCurrentTimezone();
  const setTimezone = useChangeCurrentTimezoneCallback();
  const logout = useLogoutHandler();
  const identity = useQuery(['identity'], getIdentity, { suspense: false }).data;
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        {identity ? (
          <DockTarget label={identity.username} arrow>
            <UserProfilePicture
              email={identity.username}
              firstName={identity.first_name}
              lastName={identity.last_name}
            />
          </DockTarget>
        ) : null}
      </DropdownMenuTrigger>
      <DropdownMenuPopover align="start">
        <DropdownMenuGroup>
          <Select width="100%" defaultValue={timezone} onValueChange={setTimezone}>
            {timezones.map((t) => (
              <SelectItem key={t}>{TimezoneDropdownString({ tz: t })}</SelectItem>
            ))}
          </Select>
        </DropdownMenuGroup>
        <DropdownMenuGroup>
          <DropdownMenuItem icon="power" onSelect={logout}>
            Sign out
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuPopover>
    </DropdownMenu>
  );
}
