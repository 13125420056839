import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Alert,
  Body,
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from '@meterup/atto';
import { checkDefinedOrThrow } from '@meterup/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Link } from 'react-router-dom';

import { deleteInternetServicePlan } from '../../../../api/controllers_api';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { fontWeights } from '../../../../stitches';
import { makeDrawerLink } from '../../../../utils/makeLink';
import { PlanControls } from './common/PlanControls';
import PlanDetails from './common/PlanDetails';
import { usePlanDetails } from './common/usePlanDetails';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/plans/:planId/delete',
});

export default function ISPDelete() {
  const queryClient = useQueryClient();

  const closeDrawer = useCloseDrawerCallback();

  const { controllerName, planId } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.ISPDelete),
  );

  const planDetails = usePlanDetails(controllerName, planId);

  const deletePlanMutation = useMutation(
    async () => {
      await deleteInternetServicePlan(controllerName, planId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controllers', controllerName, 'service-plans']);
        closeDrawer();
      },
    },
  );

  return (
    <Drawer>
      <DrawerHeader
        heading="Remove plan"
        actions={<PlanControls controllerName={controllerName} planId={planId} />}
        onClose={useCloseDrawerCallback()}
      />

      <DrawerContent>
        <Alert
          heading="Confirmation required"
          copy={
            <>
              Are you sure you wish to delete this plan from the controller{' '}
              <Body css={{ fontWeight: fontWeights.bold }}>{planDetails.controller}</Body>?
            </>
          }
          variant="negative"
        />

        <PlanDetails controllerName={controllerName} planId={planId} />
      </DrawerContent>

      <DrawerFooter
        actions={
          <>
            <Button
              type="button"
              variant="secondary"
              as={Link}
              to={makeDrawerLink(paths.drawers.ISPDetails, {
                controllerName,
                planId,
              })}
              loading={deletePlanMutation.isLoading}
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={() => deletePlanMutation.mutate()}
              loading={deletePlanMutation.isLoading}
            >
              Delete
            </Button>
          </>
        }
      />
    </Drawer>
  );
}
