import { api } from '@meterup/proto';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import type { CompanyUsersForCompanyHookQuery } from '../gql/graphql';
import type { QueryResultField } from '../types';
import { graphql } from '../gql';
import { CompanyMembershipRole } from '../gql/graphql';
import { makeQueryKey, useGraphQL } from './useGraphQL';

graphql(`
  fragment CompanyUserFragment on CompanyUser {
    company {
      name
    }
    companyMembershipRole
    deletedAt
    createdAt
    UUID
    user {
      firstName
      lastName
      email
    }
  }
`);

const companyUsersForCompanyQueryForHook = graphql(`
  query CompanyUsersForCompanyHook($companyName: String!) {
    companyUsers(companySlug: $companyName) {
      ...CompanyUserFragment
    }
  }
`);

const getUserGraphQLQuery = graphql(`
  query GetCompanyUser($uuid: UUID!, $input: GetCompanyUserInput!) {
    companyUser(companyUserUUID: $uuid, input: $input) {
      ...CompanyUserFragment
    }
  }
`);

export type CompanyUsersForCompanyQueryType = QueryResultField<
  CompanyUsersForCompanyHookQuery,
  'companyUsers'
>;

export function useCompanyUsersGql(companyName: string) {
  const result = useGraphQL(companyUsersForCompanyQueryForHook, {
    companyName,
  });
  return useMemo(() => result.data?.companyUsers ?? [], [result]);
}

export function getCompanyUsersGqlQueryKey(companyName: string) {
  return makeQueryKey(companyUsersForCompanyQueryForHook, { companyName });
}

export function useInvalidateCompanyUsersGql(companyName: string) {
  const queryClient = useQueryClient();
  const queryKey = getCompanyUsersGqlQueryKey(companyName);
  return useCallback(() => {
    queryClient.invalidateQueries(queryKey);
  }, [queryClient, queryKey]);
}

export enum GraphQLResult {
  Loading,
  Error,
}

export function useCompanyUserGql(companyUserSID: string) {
  const result = useGraphQL(getUserGraphQLQuery, {
    uuid: companyUserSID,
    input: {
      includeDeleted: true,
    },
  });
  return useMemo(() => {
    if (result.isLoading) {
      return GraphQLResult.Loading;
    }
    if (result.isError) {
      return GraphQLResult.Error;
    }
    const companyUser = result.data?.companyUser;
    if (!companyUser) {
      return null;
    }
    let companyRole = api.CompanyMembershipRole.unknown;
    if (companyUser.companyMembershipRole === CompanyMembershipRole.Guest) {
      companyRole = api.CompanyMembershipRole.guest;
    }
    if (companyUser.companyMembershipRole === CompanyMembershipRole.Member) {
      companyRole = api.CompanyMembershipRole.member;
    }
    if (companyUser.companyMembershipRole === CompanyMembershipRole.Admin) {
      companyRole = api.CompanyMembershipRole.admin;
    }
    return {
      ...companyUser,
      user: {
        ...companyUser.user,
        company_role: companyRole,
        deletedAt: companyUser.deletedAt,
      },
    };
  }, [result]);
}
