import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
} from '@meterup/atto';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  isDefinedAndNotEmpty,
  ResourceNotFoundError,
} from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { fetchControllerJSON } from '../../../../api/controllers_api';
import { fetchAccessPointJSON } from '../../../../api/devices_api';
import { BootinfoWidget } from '../../../../components/BootinfoWidget';
import { DeleteDeviceDialog } from '../../../../components/Device/DeleteDeviceDialog';
import DeviceDetailContent from '../../../../components/Device/DeviceDetailContent';
import { Nav } from '../../../../components/Nav';
import { WidgetSuspenseAndErrorBoundary } from '../../../../components/WidgetSuspenseAndErrorBoundary';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/devices/:id',
});

export default function DeviceDetail() {
  const { controllerName, id } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.DeviceDetail),
  );

  const controller = useQuery(
    ['controllers', controllerName],
    () => fetchControllerJSON(controllerName),
    { suspense: true },
  ).data;

  const device = useQuery(
    ['controller', controllerName, 'accessPoint', id],
    () => fetchAccessPointJSON(controllerName, id),
    { suspense: true, enabled: isDefinedAndNotEmpty(id) },
  ).data;

  expectDefinedOrThrow(device, new ResourceNotFoundError('Access point not found'));

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Drawer>
      <DrawerHeader
        heading="Access point"
        actions={
          <DropdownMenu>
            <DropdownMenuButton
              arrangement="hidden-label"
              icon="overflow-vertical"
              variant="secondary"
            >
              Actions
            </DropdownMenuButton>
            <DropdownMenuPopover>
              <DropdownMenuGroup>
                <DropdownMenuItem onSelect={() => setIsOpen(true)}>
                  Remove access point
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuPopover>
          </DropdownMenu>
        }
        onClose={useCloseDrawerCallback()}
      />

      <DrawerContent>
        <DeviceDetailContent
          controllerName={controllerName}
          device={device}
          networkUUID={controller!.network_uuid}
        />
        <WidgetSuspenseAndErrorBoundary title="">
          <BootinfoWidget serial={device.serial_number || ''} single />
        </WidgetSuspenseAndErrorBoundary>
      </DrawerContent>

      <DeleteDeviceDialog
        controllerName={controllerName}
        deviceId={id}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
      />
    </Drawer>
  );
}
