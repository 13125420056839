import { isDefined } from '@meterup/common';

import type {
  ControllerStateResponse,
  ControllerUpgradeManagerConfig,
  ControllerUpgradeManagerState,
} from '../api/types';

const controllerVersionRegex = /\d+-release-cos-([^-]+)-([^-]+)-([^-]+)-\w+-\w+/;

export const formatControllerVersion = (version: string): [string, string] => {
  const matches = version.match(controllerVersionRegex);
  if (matches && matches.length === 4) {
    return [version, `${matches[1]}.${matches[2]}.${matches[3]}`];
  }
  return [version, ''];
};
export const formatControllerVersionFromState = (
  state: ControllerStateResponse | null | undefined,
): [string, string] => {
  if (!state) {
    return ['', ''];
  }
  const version =
    (state.state as ControllerUpgradeManagerState)['meter.v1.upgrade-manager']?.version || '';
  return formatControllerVersion(version);
};

export const formatControllerVersionFromConfig = (
  config?: ControllerUpgradeManagerConfig,
): [string, string] => {
  if (!isDefined(config) || !config['build-name']) {
    return ['', ''];
  }
  return formatControllerVersion(config['build-name']);
};
