import { styled } from '../stitches';

export const ListItemTableContainer = styled('div', {
  padding: '0 0.5px',
  flexDirection: 'column',
  alignItems: 'stretch',
  overflowX: 'auto',
  overflowY: 'hidden',

  // KLUDGE: The corner-radii of the ListItem needs to propagated down to the
  // table nested within it to avoid overlapping with the rounded edges of the
  // ListItem. This is a very brittle approach and deserves rethinking from
  // within the component library.
  '&:last-child': {
    '[role="rowgroup"] [role="row"]': {},
    '[role="row"]:last-child [role=cell]:first-child:after': {
      borderBottomLeftRadius: 7,
    },
    '[role="row"]:last-child [role=cell]:last-child:after': {
      borderBottomRightRadius: 7,
    },
  },
});
