import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Alert, Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader } from '@meterup/atto';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  getErrorMessage,
  isDefined,
  ResourceNotFoundError,
} from '@meterup/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Formik } from 'formik';
import React, { Suspense } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import type { ValidIncidentData } from './common/form_data';
import { updateIncident } from '../../../../api/controllers_api';
import { LoadingDrawer } from '../../../../components/Drawers/LoadingDrawer';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { useCurrentTimezone } from '../../../../providers/CurrentTimezoneProvider';
import { styled } from '../../../../stitches';
import { makeDrawerLink } from '../../../../utils/makeLink';
import { EditFormContent } from './common/EditFormContent';
import {
  CATEGORY_NONE,
  DETAILS,
  toIncidentCreateRequest,
  validIncidentData,
} from './common/form_data';
import { useControllerIncident } from './common/useControllerIncident';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/incidents/:id/edit-details',
});

const StyledForm = styled('form', {
  display: 'contents',
});

function IncidentEditDetailsContent() {
  const navigate = useNavigate();
  const timezone = useCurrentTimezone();
  const closeDrawer = useCloseDrawerCallback();

  const { controllerName, id } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.IncidentEditDetails),
  );

  const incident = useControllerIncident(controllerName, id);

  expectDefinedOrThrow(incident, new ResourceNotFoundError('Incident not found'));

  const queryClient = useQueryClient();

  const createDrawerMutation = useMutation(
    async (data: ValidIncidentData) => {
      const apiData = toIncidentCreateRequest(data, timezone);
      return updateIncident(controllerName, id, apiData.incidentData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controller', controllerName, 'incidents']);
        navigate(
          makeDrawerLink(paths.drawers.IncidentDetail, {
            controllerName,
            id,
          }),
        );
      },
    },
  );

  return (
    <Formik<ValidIncidentData>
      initialValues={{
        start_time: incident.start_time ?? '',
        end_time: incident.end_time ?? '',
        notes: incident.notes ?? '',
        category: incident.category ?? CATEGORY_NONE,
      }}
      validationSchema={toFormikValidationSchema(validIncidentData)}
      onSubmit={(values) => createDrawerMutation.mutate(values)}
    >
      {(form) => (
        <StyledForm onSubmit={form.handleSubmit}>
          <Drawer>
            <DrawerHeader heading="Edit details" onClose={closeDrawer} />
            <DrawerContent>
              {isDefined(createDrawerMutation.error) && (
                <Alert
                  heading="Error while submitting"
                  copy={getErrorMessage(createDrawerMutation.error)}
                />
              )}
              <EditFormContent page={DETAILS} />
            </DrawerContent>

            <DrawerFooter
              actions={
                <>
                  <Button
                    type="button"
                    variant="secondary"
                    as={Link}
                    to={makeDrawerLink(paths.drawers.IncidentDetail, {
                      controllerName,
                      id,
                    })}
                  >
                    Cancel
                  </Button>
                  <Button type="submit">Save</Button>
                </>
              }
            />
          </Drawer>
        </StyledForm>
      )}
    </Formik>
  );
}

export default function IncidentEditDetails() {
  return (
    <Suspense fallback={<LoadingDrawer title="Edit details" />}>
      <IncidentEditDetailsContent />
    </Suspense>
  );
}
