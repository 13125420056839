import type { api } from '@meterup/proto';
import * as z from 'zod';

import type { FixMeLater } from '../../../../utils/FixMeLater';

export const validVPNClientData = z.object({
  public_key: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  email: z.string().optional().nullable(),
});

export type ValidVPNClientData = z.infer<typeof validVPNClientData>;

export const toVPNClientCreateRequest = (
  data: ValidVPNClientData,
): {
  vpnClientData: api.VPNClientCreateRequest;
} => ({
  vpnClientData: {
    public_key: (data.public_key as FixMeLater) ?? '',
    name: data.name ?? '',
    email: data.email ?? '',
  },
});

export const validVPNServerEditData = z.object({
  port: z.string().optional().nullable(),
  address: z.string().optional().nullable(),
  enabled: z.boolean().optional().nullable(),
  defaultAllowedIPs: z
    .string()
    // TODO(adam): I'm sorry. This is the easiest to do. This regex matches comma separated IPv4 CIDRs. Replace once we have a tag list component.
    .regex(
      /^(([0-9]{1,3}\.){3}[0-9]{1,3}\/([0-9]|[1-2][0-9]|3[0-2]))(,(([0-9]{1,3}\.){3}[0-9]{1,3}\/([0-9]|[1-2][0-9]|3[0-2])))*$/,
      'Must be a comma separated list of IPv4 CIDRs',
    )
    .optional()
    .nullable(),
});

export type ValidVPNServerEditData = z.infer<typeof validVPNServerEditData>;
