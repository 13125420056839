import type { metrics } from '@meterup/proto';
import { makeAPICall } from '@meterup/common';

import { axiosInstanceJSON } from './api_clients';

enum LinkStatus {
  Online = 'online',
  Offline = 'offline',
}
interface ControllerLinkStatus {
  time: Date;
  status: LinkStatus;
  wan: string;
}
export const fetchLatestLinkStatus = async (
  controllerName: string,
): Promise<ControllerLinkStatus[]> => {
  const data = await makeAPICall(async () => {
    const result = await axiosInstanceJSON.get<metrics.GetMetrics2Response>('/v1/metrics2', {
      params: {
        series_id: 'latest_link_status',
        object_id: controllerName,
        end_time: new Date().toISOString(), // not used
        duration_ms: 60, // arbitrary, not used
      },
    });
    return result.data;
  });

  const response: ControllerLinkStatus[] = [];

  data.data.forEach((series) => {
    if (series.values.length > 0) {
      response.push({
        time: new Date(series.values[0].timestamp!),
        status: series.values[0].value === 1 ? LinkStatus.Online : LinkStatus.Offline,
        wan: series.metadata?.name ?? '',
      });
    }
  });

  return response;
};

export const fetchLatestUptimeMetric = async (name: string): Promise<number> => {
  const data = await makeAPICall(async () => {
    const result = await axiosInstanceJSON.get<metrics.GetMetrics2Response>('/v1/metrics2', {
      params: {
        series_id: 'latest_uptime',
        object_id: name,
        end_time: new Date().toISOString(), // not used
        duration_ms: 60, // arbitrary, not used
      },
    });
    return result.data;
  });

  if (data.data.length > 0 && data.data[0].values.length > 0) {
    return data.data[0].values[0].value;
  }
  return 0;
};

interface DeviceMemoryStatus {
  total: number;
  available: number;
}

export const fetchLatestMemoryStatus = async (name: string): Promise<DeviceMemoryStatus> => {
  const data = await makeAPICall(async () => {
    const result = await axiosInstanceJSON.get<metrics.GetMetrics2Response>('/v1/metrics2', {
      params: {
        series_id: 'latest_mem_snaphot',
        object_id: name,
        end_time: new Date().toISOString(), // not used
        duration_ms: 60, // arbitrary, not used
      },
    });
    return result.data;
  });

  if (data.data.length > 1) {
    return <DeviceMemoryStatus>{
      total: data.data[0].values[0].value,
      available: data.data[1].values[0].value,
    };
  }
  return {} as DeviceMemoryStatus;
};
