import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Alert, Button, Pane, PaneContent } from '@meterup/atto';
import { checkDefinedOrThrow } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { fetchControllerJSON } from '../../../api/controllers_api';
import Clients from '../../../components/Client/Clients';
import { Nav } from '../../../components/Nav';
import { paths } from '../../../constants';
import { NosFeature, useNosFeatureEnabled } from '../../../utils/nosfeature';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/clients',
});

export default function ControllerClientsList() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerClientsList),
  );

  const networkInfo = useQuery(
    ['controller', controllerName],
    async () => fetchControllerJSON(controllerName),
    { suspense: true },
  ).data;

  const isWOS2Enabled = useNosFeatureEnabled(controllerName, NosFeature.WOS2);

  return (
    <PaneContent>
      {isWOS2Enabled && (
        <Alert
          icon="attention"
          variant="attention"
          heading="This network uses WOS Config 2.0"
          copy="Client details in this page are invalid. Please view dashboard for client information."
          relation="stacked"
          shoulderButtons={
            networkInfo ? (
              <Button
                variant="secondary"
                as="a"
                target="_blank"
                href={`${import.meta.env.DASHBOARD_URL}/org/${
                  networkInfo.company_slug
                }/network/${networkInfo.network_uuid}`}
              >
                View dashboard
              </Button>
            ) : undefined
          }
        />
      )}
      <Pane>
        <Clients controllerName={controllerName} />
      </Pane>
    </PaneContent>
  );
}
