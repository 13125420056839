import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuPopover,
} from '@meterup/atto';
import { checkDefinedOrThrow, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { fetchVPNClient } from '../../../../api/vpn';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { DeleteVPNClientDialog } from './VPNClientDelete';
import VPNClientDetailContent from './VPNClientDetailContent';

export const Meta = () => ({
  path: '/controllers/:controllerName/vpn/:clientSid',
});

export default function VPNClientDetailPage() {
  const closeDrawer = useCloseDrawerCallback();
  const [isOpen, setIsOpen] = useState(false);
  const { controllerName, clientSid } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.VPNClientDetailPage),
  );

  const vpnClientDetails = useQuery(
    ['controller', controllerName, 'vpn-clients', clientSid],
    () => fetchVPNClient(controllerName, clientSid),
    { suspense: true },
  ).data;

  expectDefinedOrThrow(
    vpnClientDetails,
    new ResourceNotFoundError('VPN client response not found'),
  );
  expectDefinedOrThrow(vpnClientDetails.client, new ResourceNotFoundError('VPN client not found'));
  expectDefinedOrThrow(vpnClientDetails.server, new ResourceNotFoundError('VPN client not found'));

  return (
    <Drawer>
      <DrawerHeader
        heading="VPN client"
        actions={
          <DropdownMenu>
            <DropdownMenuButton
              arrangement="hidden-label"
              icon="overflow-vertical"
              variant="secondary"
            >
              Actions
            </DropdownMenuButton>
            <DropdownMenuPopover>
              <DropdownMenuItem onSelect={() => setIsOpen(true)}>
                Remove VPN client
              </DropdownMenuItem>
            </DropdownMenuPopover>
          </DropdownMenu>
        }
        onClose={closeDrawer}
      />
      <DrawerContent>
        <VPNClientDetailContent
          controllerName={controllerName}
          vpnClient={vpnClientDetails.client}
          vpnServer={vpnClientDetails.server}
        />
      </DrawerContent>
      <DeleteVPNClientDialog
        controllerName={controllerName}
        vpnClientName={vpnClientDetails.client.name}
        vpnClientSid={vpnClientDetails.client.sid}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
      />
    </Drawer>
  );
}
