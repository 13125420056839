import { graphql } from '../gql';

export const networksForCompany = graphql(`
  query NetworksForCompany($companySlug: String!) {
    networksForCompany(companySlug: $companySlug) {
      label
      UUID
      slug
      goLiveAt
    }
  }
`);
