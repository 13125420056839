import { PaneContent } from '@meterup/atto';
import { ErrorBoundary } from '@sentry/react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import React, { Suspense } from 'react';

import type { ErrorFallbackProps } from '../components/error_fallbacks';
import { LoadingDrawer } from '../components/Drawers/LoadingDrawer';
import { ErrorFallbackDrawer, GenericErrorFallback } from '../components/error_fallbacks';
import { Nav } from '../components/Nav';
import { LoadingFallback } from './LoadingFallback';

function ErrorFallbackDrawerInContainer(props: ErrorFallbackProps) {
  return <ErrorFallbackDrawer {...props} />;
}

export function MainAndDrawerLayout({
  main,
  drawer,
}: {
  main: React.ReactNode;
  drawer?: React.ReactNode;
}) {
  const location = Nav.useRegionLocation('root');
  const drawerLocation = Nav.useRegionLocation('drawer');
  return (
    <>
      <QueryErrorResetBoundary
        // TRICKY: Pass key to the error boundary to force re-render when route changes
        key={location.pathname}
      >
        {({ reset }) => (
          <ErrorBoundary onReset={reset} fallback={GenericErrorFallback}>
            <Suspense
              fallback={
                <PaneContent>
                  <LoadingFallback />
                </PaneContent>
              }
            >
              {main}
            </Suspense>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
      <ErrorBoundary key={drawerLocation?.pathname ?? ''} fallback={ErrorFallbackDrawerInContainer}>
        <Suspense fallback={<LoadingDrawer />}>{drawer}</Suspense>
      </ErrorBoundary>
    </>
  );
}
