import type { FallbackRender } from '@sentry/react';
import type { ReactNode } from 'react';
import { Section, SectionContent, SectionHeader, Skeleton } from '@meterup/atto';
import { ErrorBoundary } from '@sentry/react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import React, { Suspense, useCallback } from 'react';

import { ErrorFallbackWidget } from './error_fallbacks';

function LoadingWidget({
  title,
  skeletonHeight = '160px',
}: {
  title: ReactNode;
  skeletonHeight?: string;
}) {
  return (
    <Section relation="standalone">
      <SectionHeader heading={title} />
      <SectionContent>
        <div style={{ paddingTop: 16, paddingBottom: 16 }}>
          <Skeleton radius={4} height={skeletonHeight} width="100%" />
        </div>
      </SectionContent>
    </Section>
  );
}

export function WidgetSuspenseAndErrorBoundary({
  title,
  children,
}: {
  title: ReactNode;
  children: ReactNode;
}) {
  const fallback = useCallback<FallbackRender>(
    (props) => (
      <ErrorFallbackWidget title={title} error={props.error} resetError={props.resetError} />
    ),
    [title],
  );

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary onReset={reset} fallback={fallback}>
          <Suspense fallback={<LoadingWidget title={title} />}>{children}</Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
