import type { api } from '@meterup/proto';
import type { AxiosResponse } from 'axios';
import {
  ControllerRequestFailedError,
  getMany,
  getOne,
  makeAPICall,
  mutateVoid,
} from '@meterup/common';
import { mboot } from '@meterup/proto';
import { orderBy, sortBy } from 'lodash-es';

import type {
  ControllerConfigResponse,
  ControllerJSONWithStats,
  ControllerStateResponse,
  NetworkInfoResponseData,
  NetworkInfoUpdateRequest,
} from './types';
import { logError } from '../utils/logError';
import { axiosInstanceJSON } from './api_clients';

async function getResultOrThrow<T>(
  action: () => T | Promise<T>,
  errorFn: (error: unknown) => Error,
): Promise<T> {
  try {
    return await action();
  } catch (failure) {
    logError(failure);
    throw errorFn(failure);
  }
}

const delay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const fetchNetworkInfoJSON = async (
  controllerName: string,
): Promise<NetworkInfoResponseData | null> => {
  if (import.meta.env.REALM === 'local') {
    await delay(10);
    if (Math.random() > 0.5) {
      return {
        guest_password: 'pizza party',
        guest_ssid: 'Local Guest',
        guest_strategy: mboot.GuestPasswordStrategy.MONTHLY,
        private_2g_ssid: 'Local 2G',
        private_password: 'pepperoni',
        private_ssid: 'Local 5g',
        production_password: 'tomato',
        production_ssid: 'Local Custom Network',
        production_ssid_is_disabled: true,
        private_ssid_is_disabled: true,
      };
    }

    throw new ControllerRequestFailedError(controllerName, 'network_info');
  }

  const result = await getResultOrThrow(
    () =>
      axiosInstanceJSON.get<api.ControllerMessageResponse>(
        `/v1/controllers/${controllerName}/network-info?timeout=10s`,
      ),
    (error) => new ControllerRequestFailedError(controllerName, 'network_info', error),
  );

  return getResultOrThrow(
    // @ts-expect-error
    () => JSON.parse(result.data.response.data) as NetworkInfoResponseData,
    (error) => new ControllerRequestFailedError(controllerName, 'network_info', error),
  );
};

export const fetchStatsJSON = () =>
  makeAPICall(async () => {
    const result = await axiosInstanceJSON.get<api.NOCControllerStatsResponse>(
      '/v1/noc/controller-stats',
    );
    return result.data.stats;
  });

export const fetchInternetServicePlansJSON = async (controllerName: string) =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<api.ISPListResponse>(
      `/v1/controllers/${controllerName}/internet-service-plans`,
    );
    return result.data.plans;
  });

export const getProvider = async (sid: string) =>
  getOne(async () => {
    const response = await axiosInstanceJSON.get<api.Provider>(`/v1/providers/${sid}`);
    return response.data;
  });

export const getNetworkInterfaces = async (controllerName: string) =>
  getMany(async () => {
    const response = await axiosInstanceJSON.get<api.NetworkInterfaceListResponse>(
      `/v1/controllers/${controllerName}/network-interfaces`,
    );
    return response.data.interfaces;
  });

export interface PlanAndProvider {
  plan: api.InternetServicePlan;
  provider: api.Provider | null;
}

export const fetchServicePlanAndProvider = async (
  controllerName: string,
): Promise<PlanAndProvider[]> =>
  getMany(async () => {
    const plans = await fetchInternetServicePlansJSON(controllerName);
    return Promise.all(
      plans.map(async (plan) => ({
        plan,
        provider: await getProvider(plan.provider),
      })),
    );
  });

export const fetchControllersJSON = async () =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<api.ControllersResponse>('/v1/controllers');
    return sortBy(result.data.controllers, (d) => d.company_slug);
  });

export const fetchControllersWithStats = async (): Promise<ControllerJSONWithStats[]> =>
  getMany(async () => {
    const controllers = await fetchControllersJSON();
    const stats = await fetchStatsJSON();

    return controllers.map((controller) => {
      const stat = stats?.[controller.name] ?? null;
      return {
        controller,
        stat,
      };
    });
  });

export const fetchControllerJSON = async (controllerName: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<api.ControllerResponse>(
      `/v1/controllers/${controllerName}`,
    );
    return result.data;
  });

export const updateNetworkInfoJSON = async (
  controllerName: string,
  data: NetworkInfoUpdateRequest,
) =>
  mutateVoid(async () =>
    axiosInstanceJSON.post(`/v1/controllers/${controllerName}/network-info`, data),
  );

export const updateControllerMetadataJSON = async (
  controllerName: string,
  data: Partial<api.ControllerUpdateRequest>,
) => mutateVoid(async () => axiosInstanceJSON.post(`/v1/controllers/${controllerName}`, data));

// Fetches all companies from the API, one 500-item page at a time. In the
// future, this function should allow calling code to control the pagination.
export const fetchCompanies = async (): Promise<api.CompanyResponse[]> => {
  try {
    const results = [];
    let nextResult = [];
    let offset = 0;
    const pageSize = 500;

    do {
      // eslint-disable-next-line no-await-in-loop
      nextResult = await axiosInstanceJSON
        .get<api.CompaniesListResponse>('/v1/companies', {
          params: {
            offset,
            limit: pageSize,
          },
        })
        .then((d) => d.data.companies)
        .catch((error) => {
          throw new Error(error.response?.data?.title);
        });

      results.push(...nextResult);
      offset += pageSize;
    } while (nextResult.length === pageSize);

    return results;
  } catch (error) {
    logError(error);
    return [];
  }
};

export const fetchControllerConfig = async (controllerName: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<ControllerConfigResponse>(
      `/v1/controllers/${controllerName}/config`,
    );
    return result.data;
  });

export const fetchControllerState = async (controllerName: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<ControllerStateResponse>(
      `/v1/controllers/${controllerName}/state`,
    );
    return result.data;
  });

export const upsertControllerConfigs = async (
  controllerName: string,
  configs: object, // JSON object of config key and config value pairs
  changeComment: string, // Reason for changing the config
) =>
  mutateVoid(async () =>
    axiosInstanceJSON.post(`/v1/controllers/${controllerName}/config`, {
      config: configs,
      reason: changeComment,
    }),
  );

export const upsertControllerConfigKey = async (
  controllerName: string,
  key: string,
  value: object,
) =>
  mutateVoid(async () =>
    axiosInstanceJSON.post(`/v1/controllers/${controllerName}/config/${key}`, {
      config: value,
    }),
  );

export const deleteControllerConfigKey = async (controllerName: string, key: string) =>
  mutateVoid(async () =>
    axiosInstanceJSON.delete(`/v1/controllers/${controllerName}/config/${key}`),
  );

export const fetchControllerEvents = async (controllerName: string) =>
  getMany(async () => {
    const currentDate = new Date();
    const twoWeeksAgo = currentDate.setDate(currentDate.getDate() - 12);
    const twoWeeksAgoUnix = Math.round(new Date(twoWeeksAgo).getTime() / 1000).toString();
    const result = await axiosInstanceJSON.get<api.AdhocControllerEventListResponse>(
      `/v1/controllers/${controllerName}/events?minimum-created-at=${twoWeeksAgoUnix}`,
    );
    return orderBy(result.data.events, (e) => e.created_at, 'desc');
  });

export const getInternetServicePlan = async (controllerName: string, sid: string) =>
  getOne(async () => {
    const response = await axiosInstanceJSON.get<api.ISPListResponse>(
      `/v1/controllers/${controllerName}/internet-service-plans`,
    );
    return response.data.plans.find((p) => p.sid === sid) ?? null;
  });

export const createInternetServicePlan = async (
  controllerName: string,
  data: api.ISPCreateRequest,
) =>
  makeAPICall(async () => {
    const result = await axiosInstanceJSON.post<
      api.ISPCreateRequest,
      AxiosResponse<api.InternetServicePlan>
    >(`/v1/controllers/${controllerName}/internet-service-plans`, data);
    return result.data;
  });

export const deleteInternetServicePlan = async (controllerName: string, planId: string) =>
  mutateVoid(async () => {
    await axiosInstanceJSON.delete(
      `/v1/controllers/${controllerName}/internet-service-plans/${planId}`,
    );
  });

export const updateInternetServicePlan = async (
  controllerName: string,
  sid: string,
  data: api.ISPUpdateRequest,
) =>
  mutateVoid(async () => {
    await axiosInstanceJSON.post(
      `/v1/controllers/${controllerName}/internet-service-plans/${sid}`,
      data,
    );
  });

export const getAllProviders = async () =>
  getMany(async () => {
    const response = await axiosInstanceJSON.get<api.ProvidersResponse>('/v1/providers');
    return response.data.providers;
  });

export const fetchIncidents = async (controllerName: string) =>
  getMany(async () => {
    const response = await axiosInstanceJSON.get<api.ControllerIncidents>(
      `/v1/controllers/${controllerName}/incidents`,
    );
    return response.data.incidents;
  });

export const fetchIncident = async (controllerName: string, sid: string) =>
  getOne(async () => {
    const response = await axiosInstanceJSON.get<api.ControllerIncidents>(
      `/v1/controllers/${controllerName}/incidents`,
    );
    return response.data.incidents.find((i) => i.sid === sid) ?? null;
  });

export const createIncident = async (
  controllerName: string,
  data: api.UpsertControllerIncidentRequest,
) =>
  makeAPICall(async () => {
    const response = await axiosInstanceJSON.post<
      api.UpsertControllerIncidentRequest,
      AxiosResponse<api.ControllerIncident>
    >(`/v1/controllers/${controllerName}/incidents`, data);
    return response.data;
  });

export const updateIncident = async (
  controllerName: string,
  sid: string,
  data: api.UpsertControllerIncidentRequest,
) =>
  mutateVoid(async () => {
    await axiosInstanceJSON.put(`/v1/controllers/${controllerName}/incidents/${sid}`, data);
  });

export const fetchMaintenanceWindowForController = async (
  controller: string,
): Promise<api.MaintenanceWindows> =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get(`/v1/controllers/${controller}/maintenance-windows`);
    return result.data;
  });

export const updateControllerVPNServer = async (
  controllerName: string,
  req: api.VPNServerUpsertRequest,
) =>
  mutateVoid(async () =>
    axiosInstanceJSON.put(`/v1/controllers/${controllerName}/vpn-servers`, req),
  );
