import type { CellProps, Column } from 'react-table';
import { ManufacturerIcon } from '@meterup/atto';
import {
  clientNameOrNull,
  expectDefinedOrThrow,
  getManufacturerIconName,
  isDefined,
  isDefinedAndNotEmpty,
  isGuest,
  lookupMACAddressOUI,
  NeutralBadge,
  ResourceNotFoundError,
  SignalStrengthBadge,
  WiredWirelessBadge,
} from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';

import type { NetworkClient } from '../../utils/clients';
import type { TabFilter } from '../AutoTable/TabFilters';
import { fetchClientsGql } from '../../api/clients_api';
import { fetchControllerJSON } from '../../api/controllers_api';
import { fetchAccessPointJSON } from '../../api/devices_api';
import { paths } from '../../constants';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import {
  FilterStrategy,
  getPredicateFromStrategy,
  isMeterAccessPoint,
  isMeterHardware,
  isMeterSwitch,
  isWired,
  isWireless,
} from '../../utils/clients';
import { makeDrawerLink } from '../../utils/makeLink';
import { AutoTable } from '../AutoTable/AutoTable';
import { TabFilters } from '../AutoTable/TabFilters';
import { Nav } from '../Nav';
import { DistanceToNowTimestampOrNull } from '../timestamps';

function Clients({ controllerName, deviceName }: { controllerName: string; deviceName?: string }) {
  const [filterStrategy, setFilterStrategy] = useState<FilterStrategy>(FilterStrategy.All);

  const controller = useQuery(
    ['controller', controllerName],
    () => fetchControllerJSON(controllerName),
    {
      suspense: true,
    },
  ).data;

  const accessPoint = useQuery(
    ['controller', controllerName, 'accessPoint', deviceName],
    () => fetchAccessPointJSON(controllerName, deviceName ?? ''),
    { suspense: true, refetchInterval: 60 * 1000 },
  ).data;

  expectDefinedOrThrow(
    controller,
    new ResourceNotFoundError(`Controller ${controllerName} not found`),
  );

  const allClients =
    useQuery(['clients', controllerName, deviceName], () =>
      fetchClientsGql(controller.network_uuid, undefined, accessPoint?.serial_number),
    ).data ?? [];

  const exportFileName = isDefinedAndNotEmpty(deviceName)
    ? `${controllerName}_${deviceName}_clients_export.csv`
    : `${controllerName}_clients_export.csv`;

  useDocumentTitle('Corporate Clients', controllerName);

  const columns = React.useMemo(
    (): Column<NetworkClient>[] => [
      {
        Header: 'Icon',
        accessor: (d) => getManufacturerIconName(lookupMACAddressOUI(d.macAddress!) ?? ''),

        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (row: CellProps<NetworkClient>) =>
          row.value ? <ManufacturerIcon icon={row.value} size={20} /> : null,
      },
      {
        Header: 'Name',
        accessor: (d) => clientNameOrNull(d),
      },
      {
        Header: 'IP',
        accessor: (d) => d.ip,
      },
      {
        Header: 'MAC',
        accessor: (d) => d.macAddress,
      },
      {
        Header: 'Connection',
        accessor: (d) => (isDefinedAndNotEmpty(d.apSerialNumber) ? 'wireless' : 'wired'),
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (row: CellProps<NetworkClient, 'wireless' | 'wired'>) => (
          <WiredWirelessBadge value={row.value} />
        ),
      },
      {
        Header: 'Access point',
        accessor: (d) => d.apLocation ?? d.apSerialNumber,
      },
      {
        Header: 'Channel',
        accessor: (d) => (isWireless(d) ? d.channel : null),
      },
      {
        Header: 'Signal',
        accessor: (d) => (isWireless(d) ? d.signal : null),
        Cell: SignalStrengthBadge,
      },
      {
        Header: 'VLAN',
        accessor: (d) => d.vlan,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (row: CellProps<NetworkClient>) =>
          row.value ? <NeutralBadge>{row.value}</NeutralBadge> : null,
      },
      {
        Header: 'Last seen',
        accessor: (d) => d.lastSeen,
        Cell: DistanceToNowTimestampOrNull,
      },
    ],
    [],
  );

  const filters: TabFilter<FilterStrategy>[] = [
    {
      key: FilterStrategy.All,
      label: 'All',
      count: allClients?.length,
    },
    {
      key: FilterStrategy.Wired,
      label: 'Wired',
      count: allClients?.filter(isWired).length,
    },
    {
      key: FilterStrategy.Wireless,
      label: 'Wireless',
      count: allClients?.filter(isWireless).length,
    },
    {
      key: FilterStrategy.Guest,
      label: 'Guest',
      count: allClients?.filter(isGuest).length,
    },
    {
      key: FilterStrategy.Meter,
      label: 'Hardware',
      count: allClients?.filter(isMeterHardware).length,
    },
    {
      key: FilterStrategy.MeterSwitch,
      label: 'Switch',
      count: allClients?.filter(isMeterSwitch).length,
    },
    {
      key: FilterStrategy.MeterAccessPoint,
      label: 'Access point',
      count: allClients?.filter(isMeterAccessPoint).length,
    },
  ];

  const filter = getPredicateFromStrategy(filterStrategy);
  const filteredClients = allClients?.filter(filter);

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.ClientDetail);

  const closeDrawer = useCloseDrawerCallback();

  return (
    <AutoTable<NetworkClient>
      columns={columns}
      data={filteredClients}
      tabs={
        <TabFilters
          filters={filters}
          activeFilterKey={filterStrategy}
          onActivateFilter={(f) => setFilterStrategy(f.key)}
        />
      }
      csvFileName={exportFileName}
      onRowDeselect={closeDrawer}
      isRowSelected={(row) => isDefined(drawerParams) && row.macAddress === drawerParams.mac}
      linkProps={(row) => ({
        to: makeDrawerLink(paths.drawers.ClientDetail, {
          controllerName,
          // TODO: Determine if lease is ever undefined?
          mac: row.macAddress ?? '',
        }),
      })}
    />
  );
}

export default Clients;
