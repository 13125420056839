import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { PaneContent } from '@meterup/atto';
import React from 'react';

import Stats from '../../../components/Stats/Stats';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/stats',
});

export default function ControllerStats() {
  return (
    <PaneContent>
      <Stats />
    </PaneContent>
  );
}
