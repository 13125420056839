import type { CompanyUsersForCompanyQueryType } from '@meterup/graphql';
import type { CellProps, Column } from 'react-table';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Badge, HStack, Link, Pane, PaneContent, PaneHeader, space } from '@meterup/atto';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  isDefined,
  ResourceNotFoundError,
  UserRoleBadge,
} from '@meterup/common';
import { useCompanyUsersGql } from '@meterup/graphql';
import { Link as RouterLink } from 'react-router-dom';

import { AutoTable } from '../../../components/AutoTable/AutoTable';
import { Nav } from '../../../components/Nav';
import { TimestampWithTimezone } from '../../../components/timestamps';
import { UserProfilePicture } from '../../../components/UserProfilePicture';
import { paths } from '../../../constants';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useCurrentTimezone } from '../../../providers/CurrentTimezoneProvider';
import { makeDrawerLink, makeLink } from '../../../utils/makeLink';
import { formatFullName } from '../../../utils/users';

export const Meta: PagefileMetaFn = () => ({
  path: '/companies/:companyName/users',
});

function CellUserEmail({ value }: CellProps<CompanyUsersForCompanyQueryType, string | null>) {
  return (
    <HStack spacing={space(8)}>
      <UserProfilePicture email={value || ''} /> {value}
    </HStack>
  );
}

function CellUserStatus({ value }: CellProps<CompanyUsersForCompanyQueryType, string | null>) {
  if (value) {
    return <Badge variant="neutral">Deleted</Badge>;
  }

  return <Badge variant="positive">Active</Badge>;
}

export default function CompanyUsers() {
  const timezone = useCurrentTimezone();

  const columns: Column<CompanyUsersForCompanyQueryType>[] = [
    {
      Header: 'Email',
      accessor: (row) => row.user.email,
      Cell: CellUserEmail,
    },
    {
      Header: 'Role',
      accessor: (row) => row.companyMembershipRole.toLowerCase(),
      Cell: UserRoleBadge,
    },
    {
      Header: 'Name',
      accessor: (row) => formatFullName(row.user),
    },
    {
      Header: 'Created at',
      accessor: (row) => row.createdAt,
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (props: CellProps<CompanyUsersForCompanyQueryType, string>) => (
        <TimestampWithTimezone value={props.value} timezone={timezone} />
      ),
    },
    {
      Header: 'State',
      accessor: (row) => row.deletedAt,
      Cell: CellUserStatus,
    },
  ];

  const { companyName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.CompanyUsers),
  );

  const companyUsers = useCompanyUsersGql(companyName);

  expectDefinedOrThrow(
    companyUsers,
    new ResourceNotFoundError(`Users for company ${companyName} not found`),
  );

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.UserDetail);

  const closeDrawer = useCloseDrawerCallback();

  return (
    <Pane>
      <PaneHeader
        heading={
          <HStack spacing={space(6)}>
            Users on company
            <Link
              as={RouterLink}
              to={Nav.makeTo({
                root: makeLink(paths.pages.CompaniesList, {}),
                drawer: makeLink(paths.drawers.CompanySummary, { companyName }),
              })}
            >
              {companyName}
            </Link>
          </HStack>
        }
      />
      <PaneContent>
        <AutoTable
          columns={columns}
          data={companyUsers}
          onRowDeselect={closeDrawer}
          isRowSelected={(row) => isDefined(drawerParams) && row.UUID === drawerParams.userSid}
          linkProps={(row) => ({
            to: makeDrawerLink(paths.drawers.UserDetail, {
              companyName,
              userSid: row.UUID,
            }),
          })}
        />
      </PaneContent>
    </Pane>
  );
}
