import {
  CompositeField,
  FieldContainer,
  PrimaryField,
  SecondaryField,
  SecondaryFieldComposite,
  SectionHeader,
  Select,
  SelectItem,
  Textarea,
  TextInput,
} from '@meterup/atto';
import { api } from '@meterup/proto';
import { useQuery } from '@tanstack/react-query';
import { useFormikContext } from 'formik';
import React from 'react';

import type { ValidPlanData } from './form_data';
import { getAllProviders, getNetworkInterfaces } from '../../../../../api/controllers_api';
import { FieldProvider } from '../../../../../components/FieldProvider';
import { formatISPProduct } from '../../../../../utils/isp_product';
import { formatISPStatus } from '../../../../../utils/isp_status';
import { NO_PROVIDER } from './form_data';

export function FormContent({ controllerName }: { controllerName: string }) {
  const form = useFormikContext<ValidPlanData>();

  const providers =
    useQuery(['providers'], getAllProviders, {
      suspense: true,
    }).data ?? [];

  const interfaces =
    useQuery(
      ['controller', controllerName, 'interfaces'],
      () => getNetworkInterfaces(controllerName),
      {
        suspense: true,
      },
    ).data ?? [];

  const interfaceOptions = [...interfaces].map((d) => ({ key: d.interface }));

  // Handle edge case where no interfaces are defined on the controller but the interface is set on the plan
  if (
    form.values.network_interface &&
    !interfaceOptions.find((i) => i.key === form.values.network_interface)
  ) {
    interfaceOptions.unshift({ key: form.values.network_interface });
  }

  return (
    <>
      <FieldContainer>
        <SectionHeader heading="Plan details" />
        <FieldProvider name="provider">
          <SecondaryField
            label="Provider"
            element={
              <Select items={[{ sid: NO_PROVIDER, name: 'Select a provider' }, ...providers]}>
                {providers.map((p) => (
                  <SelectItem key={p.sid}>{p.name}</SelectItem>
                ))}
              </Select>
            }
          />
        </FieldProvider>
        <FieldProvider name="product">
          <SecondaryField
            label="Product"
            element={
              <Select disabledKeys={[api.ISPProduct.IR_UNKNOWN]}>
                {Object.values(api.ISPProduct).map((product) => (
                  <SelectItem key={product}>{formatISPProduct(product)}</SelectItem>
                ))}
              </Select>
            }
          />
        </FieldProvider>
        <FieldProvider name="status">
          <SecondaryField
            label="Status"
            element={
              <Select disabledKeys={[api.ISPStatus.IS_UNKNOWN]}>
                {Object.values(api.ISPStatus).map((status) => (
                  <SelectItem key={status}>{formatISPStatus(status)}</SelectItem>
                ))}
              </Select>
            }
          />
        </FieldProvider>
        <FieldProvider name="account_number">
          <SecondaryField label="Account Number" element={<TextInput />} />
        </FieldProvider>
        <SecondaryFieldComposite
          label="Bandwidth"
          fields={
            <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
              <FieldProvider name="download_mbps">
                <CompositeField
                  label="Download"
                  element={<TextInput icon="download" suffix="Mb/s" />}
                />
              </FieldProvider>
              <FieldProvider name="upload_mbps">
                <CompositeField
                  label="Upload"
                  element={<TextInput icon="upload" suffix="Mb/s" />}
                />
              </FieldProvider>
            </div>
          }
        />
        <FieldProvider name="monthly_cost_dollars">
          <SecondaryField label="Price" element={<TextInput prefix="USD" suffix="monthly" />} />
        </FieldProvider>
      </FieldContainer>

      <FieldProvider name="notes">
        <PrimaryField
          label="Notes"
          description="Now accepting newlines! Smash that return key."
          element={<Textarea />}
        />
      </FieldProvider>

      <FieldProvider name="ip_assignment">
        <PrimaryField
          label="IP assignment"
          description="We no longer allow editing the IP assignment here. Please update it in the config, so it is kept up to date with what is actually on the controller."
          element={null}
        />
      </FieldProvider>

      <FieldProvider name="network_interface">
        <PrimaryField
          label="Network interface"
          element={
            <Select
              value={form.values.network_interface ?? interfaces[0].interface}
              items={interfaceOptions}
            >
              {({ key }) => <SelectItem key={key}>{key}</SelectItem>}
            </Select>
          }
        />
      </FieldProvider>
    </>
  );
}
