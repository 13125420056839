import { Link, Title } from '@meterup/atto';
import React from 'react';
import { useParams } from 'react-router-dom';

import { colors } from '../../stitches';
import { getGrafanaLogsURL, getGrafanaURL } from '../../utils/grafana';

function Stats() {
  const { controllerName } = useParams<any>();
  return (
    <div style={{ margin: '20px' }}>
      <Title>
        <Link
          href={getGrafanaURL(controllerName)}
          target="_blank"
          style={{ color: colors.brand600.toString() }}
        >
          📈 Grafana
        </Link>
      </Title>
      {controllerName && (
        <Title>
          <Link
            href={getGrafanaLogsURL(controllerName)}
            target="_blank"
            style={{ color: colors.brand600.toString() }}
          >
            📈 Logs
          </Link>
        </Title>
      )}
    </div>
  );
}

export default Stats;
