import type { PropsOf } from '@meterup/atto/src/utilities/types/polymorphicAsProp';
import type { MouseEventHandler } from 'react';
import {
  type LightOrDark,
  Body,
  colors,
  fontWeights,
  isLightOrDark,
  Logo,
  Small,
  styled,
} from '@meterup/atto';
import { fade, palette } from '@meterup/atto/src/common/colors';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { CaptivePortal as CaptivePortalGQLType } from '../gql/graphql';
import { CaptivePortalDefaults } from '../helpers/utils/captivePortal';

const PortalLogo = styled('img', {
  width: '200px',
  height: '120px',
  objectFit: 'contain',
});

const PortalBrandedLabel = styled(Small, {
  whiteSpace: 'nowrap',
  variants: {
    recolor: {
      light: {
        color: `${colors.bodyNeutralLight} !important`,
      },
      dark: {
        color: `${colors.white} !important`,
      },
      unrecognized: {
        color: `${colors.bodyNeutralLight} !important`,
      },
    },
  },
});

const PortalBrandedLogo = styled(Logo, {
  marginBottom: '2px',

  variants: {
    recolor: {
      light: {
        fill: `${colors.black} !important`,
      },
      dark: {
        fill: `${colors.white} !important`,
      },
      unrecognized: {
        fill: `${colors.black} !important`,
      },
    },
  },
});

const PortalBranded = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
  gap: '$6',
});

const PortalHeader = styled('div', {
  vStack: '$12',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',

  '@notMobile': {
    padding: '$40 $40 0',
  },
});

const PortalTerms = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  width: '100%',
  maxHeight: '100%',
  padding: '$20 0',
  overflow: 'auto',

  variants: {
    recolor: {
      light: {
        strokeTopBottom: fade(palette.black, 0.25),
      },
      dark: {
        strokeTopBottom: fade(palette.white, 0.25),
      },
      unrecognized: {
        strokeTopBottom: fade(palette.black, 0.25),
      },
    },
  },
});

const PortalButtonLabel = styled(Body, {
  fontWeight: fontWeights.bold,
});

const PortalButton = styled('a', {
  display: 'block',
  width: '100%',
  padding: '$10 $12',
  borderRadius: '12px',
  textAlign: 'center',
  textDecoration: 'none',
  transition: 'background-color 0.2s ease-in-out',

  variants: {
    recolor: {
      light: {
        strokeAll: fade(palette.black, 0.25),
      },
      dark: {
        strokeAll: fade(palette.white, 0.25),
      },
      unrecognized: {
        strokeAll: fade(palette.black, 0.25),
      },
    },
  },
});

const PortalFooter = styled('div', {
  vStack: '$12',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',

  '@notMobile': {
    padding: '0 $40 $40',
  },
});

const PortalContent = styled('div', {
  vStack: '$20',
  alignItems: 'center',
  width: '100%',
  maxWidth: '400px',
  height: '100%',

  '@notDesktop': {
    padding: '$20',
  },

  '@desktop': {
    padding: '5% 0',
  },
});

export const PortalWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  variants: {
    relation: {
      nested: {
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        maxHeight: '100%',
        minHeight: '100%',
      },
      standalone: {
        width: '100vw',
        maxWidth: '100vw',
        minWidth: '100vw',
        height: '100vh',
        maxHeight: '100vh',
        minHeight: '100vh',
      },
    },
  },
});

type CaptivePortalData = Pick<
  CaptivePortalGQLType,
  | 'callToAction'
  | 'displaySettings'
  | 'redirectURL'
  | 'termsAndConditions'
  | 'logoImageURL'
  | 'logoImageBase64'
>;

type CaptivePortalProps = {
  captivePortal: CaptivePortalData;
  confirmLink?: string;
  relation: 'nested' | 'standalone';
};

export function CaptivePortal({ captivePortal, confirmLink, relation }: CaptivePortalProps) {
  const lightOrDarkBackground = useMemo((): LightOrDark => {
    if (!captivePortal.displaySettings?.backgroundColor) {
      return 'light';
    }
    const result = isLightOrDark(captivePortal.displaySettings.backgroundColor);
    return result === 'unrecognized' ? 'light' : result; // Default to 'light' if 'unrecognized'
  }, [captivePortal]);

  const fontColor = useMemo(() => {
    if (!captivePortal.displaySettings?.fontColor) {
      return undefined;
    }
    return `${captivePortal.displaySettings.fontColor} !important`;
  }, [captivePortal.displaySettings?.fontColor]);

  const callToActionColor = useMemo(() => {
    if (!captivePortal.displaySettings?.callToActionColor) {
      return undefined;
    }
    return `${captivePortal.displaySettings.callToActionColor} !important`;
  }, [captivePortal.displaySettings?.callToActionColor]);

  const imageSource = useMemo(() => {
    if (captivePortal.logoImageBase64) {
      const { imageType, data } = captivePortal.logoImageBase64;
      let imageContentType = imageType.toLowerCase();
      if (imageType === 'SVG') {
        imageContentType = 'svg+xml';
      }
      return `data:image/${imageContentType};base64,${data}`;
    }
    return captivePortal.logoImageURL ?? CaptivePortalDefaults.logoImageURL;
  }, [captivePortal]);

  const poweredByProps: Pick<PropsOf<typeof PortalBrandedLabel>, 'css' | 'recolor'> = {
    css: captivePortal.displaySettings?.fontColor ? { color: fontColor } : undefined,
    recolor: captivePortal.displaySettings?.fontColor ? undefined : lightOrDarkBackground,
  };

  const buttonLabelProps: Pick<PropsOf<typeof PortalButton>, 'css' | 'recolor'> = {
    css: captivePortal.displaySettings?.callToActionColor
      ? { color: callToActionColor }
      : undefined,
    recolor: captivePortal.displaySettings?.callToActionColor ? undefined : lightOrDarkBackground,
  };

  const [searchParams] = useSearchParams();
  const onClickConfirm = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      if (!confirmLink || searchParams.get('preview') === '1') {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [confirmLink, searchParams],
  );

  return (
    <PortalWrapper
      relation={relation}
      css={{
        backgroundColor: captivePortal.displaySettings?.backgroundColor ?? undefined,
      }}
    >
      <PortalContent>
        <PortalHeader>
          <PortalLogo alt="company logo" src={imageSource} crossOrigin="anonymous" />
        </PortalHeader>
        {captivePortal.termsAndConditions && (
          <PortalTerms recolor={lightOrDarkBackground}>
            <Small weight="bold" css={{ color: fontColor }}>
              Terms & conditions
            </Small>
            <Small whitespace="pre-wrap" css={{ color: fontColor }}>
              {captivePortal.termsAndConditions}
            </Small>
          </PortalTerms>
        )}
        <PortalFooter>
          <PortalButton
            recolor={lightOrDarkBackground}
            onClick={onClickConfirm}
            href={confirmLink ?? '#'}
          >
            <PortalButtonLabel {...buttonLabelProps}>
              {captivePortal.callToAction ?? 'Accept terms & conditions'}
            </PortalButtonLabel>
          </PortalButton>
          <PortalBranded>
            <PortalBrandedLabel {...poweredByProps}>Powered by</PortalBrandedLabel>
            <PortalBrandedLogo recolor={lightOrDarkBackground} height={4} variant="full" />
          </PortalBranded>
        </PortalFooter>
      </PortalContent>
    </PortalWrapper>
  );
}
