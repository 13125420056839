import { useGraphQL } from '@meterup/graphql';
import { useQuery } from '@tanstack/react-query';

import { fetchControllerJSON } from '../api/controllers_api';
import { graphql } from '../gql';

export enum NosFeature {
  SOS = 'v1.config2.sos',
  WOS2 = 'v1.config2.wos',
  COS2 = 'v1.config2.cos',
}

export const IsNosFeatureEnabledForNetworkQuery = graphql(`
  query IsNosFeatureEnabledForNetwork($networkUUID: UUID!, $nosFeatureKey: String!) {
    isNosFeatureEnabledForNetwork(networkUUID: $networkUUID, nosFeatureKey: $nosFeatureKey)
  }
`);

export const useNosFeatureEnabled = (controllerName: string, nosFeatureKey: string) => {
  const controller = useQuery(
    ['controller', controllerName, 'json'],
    () => fetchControllerJSON(controllerName),
    {
      suspense: true,
    },
  ).data;

  const networkUUID = controller?.network_uuid;
  return useGraphQL(
    IsNosFeatureEnabledForNetworkQuery,
    {
      networkUUID: networkUUID as string,
      nosFeatureKey,
    },
    {
      enabled: !!networkUUID,
      useErrorBoundary: false,
    },
  ).data?.isNosFeatureEnabledForNetwork;
};
