import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Alert, Drawer, DrawerContent, DrawerHeader } from '@meterup/atto';
import { checkDefinedOrThrow, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { GraphQLResult, useCompanyUserGql } from '@meterup/graphql';
import React from 'react';

import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { formatFullName } from '../../../../utils/users';
import { UserNameAndEmailWidget } from './common/UserNameAndEmailWidget';
import { UserProfileAndPermissionsWidget } from './common/UserProfileAndPermissionsWidget';

export const Meta: PagefileMetaFn = () => ({
  path: '/companies/:companyName/users/:userSid',
});

export default function UserDetail() {
  const closeDrawer = useCloseDrawerCallback();

  const { userSid } = checkDefinedOrThrow(Nav.useRegionParams('drawer', paths.drawers.UserDetail));

  const companyUser = useCompanyUserGql(userSid);

  if (companyUser === GraphQLResult.Error) {
    expectDefinedOrThrow(companyUser, new ResourceNotFoundError(`User ${userSid} not found`));
    return null;
  }

  if (companyUser === GraphQLResult.Loading || !companyUser) {
    return null;
  }

  const formattedName = formatFullName(companyUser.user);

  return (
    <Drawer>
      <DrawerHeader heading="User" onClose={closeDrawer} />

      <DrawerContent>
        {!formattedName && (
          <Alert
            icon="attention"
            heading="Pending details"
            copy="This user's profile information will sync when they first sign in using SSO."
          />
        )}
        <UserNameAndEmailWidget user={companyUser.user} />
        <UserProfileAndPermissionsWidget user={companyUser.user} />
      </DrawerContent>
    </Drawer>
  );
}
