import {
  Alert,
  Button,
  Section,
  SectionContent,
  SectionHeader,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';
import { NeutralBadge } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import type { ControllerUpgradeManagerConfig } from '../api/types';
import { fetchControllerConfig, fetchControllerState } from '../api/controllers_api';
import { paths } from '../constants';
import {
  formatControllerVersionFromConfig,
  formatControllerVersionFromState,
} from '../utils/controllerVersion';
import { makeDrawerLink } from '../utils/makeLink';
import { NosFeature, useNosFeatureEnabled } from '../utils/nosfeature';

export function FirmwareUpdatesWidget({ controllerName }: { controllerName: string }) {
  const config = useQuery(
    ['controller', controllerName, 'config'],
    async () => fetchControllerConfig(controllerName),
    { suspense: true },
  ).data?.config as Record<string, unknown>;

  const [versionConfig] = formatControllerVersionFromConfig(
    config['meter.v1.upgrade-manager'] as ControllerUpgradeManagerConfig,
  );

  const controllerState = useQuery(
    ['controller', controllerName, 'state'],
    () => fetchControllerState(controllerName),
    { suspense: true },
  ).data;

  const editFirmwareUpdatesLink = makeDrawerLink(paths.drawers.EditFirmwareUpdates, {
    controllerName,
  });

  const [versionState, parsedVersionState] = formatControllerVersionFromState(controllerState);
  const isCOS2Enabled = useNosFeatureEnabled(controllerName, NosFeature.COS2);
  if (isCOS2Enabled) {
    return (
      <Section relation="standalone">
        <SectionHeader
          heading="Firmware updates (Use Dashboard)"
          icon="upgrading"
          actions={
            <NeutralBadge ends="card" variant="brand">
              COS Config 2.0
            </NeutralBadge>
          }
        />
      </Section>
    );
  }
  // TODO(adam): add past firmware updates for controller
  return (
    <Section relation="standalone">
      <SectionHeader
        icon="upgrading"
        heading="Firmware updates (will be deprecated soon)"
        actions={
          <Button as={RouterLink} to={editFirmwareUpdatesLink} variant="secondary" size="small">
            Edit
          </Button>
        }
      />
      <SectionContent>
        {versionState !== versionConfig && (
          <Alert
            icon="attention"
            variant="neutral"
            relation="stacked"
            heading="Firmware update pending"
            copy={`Controller currently upgrading to ${versionConfig}`}
          />
        )}
        <SummaryList>
          {parsedVersionState.length > 0 && (
            <SummaryListRow>
              <SummaryListKey>Version</SummaryListKey>
              <SummaryListValue>{parsedVersionState}</SummaryListValue>
            </SummaryListRow>
          )}
          {versionState.length > 0 && (
            <SummaryListRow>
              <SummaryListKey>Build string</SummaryListKey>
              <SummaryListValue>{versionState}</SummaryListValue>
            </SummaryListRow>
          )}
        </SummaryList>
      </SectionContent>
    </Section>
  );
}
