import type { api } from '@meterup/proto';
import {
  Alert,
  Button,
  Link,
  Section,
  SectionContent,
  SectionHeader,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';
import { checkDefinedOrThrow } from '@meterup/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { updateIncident } from '../../api/controllers_api';
import { paths } from '../../constants';
import { useCurrentTimezone } from '../../providers/CurrentTimezoneProvider';
import { CATEGORY_INSTALLATION } from '../../routes/drawers/controllers/incidents/common/form_data';
import { styled } from '../../stitches';
import { makeDrawerLink } from '../../utils/makeLink';
import { CopyToClipboardButtonMinimal } from '../CopyToClipboardButton';
import {
  CurrentLocalTime,
  StringToDateTime,
  TimestampWithTimezone,
  TimezoneAbbreviation,
} from '../timestamps';
import { IncidentCategoryBadge } from './IncidentCategoryBadge';

const BottomRoundedAlert = styled(Alert, {
  borderRadiusBottom: '$8',
});

export default function IncidentDetailContent({
  controllerName,
  incident,
}: {
  incident: api.ControllerIncident;
  controllerName: string;
}) {
  const queryClient = useQueryClient();
  const sid = checkDefinedOrThrow(incident.sid);
  const timezone = useCurrentTimezone();
  // Ignore local dev case
  const tld = import.meta.env.REALM === 'dev' ? 'dev' : 'com';
  const installerAppLink: string = `https://install.meter.${tld}/${incident.sid}`;

  const tz = TimezoneAbbreviation(timezone);

  const now = CurrentLocalTime();
  const isOngoing = useMemo(() => {
    const start = StringToDateTime(incident.start_time ?? '');
    const end = StringToDateTime(incident.end_time ?? '');
    return start <= now && now <= end;
  }, [now, incident.start_time, incident.end_time]);

  const resolveIncidentMutation = useMutation(
    async () =>
      updateIncident(controllerName, incident.sid, {
        start_time: incident.start_time,
        end_time: CurrentLocalTime().toISO(),
        notes: incident.notes,
        category: incident.category,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controller', controllerName, 'incidents']);
      },
    },
  );

  return (
    <>
      <Section relation="standalone">
        <SectionHeader
          icon="clock"
          heading="Duration"
          actions={
            <Button
              as={ReactRouterLink}
              to={makeDrawerLink(paths.drawers.IncidentEditDuration, {
                controllerName,
                id: sid,
              })}
              variant="secondary"
              size="small"
            >
              Edit
            </Button>
          }
        />
        <SectionContent>
          <SummaryList>
            <SummaryListRow>
              <SummaryListKey>Start time ({tz})</SummaryListKey>
              <SummaryListValue>
                <TimestampWithTimezone value={incident.start_time || ''} timezone={timezone} />
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>End time ({tz})</SummaryListKey>
              <SummaryListValue>
                <TimestampWithTimezone value={incident.end_time || ''} timezone={timezone} />
              </SummaryListValue>
            </SummaryListRow>
          </SummaryList>

          {isOngoing && (
            <BottomRoundedAlert
              relation="stacked"
              shoulderButtons={
                <Button
                  onClick={() => {
                    resolveIncidentMutation.mutate();
                  }}
                >
                  Mark as resolved
                </Button>
              }
              variant="negative"
              copy="This incident is ongoing."
            />
          )}
        </SectionContent>
      </Section>

      <Section relation="standalone">
        <SectionHeader
          icon="information"
          heading="Details"
          actions={
            <Button
              as={ReactRouterLink}
              to={makeDrawerLink(paths.drawers.IncidentEditDetails, {
                controllerName,
                id: sid,
              })}
              variant="secondary"
              size="small"
            >
              Edit
            </Button>
          }
        />
        <SectionContent>
          <SummaryList>
            <SummaryListRow>
              <SummaryListKey>Category</SummaryListKey>
              <SummaryListValue>
                <IncidentCategoryBadge category={incident.category || ''} />
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>Notes</SummaryListKey>
              <SummaryListValue>{incident.notes}</SummaryListValue>
            </SummaryListRow>
            {incident.category === CATEGORY_INSTALLATION && (
              <SummaryListRow>
                <SummaryListKey>
                  <Link href={installerAppLink} target="_blank">
                    Installer App Link
                  </Link>
                </SummaryListKey>
                <SummaryListValue>
                  <CopyToClipboardButtonMinimal text={installerAppLink} />
                </SummaryListValue>
              </SummaryListRow>
            )}
          </SummaryList>
        </SectionContent>
      </Section>

      <Section relation="standalone">
        <SectionHeader heading="History" />
        <SectionContent>
          <SummaryList>
            <SummaryListRow>
              <SummaryListKey>Created at ({tz})</SummaryListKey>
              <SummaryListValue>
                <TimestampWithTimezone value={incident.created_at || ''} timezone={timezone} />
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>Updated at ({tz})</SummaryListKey>
              <SummaryListValue>
                <TimestampWithTimezone value={incident.updated_at || ''} timezone={timezone} />
              </SummaryListValue>
            </SummaryListRow>
            {incident.created_by_email && (
              <SummaryListRow>
                <SummaryListKey>Created by</SummaryListKey>
                <SummaryListValue>{incident.created_by_email}</SummaryListValue>
              </SummaryListRow>
            )}
          </SummaryList>
        </SectionContent>
      </Section>
    </>
  );
}
