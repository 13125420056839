import type {
  DropdownMenuCheckboxItemProps as RadixDropdownMenuCheckboxItemProps,
  DropdownMenuContentProps as RadixDropdownMenuPopoverProps,
  DropdownMenuItemProps as RadixDropdownMenuItemProps,
  DropdownMenuRadioGroupProps as RadixDropdownMenuRadioGroupProps,
  DropdownMenuRadioItemProps as RadixDropdownMenuRadioItemProps,
} from '@radix-ui/react-dropdown-menu';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { forwardRef, useState } from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type { ButtonProps } from '../../controls/Button/Button';
import type { ShortcutKeys } from '../../formatting/Shortcut/Shortcut';
import { Icon } from '../../assets/Icon/Icon';
import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from '../../common/animations';
import { zIndexes } from '../../common/z_indexes';
import { Button } from '../../controls/Button/Button';
import Shortcut from '../../formatting/Shortcut/Shortcut';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { Small } from '../../text/Small';

export const DropdownMenuTrigger = RadixDropdownMenu.Trigger;

export const DropdownMenuButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ menuArrow = 'dropdown', size, variant = 'secondary', ...remaining }, forwardedRef) => (
    <RadixDropdownMenu.Trigger asChild>
      <Button
        {...remaining}
        ref={forwardedRef}
        size={size}
        variant={variant}
        menuArrow={menuArrow}
      />
    </RadixDropdownMenu.Trigger>
  ),
);

const DropdownMenuItemIcon = styled(Icon, {
  width: '$14',
  height: '$14',
  color: '$$iconColor',

  variants: {
    internal: {
      true: {
        color: '$$internalIconColor',
      },
      false: {},
    },
  },
});

const DropdownMenuItemLabel = styled(Body, {
  display: 'flex',
  alignItems: 'center',
  gap: '$6',
  width: '100%',
  fontWeight: fontWeights.bold,
  color: '$$labelColor',

  variants: {
    internal: {
      true: {
        color: '$$internalLabelColor',
      },
      false: {},
    },
  },
});

const dropdownMenuItemContainer = {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$6',
  padding: '$4 $8',
  background: '$$backgroundColor',
  borderRadius: '$8',
  cursor: 'pointer',
  $$iconColor: colors.iconNeutralLight,
  $$labelColor: colors.bodyNeutralLight,

  [darkThemeSelector]: {
    $$iconColor: colors.iconNeutralDark,
    $$labelColor: colors.bodyNeutralDark,
  },

  '&[data-highlighted]': {
    zIndex: 3,
    outline: 'none',
    boxShadow: shadows.focusRingLight,
    $$backgroundColor: colors.bgNeutralLight,
    $$iconColor: colors.bodyNeutralLight,
    $$labelColor: colors.headingNeutralLight,

    [darkThemeSelector]: {
      boxShadow: shadows.focusRingDark,
      $$backgroundColor: colors.bgNeutralDark,
      $$iconColor: colors.bodyNeutralDark,
      $$labelColor: colors.headingNeutralDark,
    },
  },

  '&[aria-disabled="true"]': {
    opacity: 0.5,
    cursor: 'not-allowed',
  },

  '&[aria-checked="true"]': {
    zIndex: 2,
    $$backgroundColor: colors.bgBrandLight,
    $$iconColor: colors.iconBrandLight,
    $$labelColor: colors.bodyBrandLight,

    [darkThemeSelector]: {
      $$backgroundColor: colors.bgBrandDark,
      $$iconColor: colors.iconBrandDark,
      $$labelColor: colors.bodyBrandDark,
    },

    '&[data-highlighted]': {
      $$backgroundColor: colors.bgBrandLight,
      $$iconColor: colors.bodyBrandLight,
      $$labelColor: colors.headingBrandLight,

      [darkThemeSelector]: {
        $$backgroundColor: colors.bgBrandDark,
        $$iconColor: colors.bodyBrandDark,
        $$labelColor: colors.headingBrandDark,
      },
    },
  },

  variants: {
    internal: {
      true: {
        $$internalIconColor: colors.internalIconLight,
        $$internalLabelColor: colors.internalBodyLight,

        [darkThemeSelector]: {
          $$internalIconColor: colors.internalIconDark,
          $$internalLabelColor: colors.internalBodyDark,
        },

        '&[data-highlighted]': {
          $$internalIconColor: colors.internalBodyLight,
          $$internalLabelColor: colors.internalHeadingLight,

          [darkThemeSelector]: {
            $$internalIconColor: colors.internalBodyDark,
            $$internalLabelColor: colors.internalHeadingDark,
          },
        },
      },
    },
  },
};

type DropdownMenuItemInnerProps = {
  icon?: IconName;
  internal?: boolean;
  label?: React.ReactNode;
  shortcut?: ShortcutKeys[];
};

function DropdownMenuItemInner({ icon, internal, label, shortcut }: DropdownMenuItemInnerProps) {
  return (
    <>
      {icon && <DropdownMenuItemIcon internal={internal} icon={icon} />}
      {label && <DropdownMenuItemLabel internal={internal}>{label}</DropdownMenuItemLabel>}
      {shortcut && <Shortcut internal={internal} keys={shortcut} />}
    </>
  );
}

export type DropdownMenuItemProps = RadixDropdownMenuItemProps & DropdownMenuItemInnerProps;

const DropdownMenuItemContainer = styled(RadixDropdownMenu.Item, {
  ...dropdownMenuItemContainer,
});

export const DropdownMenuItem = forwardRef<
  HTMLDivElement,
  RadixDropdownMenuItemProps & DropdownMenuItemInnerProps
>(({ asChild, children, icon, internal, label, shortcut, ...remaining }, forwardedRef) => (
  <DropdownMenuItemContainer
    internal={internal}
    ref={forwardedRef}
    {...remaining}
    asChild={asChild}
  >
    <DropdownMenuItemInner
      icon={icon}
      internal={internal}
      label={label || children}
      shortcut={shortcut}
    />
  </DropdownMenuItemContainer>
));

const DropdownMenuCheckboxItemContainer = styled(RadixDropdownMenu.CheckboxItem, {
  ...dropdownMenuItemContainer,
});

export const DropdownMenuCheckboxItem = forwardRef<
  HTMLDivElement,
  RadixDropdownMenuCheckboxItemProps & DropdownMenuItemInnerProps
>(({ checked, children, icon, internal, label, shortcut, ...remaining }, forwardedRef) => {
  const [checkedState, setCheckedState] = useState(checked);

  return (
    <DropdownMenuCheckboxItemContainer
      onCheckedChange={() => setCheckedState(!checkedState)}
      checked={checkedState}
      internal={internal}
      ref={forwardedRef}
      {...remaining}
    >
      <DropdownMenuItemInner
        icon={checkedState ? 'checkmark' : icon}
        internal={internal}
        label={label || children}
        shortcut={shortcut}
      />
    </DropdownMenuCheckboxItemContainer>
  );
});

const DropdownMenuRadioItemContainer = styled(RadixDropdownMenu.RadioItem, {
  ...dropdownMenuItemContainer,
});

export const DropdownMenuRadioItem = forwardRef<
  HTMLDivElement,
  RadixDropdownMenuRadioItemProps & DropdownMenuItemInnerProps
>(({ children, icon, internal, label, shortcut, ...remaining }, forwardedRef) => (
  <DropdownMenuRadioItemContainer internal={internal} ref={forwardedRef} {...remaining}>
    <DropdownMenuItemInner
      icon={icon}
      internal={internal}
      label={label || children}
      shortcut={shortcut}
    />
  </DropdownMenuRadioItemContainer>
));

const DropdownMenuLabel = styled(RadixDropdownMenu.Label, Small, {
  color: colors.controlContentPlaceholderLight,
  fontWeight: fontWeights.bold,
  padding: '$4 $8',

  [darkThemeSelector]: {
    color: colors.controlContentPlaceholderDark,
  },
});

const dropdownMenuGroupContainer = {
  display: 'flex',
  flexDirection: 'column',
  padding: '$6',

  '&:not(:last-child)': {
    strokeBottom: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      strokeBottom: colors.strokeNeutralDark,
    },
  },
};

const DropdownMenuGroupContainer = styled(RadixDropdownMenu.Group, {
  ...dropdownMenuGroupContainer,
});

type DropdownMenuGroupProps = {
  children: React.ReactNode;
  label?: React.ReactNode;
};

export function DropdownMenuGroup({
  children,
  label,
}: {
  children: React.ReactNode;
  label?: React.ReactNode;
}) {
  return (
    <DropdownMenuGroupContainer>
      {label && <DropdownMenuLabel>{label}</DropdownMenuLabel>}
      {children}
    </DropdownMenuGroupContainer>
  );
}

const DropdownMenuRadioGroupContainer = styled(RadixDropdownMenu.RadioGroup, {
  ...dropdownMenuGroupContainer,
});

export function DropdownMenuRadioGroup({
  children,
  label,
  ...remaining
}: RadixDropdownMenuRadioGroupProps & DropdownMenuGroupProps) {
  return (
    <DropdownMenuRadioGroupContainer {...remaining}>
      {label && <DropdownMenuLabel>{label}</DropdownMenuLabel>}
      {children}
    </DropdownMenuRadioGroupContainer>
  );
}

const DropdownMenuPopoverContainer = styled(RadixDropdownMenu.Content, {
  zIndex: zIndexes.overlay,
  minWidth: '$180',
  maxHeight: '96vh',
  overflow: 'auto',
  background: colors.bgApplicationLight,
  boxShadow: shadows.overlayLight,
  borderRadius: '$10',

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    boxShadow: shadows.overlayDark,
  },

  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '150ms',
    animationTimingFunction: 'ease-out',
    animationFillMode: 'forwards',
    willChange: 'transform, opacity',

    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideUpAndFade },
      '&[data-side="right"]': { animationName: slideRightAndFade },
      '&[data-side="bottom"]': { animationName: slideDownAndFade },
      '&[data-side="left"]': { animationName: slideLeftAndFade },
    },
  },
});

export const DropdownMenuPopover = forwardRef<HTMLDivElement, RadixDropdownMenuPopoverProps>(
  ({ children, align, ...props }, forwardedRef) => (
    <RadixDropdownMenu.Portal>
      <DropdownMenuPopoverContainer align={align} sideOffset={4} {...props} ref={forwardedRef}>
        {children}
      </DropdownMenuPopoverContainer>
    </RadixDropdownMenu.Portal>
  ),
);

export const DropdownMenu = RadixDropdownMenu.Root;
