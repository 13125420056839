import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Body, space, VStack } from '@meterup/atto';
import { checkDefinedOrThrow } from '@meterup/common';
import { useGraphQLMutation } from '@meterup/graphql';
import React, { useEffect } from 'react';

import { Nav } from '../../components/Nav';
import { Page, PageHeader, PageTitle } from '../../components/Page';
import { paths } from '../../constants';
import { graphql } from '../../gql';
import { styled } from '../../stitches';

export const Meta: PagefileMetaFn = () => ({
  path: '/cliv2/:uuid',
  name: 'CLIv2',
});

const Container = styled('div', {
  alignItems: 'center',
  height: 'calc(100vh - 200px)',
  display: 'flex',
  justifyContent: 'center',
});

const validateBearerSessionDoc = graphql(`
  mutation validateBearerSession($UUID: UUID!) {
    validateBearerSession(UUID: $UUID) {
      UUID
    }
  }
`);

export default function CLI() {
  const { uuid } = checkDefinedOrThrow(Nav.useRegionParams('root', paths.pages.CLIv2));

  const validateBearerSession = useGraphQLMutation(validateBearerSessionDoc, {});

  useEffect(() => {
    validateBearerSession.mutate({ UUID: uuid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);
  return (
    <Page>
      <PageHeader>
        <PageTitle>CLI Login</PageTitle>
      </PageHeader>
      <Container>
        <VStack align="center" justify="center" spacing={space(16)}>
          {validateBearerSession.isLoading && <Body>Loading...</Body>}
          {validateBearerSession.isSuccess && (
            <Body>CLI session created, you can now close this tab.</Body>
          )}
        </VStack>
      </Container>
    </Page>
  );
}
