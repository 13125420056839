import { Command } from '@meterup/command';
import { isDefined, Notifications, ResourceNotFoundError } from '@meterup/common';
import { getGraphQLError, GraphQLExtensionErrorCode } from '@meterup/graphql';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from '@sentry/react';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';
import { OverlayProvider } from 'react-aria';
import { Navigate, useLocation } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import pagefileRoutes from 'virtual:pagefiles/pages';

import { getIdentity } from './api/api';
import { FatalErrorFallback } from './components/error_fallbacks';
import { Nav } from './components/Nav';
import { OverlayableContainer, overlayableRootCSS } from './components/overlays';
import { useValidateFeatureFlags } from './hooks/useValidateFeatureFlags';
import { AppLayout } from './layouts/AppLayout';
import { CurrentTimezoneProvider } from './providers/CurrentTimezoneProvider';
import { colors, darkThemeSelector, fonts, fontWeights, globalCss, styled } from './stitches';

function retry(count: number, error: unknown) {
  if (error instanceof ResourceNotFoundError) {
    return false;
  }

  if (error instanceof Error) {
    const graphqlError = getGraphQLError(error);
    return graphqlError?.extensions?.code !== GraphQLExtensionErrorCode.Unauthorized;
  }

  return count < 3;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: import.meta.env.REALM === 'local' ? 'always' : undefined,
      retry,
    },
    mutations: {
      networkMode: import.meta.env.REALM === 'local' ? 'always' : undefined,
    },
  },
});

const injectGlobalStyles = globalCss({
  'html, body, #root': {
    height: '100%',
    width: '100%',
    overscrollBehaviorY: 'none',
    position: 'fixed',
    overflow: 'hidden',
  },
  body: {
    background: colors.bgApplicationLight,
    fontSize: 16,
    fontFamily: fonts.sans,
    fontWeight: fontWeights.regular,
    [darkThemeSelector]: {
      background: colors.bgApplicationDark,
    },
    // Enable improved font rendering only on high-DPI displays
    '@media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 13/10), only screen and (min-resolution: 120dpi)':
      {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
      },
  },
  '#root': overlayableRootCSS,
});

const StyledOverlayProvider = styled(OverlayProvider, OverlayableContainer);

function NOCApp() {
  const PagefileRoutes = Nav.useRoutes('root', pagefileRoutes);

  return <AppLayout>{PagefileRoutes && PagefileRoutes}</AppLayout>;
}

function IdentityCheck() {
  const returnPath = window.location.pathname;
  const identity = useQuery(['identity'], getIdentity, { suspense: false });

  const LDClient = useLDClient();

  useEffect(() => {
    if (isDefined(LDClient)) {
      if (isDefined(identity.data)) {
        LDClient.identify({
          key: identity.data.id.toString(),
          email: identity.data.username,
        });
      } else {
        LDClient.identify({
          anonymous: true,
        });
      }
    }
  }, [LDClient, identity]);

  useEffect(() => {
    const isCookiePresent = document.cookie.match(/^(.*;)?\s*noc.frontend.v2\s*=\s*[^;]+(.*)?$/);
    if (!isCookiePresent || identity.error) {
      window.location.href = `${import.meta.env.REACT_APP_PORTAL_REDIRECT_URL}&path=${returnPath}`;
    }
  }, [identity, returnPath]);

  return null;
}

function RedirectDrawerEffect() {
  const location = useLocation();

  if (location.search.includes('drawer')) {
    const params = new URLSearchParams(location.search);
    const drawer = params.get('drawer');

    return (
      <Navigate
        replace
        to={Nav.makeTo({
          root: location.pathname,
          drawer,
        })}
      />
    );
  }

  return null;
}

function App() {
  useEffect(() => {
    injectGlobalStyles();
  });

  useValidateFeatureFlags();

  return (
    <Router basename="/">
      <RedirectDrawerEffect />
      <StyledOverlayProvider>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary fallback={FatalErrorFallback}>
            <Command.Root>
              <Command.Renderer />
              <IdentityCheck />
              <Nav.Provider>
                <CurrentTimezoneProvider>
                  <Notifications />
                  <NOCApp />
                </CurrentTimezoneProvider>
              </Nav.Provider>
            </Command.Root>
          </ErrorBoundary>
        </QueryClientProvider>
      </StyledOverlayProvider>
    </Router>
  );
}

const withLaunchDarkly = withLDProvider({
  clientSideID: import.meta.env.LAUNCHDARKLY_CLIENT_ID,
  options: {
    sendEvents: false,
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
});

export default Sentry.withProfiler(withLaunchDarkly(App));
