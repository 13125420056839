import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Drawer, DrawerContent, DrawerHeader } from '@meterup/atto';
import React from 'react';

import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { PlanControls } from './common/PlanControls';
import PlanDetails from './common/PlanDetails';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/plans/:planId',
});

export default function ISPDetails() {
  const { controllerName, planId } = Nav.useRegionParams('drawer', paths.drawers.ISPDetails)!;

  return (
    <Drawer>
      <DrawerHeader
        heading="Internet service plan"
        actions={<PlanControls controllerName={controllerName} planId={planId} />}
        onClose={useCloseDrawerCallback()}
      />
      <DrawerContent>
        <PlanDetails controllerName={controllerName} planId={planId} />
      </DrawerContent>
    </Drawer>
  );
}
