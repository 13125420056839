/* eslint-disable @typescript-eslint/no-unused-vars,@typescript-eslint/no-use-before-define,@typescript-eslint/naming-convention,@typescript-eslint/no-use-before-define */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

import * as types from './graphql';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  query networkClients($networkUUID: UUID!, $filter: NetworkClientsFilter) {\n    networkClients(networkUUID: $networkUUID, filter: $filter) {\n      alias\n      apLocation\n      apSerialNumber\n      channel\n      clientName\n      ip\n      lastSeen\n      macAddress\n      noise\n      rxRate\n      signal\n      ssid\n      txRate\n      vlan\n    }\n  }\n':
    types.NetworkClientsDocument,
  '\n  query JobTrackers {\n    jobTrackers {\n      id\n      jobID\n      totalSqFt\n      fullAddress\n      locationCode\n    }\n  }\n':
    types.JobTrackersDocument,
  '\n  mutation CreateNetworkOnboarding($networkUUID: UUID!, $jobTrackerID: String!) {\n    createNetworkOnboarding(networkUUID: $networkUUID, jobTrackerID: $jobTrackerID) {\n      UUID\n    }\n  }\n':
    types.CreateNetworkOnboardingDocument,
  '\n  mutation EnableSSO($companySlug: String!) {\n    enableSSOForCompany(companySlug: $companySlug) {\n      ssoEnabled\n    }\n  }\n':
    types.EnableSsoDocument,
  '\n  mutation CreateSSOAdminPortalLinkMutation($companySlug: String!, $type: SSOAdminPortalType!) {\n    createSSOAdminPortalLink(input: { companySlug: $companySlug, type: $type }) {\n      portalURL\n    }\n  }\n':
    types.CreateSsoAdminPortalLinkMutationDocument,
  '\n  mutation CreatePermittedEmailDomain($companyName: String!, $emailDomain: String!) {\n    createPermittedEmailDomain(input: { companySlug: $companyName, domain: $emailDomain }) {\n      sid\n      domain\n    }\n  }\n':
    types.CreatePermittedEmailDomainDocument,
  '\n  query CompaniesWithPermittedEmailDomains($companyName: String!) {\n    getCompany(slug: $companyName) {\n      ssoEnabled\n      permittedEmailDomains {\n        domain\n        sid\n      }\n    }\n  }\n':
    types.CompaniesWithPermittedEmailDomainsDocument,
  '\n  mutation RemovePermittedEmailDomain($companyName: String!, $permittedEmailDomainSID: UUID!) {\n    deletePermittedEmailDomain(\n      input: { companySlug: $companyName, permittedEmailDomainSID: $permittedEmailDomainSID }\n    ) {\n      numRowsDeleted\n    }\n  }\n':
    types.RemovePermittedEmailDomainDocument,
  '\n  mutation CreateNetwork(\n    $companySlug: String!\n    $mailingAddress: String!\n    $label: String!\n    $slug: String!\n  ) {\n    createNetwork(\n      input: {\n        companySlug: $companySlug\n        mailingAddress: $mailingAddress\n        label: $label\n        slug: $slug\n      }\n    ) {\n      UUID\n      slug\n    }\n  }\n':
    types.CreateNetworkDocument,
  '\n  query NetworksAndControllersForCompany($companySlug: String!) {\n    networksForCompany(companySlug: $companySlug) {\n      label\n      UUID\n      slug\n\n      virtualDevices(filter: { deviceType: CONTROLLER }) {\n        UUID\n        hardwareDevice {\n          serialNumber\n        }\n      }\n    }\n  }\n':
    types.NetworksAndControllersForCompanyDocument,
  '\n  mutation validateBearerSession($UUID: UUID!) {\n    validateBearerSession(UUID: $UUID) {\n      UUID\n    }\n  }\n':
    types.ValidateBearerSessionDocument,
  '\n  query deviceHeartbeatForDevice($serialNumber: String!) {\n    deviceHeartbeatForDevice(serialNumber: $serialNumber) {\n      UUID\n      token\n    }\n  }\n':
    types.DeviceHeartbeatForDeviceDocument,
  '\n  mutation createDeviceHeartbeat($serialNumber: String!) {\n    createDeviceHeartbeat(input: { serialNumber: $serialNumber }) {\n      UUID\n    }\n  }\n':
    types.CreateDeviceHeartbeatDocument,
  '\n  query ControllerDetailsNetwork($uuid: UUID!) {\n    network(UUID: $uuid) {\n      UUID\n      topologyImageURL\n    }\n  }\n':
    types.ControllerDetailsNetworkDocument,
  '\n  mutation UpdateControllerTopologyImage($networkUUID: UUID!, $s3Key: String) {\n    updateNetwork(UUID: $networkUUID, input: { topologyImageS3Key: $s3Key }) {\n      UUID\n    }\n  }\n':
    types.UpdateControllerTopologyImageDocument,
  '\n  query DeviceLastDayCellularUsage($serialNumber: String!) {\n    deviceLastDayCellularUsage(serialNumber: $serialNumber) {\n      uploadBytes\n      downloadBytes\n    }\n  }\n':
    types.DeviceLastDayCellularUsageDocument,
  '\n  query NetworksForCompany($companySlug: String!) {\n    networksForCompany(companySlug: $companySlug) {\n      label\n      UUID\n      slug\n      goLiveAt\n    }\n  }\n':
    types.NetworksForCompanyDocument,
  '\n  query IsNosFeatureEnabledForNetwork($networkUUID: UUID!, $nosFeatureKey: String!) {\n    isNosFeatureEnabledForNetwork(networkUUID: $networkUUID, nosFeatureKey: $nosFeatureKey)\n  }\n':
    types.IsNosFeatureEnabledForNetworkDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query networkClients($networkUUID: UUID!, $filter: NetworkClientsFilter) {\n    networkClients(networkUUID: $networkUUID, filter: $filter) {\n      alias\n      apLocation\n      apSerialNumber\n      channel\n      clientName\n      ip\n      lastSeen\n      macAddress\n      noise\n      rxRate\n      signal\n      ssid\n      txRate\n      vlan\n    }\n  }\n',
): (typeof documents)['\n  query networkClients($networkUUID: UUID!, $filter: NetworkClientsFilter) {\n    networkClients(networkUUID: $networkUUID, filter: $filter) {\n      alias\n      apLocation\n      apSerialNumber\n      channel\n      clientName\n      ip\n      lastSeen\n      macAddress\n      noise\n      rxRate\n      signal\n      ssid\n      txRate\n      vlan\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query JobTrackers {\n    jobTrackers {\n      id\n      jobID\n      totalSqFt\n      fullAddress\n      locationCode\n    }\n  }\n',
): (typeof documents)['\n  query JobTrackers {\n    jobTrackers {\n      id\n      jobID\n      totalSqFt\n      fullAddress\n      locationCode\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateNetworkOnboarding($networkUUID: UUID!, $jobTrackerID: String!) {\n    createNetworkOnboarding(networkUUID: $networkUUID, jobTrackerID: $jobTrackerID) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateNetworkOnboarding($networkUUID: UUID!, $jobTrackerID: String!) {\n    createNetworkOnboarding(networkUUID: $networkUUID, jobTrackerID: $jobTrackerID) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation EnableSSO($companySlug: String!) {\n    enableSSOForCompany(companySlug: $companySlug) {\n      ssoEnabled\n    }\n  }\n',
): (typeof documents)['\n  mutation EnableSSO($companySlug: String!) {\n    enableSSOForCompany(companySlug: $companySlug) {\n      ssoEnabled\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateSSOAdminPortalLinkMutation($companySlug: String!, $type: SSOAdminPortalType!) {\n    createSSOAdminPortalLink(input: { companySlug: $companySlug, type: $type }) {\n      portalURL\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateSSOAdminPortalLinkMutation($companySlug: String!, $type: SSOAdminPortalType!) {\n    createSSOAdminPortalLink(input: { companySlug: $companySlug, type: $type }) {\n      portalURL\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreatePermittedEmailDomain($companyName: String!, $emailDomain: String!) {\n    createPermittedEmailDomain(input: { companySlug: $companyName, domain: $emailDomain }) {\n      sid\n      domain\n    }\n  }\n',
): (typeof documents)['\n  mutation CreatePermittedEmailDomain($companyName: String!, $emailDomain: String!) {\n    createPermittedEmailDomain(input: { companySlug: $companyName, domain: $emailDomain }) {\n      sid\n      domain\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CompaniesWithPermittedEmailDomains($companyName: String!) {\n    getCompany(slug: $companyName) {\n      ssoEnabled\n      permittedEmailDomains {\n        domain\n        sid\n      }\n    }\n  }\n',
): (typeof documents)['\n  query CompaniesWithPermittedEmailDomains($companyName: String!) {\n    getCompany(slug: $companyName) {\n      ssoEnabled\n      permittedEmailDomains {\n        domain\n        sid\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RemovePermittedEmailDomain($companyName: String!, $permittedEmailDomainSID: UUID!) {\n    deletePermittedEmailDomain(\n      input: { companySlug: $companyName, permittedEmailDomainSID: $permittedEmailDomainSID }\n    ) {\n      numRowsDeleted\n    }\n  }\n',
): (typeof documents)['\n  mutation RemovePermittedEmailDomain($companyName: String!, $permittedEmailDomainSID: UUID!) {\n    deletePermittedEmailDomain(\n      input: { companySlug: $companyName, permittedEmailDomainSID: $permittedEmailDomainSID }\n    ) {\n      numRowsDeleted\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateNetwork(\n    $companySlug: String!\n    $mailingAddress: String!\n    $label: String!\n    $slug: String!\n  ) {\n    createNetwork(\n      input: {\n        companySlug: $companySlug\n        mailingAddress: $mailingAddress\n        label: $label\n        slug: $slug\n      }\n    ) {\n      UUID\n      slug\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateNetwork(\n    $companySlug: String!\n    $mailingAddress: String!\n    $label: String!\n    $slug: String!\n  ) {\n    createNetwork(\n      input: {\n        companySlug: $companySlug\n        mailingAddress: $mailingAddress\n        label: $label\n        slug: $slug\n      }\n    ) {\n      UUID\n      slug\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NetworksAndControllersForCompany($companySlug: String!) {\n    networksForCompany(companySlug: $companySlug) {\n      label\n      UUID\n      slug\n\n      virtualDevices(filter: { deviceType: CONTROLLER }) {\n        UUID\n        hardwareDevice {\n          serialNumber\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query NetworksAndControllersForCompany($companySlug: String!) {\n    networksForCompany(companySlug: $companySlug) {\n      label\n      UUID\n      slug\n\n      virtualDevices(filter: { deviceType: CONTROLLER }) {\n        UUID\n        hardwareDevice {\n          serialNumber\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation validateBearerSession($UUID: UUID!) {\n    validateBearerSession(UUID: $UUID) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation validateBearerSession($UUID: UUID!) {\n    validateBearerSession(UUID: $UUID) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query deviceHeartbeatForDevice($serialNumber: String!) {\n    deviceHeartbeatForDevice(serialNumber: $serialNumber) {\n      UUID\n      token\n    }\n  }\n',
): (typeof documents)['\n  query deviceHeartbeatForDevice($serialNumber: String!) {\n    deviceHeartbeatForDevice(serialNumber: $serialNumber) {\n      UUID\n      token\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createDeviceHeartbeat($serialNumber: String!) {\n    createDeviceHeartbeat(input: { serialNumber: $serialNumber }) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation createDeviceHeartbeat($serialNumber: String!) {\n    createDeviceHeartbeat(input: { serialNumber: $serialNumber }) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ControllerDetailsNetwork($uuid: UUID!) {\n    network(UUID: $uuid) {\n      UUID\n      topologyImageURL\n    }\n  }\n',
): (typeof documents)['\n  query ControllerDetailsNetwork($uuid: UUID!) {\n    network(UUID: $uuid) {\n      UUID\n      topologyImageURL\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateControllerTopologyImage($networkUUID: UUID!, $s3Key: String) {\n    updateNetwork(UUID: $networkUUID, input: { topologyImageS3Key: $s3Key }) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateControllerTopologyImage($networkUUID: UUID!, $s3Key: String) {\n    updateNetwork(UUID: $networkUUID, input: { topologyImageS3Key: $s3Key }) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DeviceLastDayCellularUsage($serialNumber: String!) {\n    deviceLastDayCellularUsage(serialNumber: $serialNumber) {\n      uploadBytes\n      downloadBytes\n    }\n  }\n',
): (typeof documents)['\n  query DeviceLastDayCellularUsage($serialNumber: String!) {\n    deviceLastDayCellularUsage(serialNumber: $serialNumber) {\n      uploadBytes\n      downloadBytes\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NetworksForCompany($companySlug: String!) {\n    networksForCompany(companySlug: $companySlug) {\n      label\n      UUID\n      slug\n      goLiveAt\n    }\n  }\n',
): (typeof documents)['\n  query NetworksForCompany($companySlug: String!) {\n    networksForCompany(companySlug: $companySlug) {\n      label\n      UUID\n      slug\n      goLiveAt\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query IsNosFeatureEnabledForNetwork($networkUUID: UUID!, $nosFeatureKey: String!) {\n    isNosFeatureEnabledForNetwork(networkUUID: $networkUUID, nosFeatureKey: $nosFeatureKey)\n  }\n',
): (typeof documents)['\n  query IsNosFeatureEnabledForNetwork($networkUUID: UUID!, $nosFeatureKey: String!) {\n    isNosFeatureEnabledForNetwork(networkUUID: $networkUUID, nosFeatureKey: $nosFeatureKey)\n  }\n'];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
