import {
  CopyBox,
  Section,
  SectionContent,
  SectionHeader,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';
import { NeutralBadge } from '@meterup/common';
import { mboot } from '@meterup/proto';
import React from 'react';
import { match } from 'ts-pattern';

import type { NetworkInfoResponseData, NetworkInfoUpdateRequest } from '../../../../api/types';
import type { LegacyNetworkInfo } from '../../../../utils/legacy_network_info';
import { Tooltip } from '../../../../components/Tooltip/Tooltip';
import {
  LegacyNetworkPSKSchemeType,
  networkInfoAPIResponseToList,
} from '../../../../utils/legacy_network_info';

function LegacyNetworkInfoCard({ value }: { value: LegacyNetworkInfo }) {
  const isRotating = value.passwordScheme === LegacyNetworkPSKSchemeType.Rotating;
  return (
    <Section relation="standalone">
      <SectionHeader
        heading={value.key}
        actions={
          value.isCustomKey && (
            <Tooltip content="This SSID is custom and cannot be modified through NOC">
              <NeutralBadge>Custom</NeutralBadge>
            </Tooltip>
          )
        }
      />
      <SectionContent>
        <SummaryList>
          <SummaryListRow>
            <SummaryListKey>SSID</SummaryListKey>
            <SummaryListValue>
              {value.isSSIDDisabled && <NeutralBadge>Disabled</NeutralBadge>}
              <CopyBox aria-label="Copy SSID" relation="stacked" size="small" value={value.ssid}>
                {value.ssid}
              </CopyBox>
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Rotation</SummaryListKey>
            <SummaryListValue>
              {isRotating
                ? match(value.rotationStrategy)
                    .with(mboot.GuestPasswordStrategy.UNKNOWN, () => 'Unknown')
                    .with(mboot.GuestPasswordStrategy.NEVER, () => 'Never')
                    .with(mboot.GuestPasswordStrategy.DAILY, () => 'Daily')
                    .with(mboot.GuestPasswordStrategy.MONTHLY, () => 'Monthly')
                    .otherwise(() => 'Unknown')
                : 'Static'}
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>{isRotating ? 'Current password' : 'Password'}</SummaryListKey>
            <SummaryListValue>
              <CopyBox
                aria-label="Copy password"
                relation="stacked"
                size="small"
                value={value.password ?? ''}
              >
                {value.password}
              </CopyBox>
            </SummaryListValue>
          </SummaryListRow>
        </SummaryList>
      </SectionContent>
    </Section>
  );
}

export function LegacyNetworkInfoCards({
  networkPass,
}: {
  networkPass: NetworkInfoResponseData | NetworkInfoUpdateRequest;
}) {
  const list = networkInfoAPIResponseToList(networkPass);

  return (
    <>
      {list.map((value) => (
        <LegacyNetworkInfoCard key={value.key} value={value} />
      ))}
    </>
  );
}
