import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
} from '@meterup/atto';
import { checkDefinedOrThrow, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { fetchVPNServer } from '../../../../api/vpn';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { makeDrawerLink } from '../../../../utils/makeLink';
import VPNServerDetailContent from './VPNServerDetailContent';

export const Meta = () => ({
  path: '/controllers/:controllerName/vpn-servers',
});

export default function VPNServerDetailPage() {
  const closeDrawer = useCloseDrawerCallback();
  const navigate = useNavigate();
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.VPNServerDetailPage),
  );

  const vpnServerDetails = useQuery(
    ['controller', controllerName, 'vpn-servers'],
    () => fetchVPNServer(controllerName),
    { suspense: true, enabled: true },
  ).data;

  expectDefinedOrThrow(
    vpnServerDetails,
    new ResourceNotFoundError('VPN server response not found'),
  );

  return (
    <Drawer>
      <DrawerHeader
        heading="VPN server"
        actions={
          <DropdownMenu>
            <DropdownMenuButton
              arrangement="hidden-label"
              icon="overflow-vertical"
              variant="secondary"
            >
              Actions
            </DropdownMenuButton>
            <DropdownMenuPopover>
              <DropdownMenuGroup>
                <DropdownMenuItem
                  icon="pencil"
                  onSelect={() =>
                    navigate(
                      makeDrawerLink(paths.drawers.VPNServerEdit, {
                        controllerName,
                      }),
                    )
                  }
                >
                  Edit VPN server
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuPopover>
          </DropdownMenu>
        }
        onClose={closeDrawer}
      />
      <DrawerContent>
        <VPNServerDetailContent controllerName={controllerName} vpnServer={vpnServerDetails} />
      </DrawerContent>
    </Drawer>
  );
}
