import { Small } from '@meterup/atto';

import { colors, styled } from '../stitches';

export const ListItemStatList = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: 0,
  alignItems: 'stretch',
  padding: 0,
});

export const StatItem = styled('div', {
  padding: 16,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 4,
});

export const StatItemLabel = styled(Small, {
  color: colors.gray600,
});
