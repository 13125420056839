import { compare } from 'fast-json-patch';

const IGNORED_ADDITIONS = [/service-sets\/[\s\S]+\/id$/];

export const doesDraftHaveChanges = (base: Record<string, any>, draft: Record<string, any>) => {
  const diffs = compare(base, draft);
  const diffsWithoutIgnoredOps = diffs.filter((diff) => {
    if (diff.op === 'add') {
      return !IGNORED_ADDITIONS.some((t) => t.test(diff.path));
    }
    return true;
  });

  return diffsWithoutIgnoredOps.length > 0;
};
