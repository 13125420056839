import type { DialogContentProps } from '@radix-ui/react-dialog';
import { Body, DeprecatedCloseButton } from '@meterup/atto';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import React from 'react';

import { colors, fontWeights, keyframes, shadows, styled } from '../../stitches';

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const StyledOverlay = styled(DialogPrimitive.Overlay, {
  background:
    'radial-gradient(50% 50% at 50% 50%, rgba(43, 48, 91, 0.1) 0%, rgba(43, 48, 91, 0.25) 100%)',
  position: 'fixed',
  inset: 0,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
  zIndex: 1000,
});

const StyledContent = styled(DialogPrimitive.Content, {
  vStack: 0,
  alignItems: 'stretch',
  backgroundColor: colors.bgApplicationLight,
  borderRadius: '$8',
  boxShadow: shadows.modalLight,
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  maxWidth: '450px',
  maxHeight: '85vh',
  padding: 0,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
  '&:focus': { outline: 'none' },
  zIndex: 1001,
});

const StyledTitle = styled(DialogPrimitive.Title, Body, {
  fontWeight: fontWeights.bold,
});

const StyledDescription = styled(DialogPrimitive.Description, Body);

export const Dialog = DialogPrimitive.Root;

export const DialogTrigger = DialogPrimitive.Trigger;

export function DialogContent(props: DialogContentProps) {
  return (
    <DialogPrimitive.Portal>
      <StyledOverlay />
      <StyledContent {...props} />
    </DialogPrimitive.Portal>
  );
}

export const DialogTitle = StyledTitle;

export const DialogHeader = styled('div', {
  hStack: '$8',
  padding: '$14 $20',
  strokeBottom: colors.strokeApplicationLight,
});

export const DialogBody = styled('div', {
  overflow: 'auto',
});

export const DialogSection = styled('div', {
  padding: '$16 $20',
  strokeTopBottom: colors.strokeApplicationLight,
});

export const DialogFooter = styled(DialogHeader, {
  strokeTop: colors.strokeApplicationDark,
});

export const DialogControls = styled('div', {
  hStack: '$8',
  marginLeft: 'auto',
});

export const DialogDescription = StyledDescription;

export const DialogClose = DialogPrimitive.Close;

export function DialogCloseButton() {
  return (
    <DialogClose asChild>
      <DeprecatedCloseButton aria-label="Close dialog" />
    </DialogClose>
  );
}
