import { Duration } from 'luxon';

export const secToHuman = (sec: number) =>
  Duration.fromObject({ seconds: sec }).rescale().toHuman({ unitDisplay: 'short' });

export const bytesToHuman = (bytes: number) => {
  // from https://www.geeksforgeeks.org/javascript-convert-bytes-to-human-readable-string/
  if (bytes === 0) {
    return '';
  }
  const e = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / 1024 ** e).toFixed(2)} ${' KMGTP'.charAt(e)}B`;
};

export const kbToHuman = (kb: number) => bytesToHuman(kb * 1024);
