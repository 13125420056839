import { Skeleton } from '@meterup/atto';
import React from 'react';

import { styled } from '../stitches';

const LoadingFallbackContainer = styled('div', {
  padding: '$20',
  vStack: '$16',
  alignItems: 'start',
});

export function LoadingFallback() {
  return (
    <LoadingFallbackContainer>
      <Skeleton width="200px" height="28px" radius={8} />
      <Skeleton width="100%" height="240px" radius={8} />
    </LoadingFallbackContainer>
  );
}
