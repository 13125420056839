import { expectDefinedOrThrow } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';

import { getInternetServicePlan } from '../../../../../api/controllers_api';

export function usePlanDetails(controllerName: string, planId: string) {
  const planDetails = useQuery(
    ['controllers', controllerName, 'service-plans', planId],
    () => getInternetServicePlan(controllerName, planId),
    { suspense: true },
  ).data;

  expectDefinedOrThrow(planDetails);
  return planDetails;
}
