import {
  Badge,
  Body,
  CopyBox,
  Section,
  SectionContent,
  SectionHeader,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';
import {
  isDefinedAndNotEmpty,
  LEGACY_formatDataRateBits,
  LEGACY_kilobitsPerSecond,
  NetworkInterfaceBadge,
  ServicePlanStatusBadge,
} from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { getProvider } from '../../../../../api/controllers_api';
import { styled } from '../../../../../stitches';
import { formatUSD, usdCents } from '../../../../../utils/currencies';
import { FirstAndLastAddresses } from './FirstAndLastAddresses';
import { usePlanDetails } from './usePlanDetails';

const Notes = styled('div', Body, {
  padding: '$12 $16',
  whiteSpace: 'pre-line',
});

export default function PlanDetails({
  controllerName,
  planId,
}: {
  controllerName: string;
  planId: string;
}) {
  const planDetails = usePlanDetails(controllerName, planId);

  const provider = useQuery(
    ['provider', planDetails.provider],
    () => getProvider(planDetails.provider),
    { suspense: true },
  ).data;

  const isStaticIP = isDefinedAndNotEmpty(planDetails.static_ip_range);

  return (
    <>
      <Section relation="standalone">
        <SectionHeader heading="Plan details" />
        <SectionContent>
          <SummaryList>
            <SummaryListRow>
              <SummaryListKey>Provider</SummaryListKey>
              <SummaryListValue>
                {provider?.name ? (
                  <CopyBox
                    aria-label="Copy provider"
                    relation="stacked"
                    size="small"
                    value={provider?.name}
                  >
                    {provider?.name}
                  </CopyBox>
                ) : (
                  <Badge variant="negative" size="small">
                    Provider not found
                  </Badge>
                )}
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>Product</SummaryListKey>
              <SummaryListValue>
                <CopyBox
                  aria-label="Copy provider"
                  relation="stacked"
                  size="small"
                  value={planDetails.product}
                >
                  {planDetails.product}
                </CopyBox>
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>Status</SummaryListKey>
              <SummaryListValue>
                <ServicePlanStatusBadge value={planDetails.status} />
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>Account #</SummaryListKey>
              <SummaryListValue>
                <CopyBox
                  aria-label="Copy account number"
                  relation="stacked"
                  size="small"
                  value={planDetails.account_number}
                >
                  {planDetails.account_number}
                </CopyBox>
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>Download speed</SummaryListKey>
              <SummaryListValue>
                {LEGACY_formatDataRateBits(planDetails.download_kbps, LEGACY_kilobitsPerSecond)}
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>Upload speed</SummaryListKey>
              <SummaryListValue>
                {LEGACY_formatDataRateBits(planDetails.upload_kbps, LEGACY_kilobitsPerSecond)}
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>Monthly price</SummaryListKey>
              <SummaryListValue>
                {formatUSD(planDetails.monthly_cost_cents, usdCents)}
              </SummaryListValue>
            </SummaryListRow>
          </SummaryList>
        </SectionContent>
      </Section>

      {isDefinedAndNotEmpty(planDetails.notes) && (
        <Section relation="standalone">
          <SectionHeader heading="Notes" />
          <SectionContent>
            <Notes>{planDetails.notes}</Notes>
          </SectionContent>
        </Section>
      )}

      {isStaticIP ? (
        <Section relation="standalone">
          <SectionHeader heading="IP assignment" />
          <SectionContent>
            <SummaryList>
              <SummaryListRow>
                <SummaryListKey>Range</SummaryListKey>
                <SummaryListValue>{planDetails.static_ip_range}</SummaryListValue>
              </SummaryListRow>
              <SummaryListRow>
                <SummaryListKey>Gateway address</SummaryListKey>
                <SummaryListValue>{planDetails.gateway_addr}</SummaryListValue>
              </SummaryListRow>
              <SummaryListRow>
                <SummaryListKey>Controller address</SummaryListKey>
                <SummaryListValue>{planDetails.controller_ip}</SummaryListValue>
              </SummaryListRow>
              <FirstAndLastAddresses ipRange={planDetails.static_ip_range} />
            </SummaryList>
          </SectionContent>
        </Section>
      ) : (
        <Section relation="standalone">
          <SectionHeader heading="IP assignment" actions={<Badge size="small">Dynamic</Badge>} />
        </Section>
      )}

      <Section relation="standalone">
        <SectionHeader
          heading="Network interface"
          actions={<NetworkInterfaceBadge value={planDetails.network_interface} />}
        />
      </Section>
    </>
  );
}
