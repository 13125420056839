import type { api } from '@meterup/proto';
import {
  Small,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';

export function NosInformationWidget({ nos }: { nos: api.NosVersion }) {
  return (
    <SummaryList>
      <SummaryListRow>
        <SummaryListKey>Version</SummaryListKey>
        <SummaryListValue>
          <Small family="monospace">{nos.version}</Small>
        </SummaryListValue>
      </SummaryListRow>
      <SummaryListRow>
        <SummaryListKey>COS</SummaryListKey>
        <SummaryListValue>
          <Small family="monospace">{nos.mc01_build_name}</Small>
        </SummaryListValue>
      </SummaryListRow>
      <SummaryListRow>
        <SummaryListKey>mw03, mw04, mw06, mw07</SummaryListKey>
        <SummaryListValue>
          <Small family="monospace">{nos.mw07_build_name}</Small>
        </SummaryListValue>
      </SummaryListRow>
      <SummaryListRow>
        <SummaryListKey>mw08</SummaryListKey>
        <SummaryListValue>
          <Small family="monospace">{nos.mw08_build_name}</Small>
        </SummaryListValue>
      </SummaryListRow>
      <SummaryListRow>
        <SummaryListKey>mw09</SummaryListKey>
        <SummaryListValue>
          <Small family="monospace">{nos.mw09_build_name}</Small>
        </SummaryListValue>
      </SummaryListRow>
      <SummaryListRow>
        <SummaryListKey>SOS</SummaryListKey>
        <SummaryListValue>
          <Small family="monospace">{nos.ms10_build_name}</Small>
        </SummaryListValue>
      </SummaryListRow>
      <SummaryListRow>
        <SummaryListKey>Is GA</SummaryListKey>
        <SummaryListValue>
          <Small family="monospace">{nos.is_default ? 'YES' : 'no'}</Small>
        </SummaryListValue>
      </SummaryListRow>
      <SummaryListRow>
        <SummaryListKey>Release Notes</SummaryListKey>
        <SummaryListValue>
          <Small family="monospace">{nos.release_notes}</Small>
        </SummaryListValue>
      </SummaryListRow>
    </SummaryList>
  );
}
