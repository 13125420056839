import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
} from '@meterup/atto';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { paths } from '../../../../../constants';
import { makeDrawerLink } from '../../../../../utils/makeLink';

export function PlanControls({
  controllerName,
  planId,
}: {
  controllerName: string;
  planId: string;
}) {
  const navigate = useNavigate();

  return (
    <DropdownMenu>
      <DropdownMenuButton variant="secondary" arrangement="hidden-label" icon="overflow-vertical">
        Actions
      </DropdownMenuButton>
      <DropdownMenuPopover>
        <DropdownMenuGroup>
          <DropdownMenuItem
            icon="pencil"
            onSelect={() =>
              navigate(
                makeDrawerLink(paths.drawers.ISPEdit, {
                  controllerName,
                  planId,
                }),
              )
            }
          >
            Edit plan
          </DropdownMenuItem>
          <DropdownMenuItem
            icon="trash-can"
            onSelect={() =>
              navigate(
                makeDrawerLink(paths.drawers.ISPDelete, {
                  controllerName,
                  planId,
                }),
              )
            }
          >
            Delete plan
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuPopover>
    </DropdownMenu>
  );
}
