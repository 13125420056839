import {
  Badge,
  Body,
  Button,
  CopyBox,
  Section,
  SectionContent,
  SectionHeader,
  Select,
  SelectItem,
  space,
  SummaryTable,
  SummaryTableCell,
  SummaryTableRow,
  Text,
  VStack,
} from '@meterup/atto';
import { notify, styled } from '@meterup/common';
import { useGraphQLMutation } from '@meterup/graphql';
import React, { useCallback, useState } from 'react';

import { graphql } from '../gql';
import { SsoAdminPortalType } from '../gql/graphql';

const VStackWrapper = styled('div', {
  padding: '$8 0',
});
const enableSSOMutation = graphql(`
  mutation EnableSSO($companySlug: String!) {
    enableSSOForCompany(companySlug: $companySlug) {
      ssoEnabled
    }
  }
`);

const createSSOAdminPortalLinkMutation = graphql(`
  mutation CreateSSOAdminPortalLinkMutation($companySlug: String!, $type: SSOAdminPortalType!) {
    createSSOAdminPortalLink(input: { companySlug: $companySlug, type: $type }) {
      portalURL
    }
  }
`);

const allDashboardTypes = Object.values(SsoAdminPortalType).sort();

function humanizeDashboardType(type: SsoAdminPortalType) {
  switch (type) {
    case SsoAdminPortalType.SsoProvider:
      return 'SSO Provider';
    case SsoAdminPortalType.AuditLogs:
      return 'Audit Logs';
    case SsoAdminPortalType.Dsync:
      return 'Directory Sync';
    case SsoAdminPortalType.LogStreams:
      return 'Log Streams';
    default:
      return type;
  }
}

function shorten(url: string, length: number = 15) {
  if (url.length > length) {
    return `${url.slice(0, length)}...`;
  }
  return url;
}

type GenerateWorkOSAdminLinkProps = {
  companyHasSSOEnabled: boolean;
  companySlug: string;
  invalidateCompany: () => void;
};

export default function GenerateWorkOSPortalLink({
  companyHasSSOEnabled,
  companySlug,
  invalidateCompany,
}: GenerateWorkOSAdminLinkProps) {
  const createSSOAdminPortalLink = useGraphQLMutation(createSSOAdminPortalLinkMutation);
  const enableSSO = useGraphQLMutation(enableSSOMutation);
  const [adminLink, setAdminLink] = useState<string | null>(null);
  const [dashboardType, setDashboardType] = useState(SsoAdminPortalType.SsoProvider);
  const onClickGenerateAdminLink = useCallback(() => {
    createSSOAdminPortalLink.mutate(
      { companySlug, type: dashboardType },
      {
        onSuccess: (data) => setAdminLink(data.createSSOAdminPortalLink.portalURL),
      },
    );
  }, [companySlug, createSSOAdminPortalLink, dashboardType]);
  const onClickEnableSSO = useCallback(() => {
    enableSSO.mutate(
      { companySlug },
      {
        onSuccess: () => {
          notify('SSO enabled', { variant: 'positive' });
          invalidateCompany();
        },
        onError(err) {
          const messages = (err.response.errors || []).map((error) => error.message);
          notify(`Error enabling SSO\n${messages}`, {
            variant: 'negative',
          });
        },
      },
    );
  }, [companySlug, enableSSO, invalidateCompany]);
  const typeSelected = useCallback((value: string) => {
    setDashboardType((prevValue) => {
      if (prevValue === value) {
        return prevValue;
      }
      setAdminLink(null);
      return value as SsoAdminPortalType;
    });
  }, []);

  return (
    <Section relation="standalone">
      <SectionHeader heading="SSO (Single sign-on)" />
      <SectionContent>
        <SummaryTable>
          <SummaryTableRow>
            <SummaryTableCell>
              <Text>SSO Status</Text>
            </SummaryTableCell>
            <SummaryTableCell>
              {companyHasSSOEnabled ? (
                <Badge variant="positive" size="small">
                  SSO already enabled
                </Badge>
              ) : (
                <Button type="button" variant="secondary" onClick={onClickEnableSSO}>
                  Enable SSO
                </Button>
              )}
            </SummaryTableCell>
          </SummaryTableRow>
          <SummaryTableRow>
            <SummaryTableCell>
              <Text>Admin Portal Type</Text>
            </SummaryTableCell>
            <SummaryTableCell>
              {companyHasSSOEnabled ? (
                <VStackWrapper>
                  <VStack spacing={space(8)}>
                    <Select value={dashboardType} onValueChange={typeSelected}>
                      {allDashboardTypes.map((item) => (
                        <SelectItem key={item}>{humanizeDashboardType(item)}</SelectItem>
                      ))}
                    </Select>
                    <Button type="button" variant="secondary" onClick={onClickGenerateAdminLink}>
                      Generate Link
                    </Button>
                  </VStack>
                </VStackWrapper>
              ) : (
                <Body>Enable SSO first</Body>
              )}
            </SummaryTableCell>
          </SummaryTableRow>
          {adminLink ? (
            <SummaryTableRow>
              <SummaryTableCell>
                <VStack>
                  <Text>{humanizeDashboardType(dashboardType)}</Text>
                  <Text>Portal URL</Text>
                </VStack>
              </SummaryTableCell>
              <SummaryTableCell>
                <CopyBox
                  // @ts-ignore
                  css={{ whiteSpace: 'nowrap' }}
                  size="large"
                  value={adminLink}
                  aria-label={adminLink}
                  relation="standalone"
                >
                  {shorten(adminLink)}
                </CopyBox>
              </SummaryTableCell>
            </SummaryTableRow>
          ) : null}
        </SummaryTable>
      </SectionContent>
    </Section>
  );
}
