import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Body,
  CompositeField,
  Drawer,
  DrawerContent,
  DrawerHeader,
  FieldContainer,
  HStack,
  MinimalCheckboxField,
  PrimaryField,
  PrimaryFieldComposite,
  SecondaryField,
  Section,
  SectionContent,
  SectionHeader,
  Select,
  SelectItem,
  space,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  TextInput,
  ToggleInput,
} from '@meterup/atto';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  NeutralBadge,
  PositiveBadge,
  ResourceNotFoundError,
} from '@meterup/common';
import { PSKRotationFrequency, PSKSchemeType } from '@meterup/config';
import { produce } from 'immer';
import { orderBy } from 'lodash-es';
import React from 'react';

import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { useConfigEditor } from '../../../../context/ConfigEditorContext';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/config/ssids/:eid',
});

export default function SSIDEdit() {
  const { eid } = checkDefinedOrThrow(Nav.useRegionParams('drawer', paths.drawers.SSIDEdit));

  const configEditor = useConfigEditor();

  const configDraft = configEditor.draftModel;

  const serviceSet = configDraft.getServiceSetByStableId(eid);

  expectDefinedOrThrow(
    serviceSet,
    new ResourceNotFoundError(
      `Service set not found. This could happen if it was recently renamed.`,
    ),
  );

  const vlanOptions = [
    'none',
    ...orderBy(configDraft.vlans, (v) => configDraft.getVLANByName(v.name)?.json['vlan-id']),
  ];

  return (
    <Drawer>
      <DrawerHeader heading="Service set" onClose={useCloseDrawerCallback()} />
      <DrawerContent>
        <FieldContainer>
          <PrimaryField
            label="SSID"
            element={
              <TextInput
                value={serviceSet.ssid}
                onChange={(nextValue: string) => {
                  configEditor.setDraftModel(
                    produce((draft) => {
                      const draftServiceSet = draft.getServiceSetByStableId(serviceSet.stableId);

                      if (draftServiceSet) {
                        draftServiceSet.ssid = nextValue;
                      }
                    }),
                  );
                }}
              />
            }
          />
          <SecondaryField
            label="Broadcast SSID"
            element={
              <ToggleInput
                selected={!serviceSet.hidden}
                onChange={(nextValue: boolean) => {
                  configEditor.setDraftModel(
                    produce((draft) => {
                      const draftServiceSet = draft.getServiceSetByStableId(serviceSet.stableId);

                      if (draftServiceSet) {
                        draftServiceSet.hidden = !nextValue;
                      }
                    }),
                  );
                }}
              />
            }
          />
        </FieldContainer>
        <FieldContainer>
          <PrimaryFieldComposite
            label="Password"
            fields={
              <HStack spacing={space(8)}>
                <CompositeField
                  label="Type"
                  element={
                    <Select
                      value={serviceSet.pskScheme.type}
                      onValueChange={(nextValue) => {
                        configEditor.setDraftModel(
                          produce((draft) => {
                            const draftServiceSet = draft.getServiceSetByStableId(
                              serviceSet.stableId,
                            );

                            if (draftServiceSet) {
                              draftServiceSet.pskScheme.type = nextValue as PSKSchemeType;
                            }
                          }),
                        );
                      }}
                      disabledKeys={[PSKSchemeType.MeterAuth, PSKSchemeType.IEEE8021X]}
                    >
                      <SelectItem key={PSKSchemeType.None}>None</SelectItem>
                      <SelectItem key={PSKSchemeType.Static}>Static</SelectItem>
                      <SelectItem key={PSKSchemeType.Rotating}>Rotating</SelectItem>
                      <SelectItem key={PSKSchemeType.MeterAuth}>Meter Auth</SelectItem>
                      <SelectItem key={PSKSchemeType.IEEE8021X}>802.1X</SelectItem>
                    </Select>
                  }
                />
                {serviceSet.pskScheme.type === 'static' && (
                  <CompositeField
                    label="Password"
                    element={
                      <TextInput
                        value={serviceSet.pskScheme.staticValue}
                        onChange={(nextValue: string) => {
                          configEditor.setDraftModel(
                            produce((draft) => {
                              const draftServiceSet = draft.getServiceSetByStableId(
                                serviceSet.stableId,
                              );

                              if (draftServiceSet) {
                                draftServiceSet.pskScheme.staticValue = nextValue;
                              }
                            }),
                          );
                        }}
                      />
                    }
                  />
                )}{' '}
                {serviceSet.pskScheme.type === 'rotating' && (
                  <CompositeField
                    label="Rotation frequency"
                    element={
                      <Select
                        value={serviceSet.pskScheme.rotationFrequency}
                        onValueChange={(nextValue) => {
                          configEditor.setDraftModel(
                            produce((draft) => {
                              const draftServiceSet = draft.getServiceSetByStableId(
                                serviceSet.stableId,
                              );

                              if (draftServiceSet) {
                                draftServiceSet.pskScheme.rotationFrequency =
                                  nextValue as PSKRotationFrequency;
                              }
                            }),
                          );
                        }}
                      >
                        <SelectItem key={PSKRotationFrequency.Never}>Never</SelectItem>
                        <SelectItem key={PSKRotationFrequency.Daily}>Daily</SelectItem>
                        <SelectItem key={PSKRotationFrequency.Weekly}>Weekly</SelectItem>
                        <SelectItem key={PSKRotationFrequency.Monthly}>Monthly</SelectItem>
                      </Select>
                    }
                  />
                )}
              </HStack>
            }
          />
        </FieldContainer>
        <FieldContainer>
          <PrimaryFieldComposite
            label="Bands"
            fields={
              <HStack spacing={space(12)}>
                <MinimalCheckboxField
                  label="2.4 GHz"
                  checked={serviceSet.getKnownAndAdditionalBands()['2G']}
                  onChange={(enabled) =>
                    configEditor.setDraftModel(
                      produce((draft) => {
                        const draftServiceSet = draft.getServiceSetByStableId(serviceSet.stableId);

                        if (draftServiceSet) {
                          draftServiceSet.setBandStatus('2.4 GHz', enabled);
                        }
                      }),
                    )
                  }
                />
                <MinimalCheckboxField
                  label="5 GHz"
                  checked={serviceSet.getKnownAndAdditionalBands()['5G']}
                  onChange={(enabled) =>
                    configEditor.setDraftModel(
                      produce((draft) => {
                        const draftServiceSet = draft.getServiceSetByStableId(serviceSet.stableId);

                        if (draftServiceSet) {
                          draftServiceSet.setBandStatus('5 GHz', enabled);
                        }
                      }),
                    )
                  }
                />
              </HStack>
            }
          />
        </FieldContainer>
        <FieldContainer>
          <PrimaryField
            label="VLAN"
            element={
              <Select
                value={serviceSet.vlan ?? 'none'}
                disabledKeys={['none']}
                onValueChange={(nextValue) => {
                  configEditor.setDraftModel(
                    produce((draft) => {
                      const draftServiceSet = draft.getServiceSetByStableId(serviceSet.stableId);

                      if (draftServiceSet) {
                        if (nextValue !== 'none') {
                          draftServiceSet.vlan = nextValue;
                        }
                      }
                    }),
                  );
                }}
              >
                {vlanOptions.map((vlan) => {
                  if (typeof vlan === 'string') {
                    return <SelectItem key={vlan}>{vlan}</SelectItem>;
                  }

                  const vlanId = configDraft.getVLANByName(vlan.name)?.json['vlan-id'];
                  return (
                    <SelectItem key={vlan.name}>
                      <HStack spacing={space(8)} width="full">
                        {vlan.name}
                        <Body style={{ marginLeft: 'auto' }}>{vlanId}</Body>
                      </HStack>
                    </SelectItem>
                  );
                })}
              </Select>
            }
          />
        </FieldContainer>
        <Section relation="standalone">
          <SectionHeader heading="Access points" />
          <SectionContent>
            <SummaryList>
              {configDraft.accessPoints.map((ap) => (
                <SummaryListRow key={ap.name}>
                  <SummaryListKey>{ap.name}</SummaryListKey>
                  <SummaryListValue>
                    {configDraft.doesAccessPointBroadcastServiceSet(ap, serviceSet) ? (
                      <PositiveBadge icon="wifi" arrangement="hidden-label">
                        Broadcasting
                      </PositiveBadge>
                    ) : (
                      <NeutralBadge icon="cross" arrangement="hidden-label">
                        Not broadcasting
                      </NeutralBadge>
                    )}
                  </SummaryListValue>
                </SummaryListRow>
              ))}
            </SummaryList>
          </SectionContent>
        </Section>
      </DrawerContent>
    </Drawer>
  );
}
