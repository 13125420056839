import type { ReactNode } from 'react';
import { Pane, PaneContent, PaneFooter, PaneHeader, Skeleton } from '@meterup/atto';
import { ErrorBoundary } from '@sentry/react';
import React, { Suspense } from 'react';

import { GenericErrorFallback } from '../components/error_fallbacks';

export function ControllerPageLayout({
  children,
  drawer,
  footer,
  header,
}: {
  children: ReactNode;
  drawer: ReactNode;
  footer: ReactNode;
  header: ReactNode;
}) {
  return (
    <>
      <Pane>
        <ErrorBoundary fallback={GenericErrorFallback}>
          <Suspense
            fallback={<PaneHeader heading={<Skeleton width="200px" height="28px" radius={8} />} />}
          >
            {header}
          </Suspense>
        </ErrorBoundary>

        <ErrorBoundary fallback={GenericErrorFallback}>
          <Suspense
            fallback={
              <PaneContent>
                <Skeleton width="100%" height="240px" radius={8} />
              </PaneContent>
            }
          >
            {children}
          </Suspense>
        </ErrorBoundary>

        <ErrorBoundary fallback={GenericErrorFallback}>
          <Suspense
            fallback={
              <PaneFooter>
                <Skeleton width="100%" height="28px" radius={8} />
              </PaneFooter>
            }
          >
            {footer}
          </Suspense>
        </ErrorBoundary>
      </Pane>

      <ErrorBoundary fallback={GenericErrorFallback}>
        <Suspense fallback={<Skeleton width="100%" height="240px" radius={8} />}>{drawer}</Suspense>
      </ErrorBoundary>
    </>
  );
}
