import { Button, useTimedToggle } from '@meterup/atto';
import { copyTextToClipboard } from '@meterup/atto/src/utilities/clipboard';
import React from 'react';

interface CopyToClipboardButtonProps {
  text: string;
}

export function CopyToClipboardButton({ text }: CopyToClipboardButtonProps) {
  const { value, show } = useTimedToggle();

  const handleClick = async () => {
    await copyTextToClipboard(text);
    show();
  };

  return (
    <Button
      arrangement="leading-icon"
      size="small"
      variant="secondary"
      icon={value ? 'checkmark-circle' : 'copy'}
      width="100%"
      onClick={handleClick}
    >
      {value ? 'Copied' : 'Copy'}
    </Button>
  );
}

export function CopyToClipboardButtonMinimal({ text }: CopyToClipboardButtonProps) {
  const { value, show } = useTimedToggle();

  const handleClick = async () => {
    await copyTextToClipboard(text);
    show();
  };

  return (
    <Button
      arrangement="hidden-label"
      size="small"
      variant="secondary"
      icon={value ? 'checkmark-circle' : 'copy'}
      onClick={handleClick}
    >
      Copy to clipboard
    </Button>
  );
}
