import type { editor } from 'monaco-editor';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Alert, Button, PaneContent } from '@meterup/atto';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  isDefined,
  isDefinedAndNotEmpty,
  ResourceNotFoundError,
} from '@meterup/common';
import schema from '@meterup/config/src/schema/schema.json';
import { DiffEditor, useMonaco } from '@monaco-editor/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import { fetchControllerJSON } from '../../../api/controllers_api';
import { Nav } from '../../../components/Nav';
import { paths } from '../../../constants';
import { useConfigEditor } from '../../../context/ConfigEditorContext';
import { useFeatureFlag } from '../../../hooks/useFeatureFlags';
import { styled } from '../../../stitches';
import { NosFeature, useNosFeatureEnabled } from '../../../utils/nosfeature';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/config',
});

const Container = styled('div', { display: 'flex', flexDirection: 'column', height: '100%' });

const EditorContainer = styled('div', {
  height: '100%',
  flex: 1,
  flexShrink: 0,
  overflow: 'hidden',
});

interface ConfigError {
  response?: {
    data?: {
      title?: string;
    };
    status?: number;
  };
  message: string;
}

function isConfigError(error: any): error is ConfigError {
  return error && error.response && typeof error.response === 'object';
}

function getConfigSaveErrorMessage(configError: any): string {
  if (isConfigError(configError)) {
    return configError.response?.data?.title ?? 'Something went wrong';
  }
  return 'Something went wrong';
}

export default function ControllerConfig() {
  const monaco = useMonaco();

  useEffect(() => {
    monaco?.languages.json.jsonDefaults.setDiagnosticsOptions({
      schemas: [
        {
          fileMatch: ['**'],
          uri: '',
          schema,
        },
      ],
    });
  }, [monaco]);

  const [editor, setEditor] = useState<editor.IStandaloneCodeEditor | null>(null);

  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerConfig),
  );

  const config = useConfigEditor();

  const networkInfo = useQuery(
    ['controller', controllerName],
    async () => fetchControllerJSON(controllerName),
    { suspense: true },
  ).data;

  expectDefinedOrThrow(
    networkInfo,
    new ResourceNotFoundError(`Controller ${controllerName} not found`),
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (editor) {
      return editor.onDidChangeModelContent(() => {
        config.setDraftText(editor.getValue());
      }).dispose;
    }
  }, [config, editor]);

  const canModifyConfig = useFeatureFlag('config-json-ui');
  const isCOS2Enabled = useNosFeatureEnabled(controllerName, NosFeature.COS2);

  return (
    <PaneContent>
      {isCOS2Enabled && (
        <Alert
          variant="negative"
          copy="This network uses COS Config 2.0. This config is not used. Use Dashboard to configure the network."
          heading="Attention"
          relation="stacked"
          icon="warning"
          shoulderButtons={
            <Button
              variant="secondary"
              as="a"
              target="_blank"
              href={`${import.meta.env.DASHBOARD_URL}/org/${
                networkInfo.company_slug
              }/network/${networkInfo.network_uuid}`}
            >
              View dashboard
            </Button>
          }
        />
      )}
      <Container>
        {isDefinedAndNotEmpty(networkInfo.mboot_version) && (
          <Alert
            icon="attention"
            variant="negative"
            heading="Legacy controller"
            copy="This looks like a legacy controller. Writing to its config
              might not have the intended effect."
            relation="stacked"
          />
        )}
        {isDefined(config.saveDraftModelError) && (
          <Alert
            icon="attention"
            variant="negative"
            heading="Error while saving changes"
            copy={getConfigSaveErrorMessage(config.saveDraftModelError)}
            relation="stacked"
          />
        )}
        <EditorContainer>
          <DiffEditor
            originalLanguage="json"
            modifiedLanguage="json"
            original={config.originalText}
            modified={config.draftText}
            onMount={(ref) => setEditor(ref.getModifiedEditor())}
            options={{ minimap: { enabled: false }, readOnly: !canModifyConfig }}
          />
        </EditorContainer>
      </Container>
    </PaneContent>
  );
}
