import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Alert,
  Button,
  CheckboxInput,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  PrimaryField,
  Section,
  SectionContent,
  SectionHeader,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  TextInput,
} from '@meterup/atto';
import { checkDefinedOrThrow, getErrorMessage } from '@meterup/common';
import { MaintenanceWindowRecurrence } from '@meterup/proto/esm/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import React from 'react';
import * as z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import type {
  ControllerUpgradeManagerConfig,
  ControllerUpgradeManagerState,
} from '../../../../api/types';
import {
  fetchControllerConfig,
  fetchControllerState,
  fetchMaintenanceWindowForController,
} from '../../../../api/controllers_api';
import { createFirmwareUpdate } from '../../../../api/devices_api';
import { FieldProvider } from '../../../../components/FieldProvider';
import { Nav } from '../../../../components/Nav';
import { TimestampWithTimezone } from '../../../../components/timestamps';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { useCurrentTimezone } from '../../../../providers/CurrentTimezoneProvider';
import { styled } from '../../../../stitches';
import {
  formatControllerVersionFromConfig,
  formatControllerVersionFromState,
} from '../../../../utils/controllerVersion';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/firmware-updates/edit',
});

const validFirmwareUpdate = z.object({
  buildName: z.string(),
  scheduleAtNextWindow: z.boolean().optional(),
  forceUpdate: z.boolean().optional(),
});

interface FirmwareUpdate extends z.TypeOf<typeof validFirmwareUpdate> {}

const StyledForm = styled(Form, {
  display: 'contents',
});

export default function EditFirmwareUpdates() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.EditFirmwareUpdates),
  );

  const controllerState = useQuery(
    ['controller', controllerName, 'state'],
    () => fetchControllerState(controllerName),
    { suspense: true },
  ).data;

  const config = useQuery(
    ['controller', controllerName, 'config'],
    async () => fetchControllerConfig(controllerName),
    {
      suspense: true,
    },
  ).data?.config as Record<string, unknown>;

  const [versionConfig, parsedVersionConfig] = React.useMemo(
    () =>
      formatControllerVersionFromConfig(
        config['meter.v1.upgrade-manager'] as ControllerUpgradeManagerConfig,
      ),
    [config],
  );

  const versionStateRaw = (controllerState?.state as ControllerUpgradeManagerState)[
    'meter.v1.upgrade-manager'
  ];

  const [versionState, parsedVersionState] = formatControllerVersionFromState(controllerState);

  const windows = useQuery(
    ['controller', controllerName, 'maintenance-windows'],
    () => fetchMaintenanceWindowForController(controllerName),
    {
      suspense: true,
    },
  ).data;
  const maintenanceWindow = windows?.maintenance_windows[0];

  const timezone = useCurrentTimezone();
  const queryClient = useQueryClient();
  const closeDrawer = useCloseDrawerCallback();
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const timeLabel = (s: number) => {
    let meridiem = 'AM';
    if (s > 12) meridiem = 'PM';
    return `${s % 12 || 12}${meridiem}`;
  };

  const createFirmwareUpdateMutation = useMutation(
    async (values: FirmwareUpdate) => {
      await createFirmwareUpdate(controllerName, {
        build_name: values.buildName,
        schedule_at_next_window: values.scheduleAtNextWindow || false,
        force_update: values.forceUpdate || false,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controller', controllerName, 'state']);
        queryClient.invalidateQueries(['controller', controllerName, 'config']);
        closeDrawer();
      },
    },
  );

  return (
    <Formik<FirmwareUpdate>
      initialValues={{
        buildName: '',
        scheduleAtNextWindow: true,
        forceUpdate: false,
      }}
      validationSchema={toFormikValidationSchema(validFirmwareUpdate)}
      onSubmit={(values) => createFirmwareUpdateMutation.mutate(values)}
      enableReinitialize
    >
      {(props) => (
        <StyledForm>
          <Drawer>
            <DrawerHeader heading="Edit controller firmware" onClose={closeDrawer} />
            <DrawerContent>
              {createFirmwareUpdateMutation.isError && (
                <Alert
                  icon="attention"
                  variant="negative"
                  heading="Error while submitting"
                  copy={getErrorMessage(createFirmwareUpdateMutation.error)}
                />
              )}
              {versionState !== versionConfig && (
                <Alert
                  icon="attention"
                  variant="neutral"
                  heading="Firmware update pending"
                  copy={`Controller currently upgrading to ${versionConfig}`}
                />
              )}

              <Section relation="standalone">
                <SectionHeader heading="Current observed version" />
                <SectionContent>
                  <SummaryList>
                    <SummaryListRow>
                      <SummaryListKey>Build String</SummaryListKey>
                      <SummaryListValue>{versionState}</SummaryListValue>
                    </SummaryListRow>
                    {parsedVersionState.length > 0 && (
                      <SummaryListRow>
                        <SummaryListKey>Version</SummaryListKey>
                        <SummaryListValue>{parsedVersionState}</SummaryListValue>
                      </SummaryListRow>
                    )}
                    {versionStateRaw?.observed_at && (
                      <SummaryListRow>
                        <SummaryListKey>Observed At</SummaryListKey>
                        <SummaryListValue>
                          <TimestampWithTimezone
                            value={versionStateRaw.observed_at}
                            timezone={timezone}
                          />
                        </SummaryListValue>
                      </SummaryListRow>
                    )}
                  </SummaryList>
                </SectionContent>
              </Section>

              {versionState !== versionConfig && (
                <Section relation="standalone">
                  <SectionHeader heading="Current desired version" />
                  <SectionContent>
                    <SummaryList>
                      {versionConfig.length > 0 && (
                        <SummaryListRow>
                          <SummaryListKey>Build String</SummaryListKey>
                          <SummaryListValue>{versionConfig}</SummaryListValue>
                        </SummaryListRow>
                      )}
                      {parsedVersionConfig.length > 0 && (
                        <SummaryListRow>
                          <SummaryListKey>Version</SummaryListKey>
                          <SummaryListValue>{parsedVersionConfig}</SummaryListValue>
                        </SummaryListRow>
                      )}
                    </SummaryList>
                  </SectionContent>
                </Section>
              )}

              <FieldProvider name="buildName">
                <PrimaryField element={<TextInput />} label="Build Name" />
              </FieldProvider>
              <FieldProvider name="scheduleAtNextWindow">
                <PrimaryField
                  element={<CheckboxInput checked={props.values.scheduleAtNextWindow || false} />}
                  label="Schedule at next window"
                  description={
                    maintenanceWindow &&
                    `${
                      maintenanceWindow.recurrence === MaintenanceWindowRecurrence.RECURRENCE_DAILY
                        ? `Tonight`
                        : `Next ${days[maintenanceWindow.start_day_of_week_local!]}`
                    } at ${timeLabel(maintenanceWindow.start_hour_of_day_local!)}${
                      maintenanceWindow.timezone ? ` (${maintenanceWindow.timezone!})` : ''
                    }`
                  }
                />
              </FieldProvider>
              <FieldProvider name="forceUpdate">
                <PrimaryField
                  element={<CheckboxInput checked={props.values.forceUpdate || false} />}
                  label="Force"
                  description="Ignores the maintenance windows. Should only be used for emergencies."
                />
              </FieldProvider>
            </DrawerContent>
            <DrawerFooter actions={<Button type="submit">Save</Button>} />
          </Drawer>
        </StyledForm>
      )}
    </Formik>
  );
}
