import type { LinkProps } from 'react-router-dom';
import { SectionTarget } from '@meterup/atto';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { styled } from '../stitches';

const BaseViewAllListItem = styled(ReactRouterLink, SectionTarget);

export function ListItemViewAllLink({ children, ...props }: LinkProps) {
  return (
    <BaseViewAllListItem tabIndex={0} as={ReactRouterLink} {...props}>
      {children}
    </BaseViewAllListItem>
  );
}
